import { SET_USER_INFO, CLEAR_USER_INFO } from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        user: {
          account: action.user.account,
          email: action.user.email,
          subscribe: action.user.subscribe,
          subscribe_start: action.user.subscribe_start,
          subscribe_end: action.user.subscribe_end,
          done_mail_cert: action.user.done_mail_cert,
        },
      };
    case CLEAR_USER_INFO:
      return {
        ...state,
        user: {
          account: '',
          email: '',
          subscribe: false,
          subscribe_start: '',
          subscribe_end: '',
          done_mail_cert: false,
        },
      };
    default:
      return state;
  }
};
