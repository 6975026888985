import { SOURCES } from '../constants';
import { NEW_CATEGORY_LIST } from '../category';

export const parseCategories = (categoryString) => {
  const parsedCats = categoryString ? categoryString.split(',') : [];
  return parsedCats.filter((cat) =>
    NEW_CATEGORY_LIST.find((category) => category.id.toString() === cat)
  );
};
export const parseMiddleCategory = (durationString) => {
  const parsedMiddles = durationString ? durationString.split(',') : [];
  return parsedMiddles.filter((middle) =>
    NEW_CATEGORY_LIST.flatMap((x) => x.sub).find(
      (listDur) => listDur.id.toString() === middle
    )
  );
};
export const parseSources = (sourceString) => {
  const parsedSources = sourceString ? sourceString.split(',') : [];
  return parsedSources.filter((source) =>
    Object.keys(SOURCES).includes(source)
  );
};
export const parseSubCategory = (subString) => {
  const parsedSubs = subString ? subString.split(',') : [];
  return parsedSubs.filter((sub) =>
    NEW_CATEGORY_LIST.flatMap((x) => x.sub)
      .flatMap((x) => x.sub)
      .find((x) => x.id.toString() === sub)
  );
};
