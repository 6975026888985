import styled from 'styled-components';
import { Link as RRDLink } from 'react-router-dom';

const LinkOuterWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  margin: 2.125rem 0;
  border: 3px solid transparent;
  border-radius: 33px;
  background-origin: border-box;
  background-image: linear-gradient(180deg, #3b5ee8 0%, #e51ce4 100%),
    linear-gradient(0deg, rgba(217, 217, 217, 0), rgba(217, 217, 217, 0));
`;

const LinkInnerWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  border-radius: 33px;
`;

const CustomLink = styled(RRDLink)`
  font-family: inter;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #37ecec;
  background-color: black;
  border: 3px solid #37ecec;
  border-radius: 33px;
  width: 14.25rem;
  height: 4.375rem;
  font-size: 2rem;
`;

const Link = (props) => {
  const { style, to, children, forwardRef } = props;
  return (
    <LinkOuterWrapper>
      <LinkInnerWrapper>
        <CustomLink style={style} to={to} ref={forwardRef}>
          {children}
        </CustomLink>
      </LinkInnerWrapper>
    </LinkOuterWrapper>
  );
};

export default Link;
