import React from 'react';
import SnackbarProvider from 'react-simple-snackbar';
import { StyledEngineProvider } from '@mui/styled-engine';
import Router from './Router';
import GlobalErrorCatcher from './Components/GlobalErrorCatcher';
import { AppProvider } from './Context/AppContext';
import { FavouritesProvider } from './Context/FavouritesContext';
import { MixerProvider } from './Context/MixerContext';
import { UserProvider } from './Context/UserContext';

export default function App() {
  return (
    <GlobalErrorCatcher>
      <SnackbarProvider>
        <FavouritesProvider>
          <MixerProvider>
            <AppProvider>
              <UserProvider>
                <StyledEngineProvider injectFirst>
                  <Router />
                </StyledEngineProvider>
              </UserProvider>
            </AppProvider>
          </MixerProvider>
        </FavouritesProvider>
      </SnackbarProvider>
    </GlobalErrorCatcher>
  );
}
