import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import { useTranslation } from 'react-i18next';

import GelIcon from '../GelIcon';
import getQuerystring from '../../utilities/getQuerystring';
import modifyExistingQuerystring from '../../utilities/modifyExistingQuerystring';
import { THEME_COLOURS, RESULT_SIZE_CONFIG } from '../../constants';

import { NEW_CATEGORY_LIST } from '../../category';
import MixerToggle from '../MixerToggle';
import AppliedFilters from './AppliedFilters';
import {
  parseCategories,
  parseMiddleCategory,
  parseSubCategory,
  parseSources,
} from '../../utilities/queryParamParsers';

function WideFilter({ history }) {
  const {
    state: { resultsLength, categoryAggregations },
    echoTrack,
  } = useContext(AppContext);
  const {
    state: { mixerModeOn },
  } = useContext(MixerContext);

  // Store a reference to the page the user is on
  const pageContext = history.location.pathname.substring(1);

  const [filterMenuOpen, setFilterMenuOpen] = useState(true);
  const { t } = useTranslation();
  // Get search term from query string
  const parsedQuerystring = getQuerystring();

  // Use search term or default to empty
  const [categories, setCategories] = useState(
    parseCategories(parsedQuerystring.cat)
  );
  const [middles, setMiddles] = useState(
    parseMiddleCategory(parsedQuerystring.durations)
  );
  const [sources, setSources] = useState(
    parseSources(parsedQuerystring.source)
  );
  const [subs, setSubs] = useState(
    parseSubCategory(parsedQuerystring.continents)
  );
  const [recordists, setRecordists] = useState(
    parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []
  );
  const [habitats, setHabitats] = useState(
    parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []
  );

  // Update the categories and durations when the URL params change
  useEffect(() => {
    setCategories(parseCategories(parsedQuerystring.cat));
    setMiddles(parseMiddleCategory(parsedQuerystring.durations));
    setSources(parseSources(parsedQuerystring.source));
    setSubs(parseSubCategory(parsedQuerystring.continents));
    setRecordists(
      parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []
    );
    setHabitats(
      parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []
    );
  }, [
    parsedQuerystring.cat,
    parsedQuerystring.durations,
    parsedQuerystring.continents,
    parsedQuerystring.recordist,
    parsedQuerystring.source,
    parsedQuerystring.habitat,
  ]);

  const [showCategories, toggleShowCategories] = useState(false);
  const [showDurations, toggleShowDurations] = useState(false);
  const [showContinents, toggleShowContinents] = useState(false);

  const performSearch = () => {
    echoTrack(pageContext, { action: 'desktop_filter_search' }, 'click');

    // This will reset other search filters
    history.push(
      `/${pageContext}?${modifyExistingQuerystring(
        'cat',
        categories.join(','),
        modifyExistingQuerystring(
          'durations',
          middles.join(','),
          modifyExistingQuerystring('continents', subs.join(','))
        )
      )}`
    );
  };

  const toggleCategory = (category) => {
    echoTrack(
      pageContext,
      { action: 'desktop_filter_category_toggled', data: { category } },
      'click'
    );

    if (categories.includes(category)) {
      setCategories(_.filter(categories, (cat) => cat !== category));

      const cat = NEW_CATEGORY_LIST.find((x) => x.id.toString() === category);
      setMiddles(
        _.filter(
          middles,
          (mid) => !cat.sub.map((x) => x.id.toString()).includes(mid)
        )
      );

      setSubs(
        _.filter(
          subs,
          (sub) =>
            !cat.sub
              .flatMap((x) => x.sub)
              .map((x) => x.id.toString())
              .includes(sub)
        )
      );
    } else {
      setCategories([category, ...categories]);
    }
  };

  const toggleMiddles = (middle) => {
    echoTrack(
      pageContext,
      { action: 'desktop_filter_duration_toggled', data: { duration: middle } },
      'click'
    );

    if (middles.includes(middle)) {
      setMiddles(_.filter(middles, (mid) => mid !== middle));

      const middle_cat = NEW_CATEGORY_LIST.flatMap((x) => x.sub).find(
        (x) => x.id.toString() === middle
      );
      setSubs(
        _.filter(
          subs,
          (mid) => !middle_cat.sub.map((x) => x.id.toString()).includes(mid)
        )
      );
    } else {
      setMiddles([middle, ...middles]);
    }
  };

  const toggleSubs = (sub) => {
    echoTrack(
      pageContext,
      { action: 'desktop_filter_continent_toggled', data: { sub } },
      'click'
    );

    if (subs.includes(sub)) {
      setSubs(_.filter(subs, (cont) => cont !== sub));
    } else {
      setSubs([sub, ...subs]);
    }
  };

  const closeFilterDropdowns = () => {
    toggleShowCategories(false);
    toggleShowDurations(false);
    toggleShowContinents(false);
  };

  const applyFilters = () => {
    closeFilterDropdowns();
    performSearch();
  };

  const resetFilters = () => {
    echoTrack(pageContext, { action: 'desktop_filter_reset' }, 'click');

    closeFilterDropdowns();

    // This will reset other search filters
    history.push(
      `/${pageContext}?${modifyExistingQuerystring(
        'cat',
        [],
        modifyExistingQuerystring(
          'durations',
          [],
          modifyExistingQuerystring(
            'continents',
            [],
            modifyExistingQuerystring(
              'recordist',
              [],
              modifyExistingQuerystring(
                'source',
                [],
                modifyExistingQuerystring('habitat', [])
              )
            )
          )
        )
      )}`
    );
  };

  const cancelCategoryFilter = () => {
    setCategories(
      parsedQuerystring.cat ? parsedQuerystring.cat.split(',') : []
    );
    toggleShowCategories(false);
  };

  const cancelDurationFilter = () => {
    setMiddles(
      parsedQuerystring.durations ? parsedQuerystring.durations.split(',') : []
    );
    toggleShowDurations(false);
  };

  const cancelContinentFilter = () => {
    setSubs(
      parsedQuerystring.continents
        ? parsedQuerystring.continents.split(',')
        : []
    );
    toggleShowContinents(false);
  };

  useEffect(() => {
    if (showCategories) {
      toggleShowDurations(false);
      toggleShowContinents(false);
    }
  }, [showCategories]);

  useEffect(() => {
    if (showDurations) {
      toggleShowCategories(false);
      toggleShowContinents(false);
    }
  }, [showDurations]);

  useEffect(() => {
    if (showContinents) {
      toggleShowCategories(false);
      toggleShowDurations(false);
    }
  }, [showContinents]);

  return (
    <div
      className={`${
        mixerModeOn ? 'bg-gray-700' : 'bg-gray-100'
      } w-full pb-3 transition-all duration-200 ease-in-out`}
    >
      {/* Clickaway Overlay */}
      {(showCategories || showContinents || showDurations) && (
        <button
          className="fixed top-0 left-0 w-full h-screen bg-transparent focus:outline-none"
          onClick={closeFilterDropdowns}
        />
      )}
      <div className="max-w-screen-xl px-3 pt-4 mx-auto">
        <div className="flex flex-row items-center justify-between">
          <h2 className="text-lg text-gray-900">
            {pageContext === 'search' ? (
              <span>
                Showing&nbsp;
                {RESULT_SIZE_CONFIG.sizeLimit < resultsLength && (
                  <span>
                    top <strong>{RESULT_SIZE_CONFIG.sizeLimit}</strong> of&nbsp;
                  </span>
                )}
                <strong>{resultsLength}</strong>
                {parsedQuerystring.q ? (
                  <span>
                    {' '}
                    results for <strong>{parsedQuerystring.q}</strong>
                  </span>
                ) : (
                  <span> results</span>
                )}
              </span>
            ) : (
              <span>
                Your Favourites <strong>({resultsLength})</strong>
              </span>
            )}
          </h2>
          <div className="flex flex-row items-center">
            <MixerToggle />
            <div className="flex flex-row">
              <div className="relative px-2 lg:px-4"></div>
              <button
                type="button"
                className={`focus:outline-none ${
                  !filterMenuOpen
                    ? 'bg-gray-100 border-gray-100'
                    : 'bg-white border-gray-400 white-bottom-border'
                } ${
                  mixerModeOn
                    ? !filterMenuOpen
                      ? 'bg-gray-700 border-gray-700'
                      : 'bg-white'
                    : ''
                } transition-all duration-200 ease-in-out border flex flex-row items-center px-3 py-1 hover:underline focus:underline`}
                onClick={() => setFilterMenuOpen(!filterMenuOpen)}
                aria-label={
                  filterMenuOpen ? 'Close filter menu' : 'Open filter menu'
                }
              >
                <GelIcon
                  name="filter"
                  fill={THEME_COLOURS.PRIMARY}
                  className="w-5 h-5"
                />
                <span className="px-2 font-bold text-gray-900 lg:text-xl">
                  Filter (
                  {categories.length +
                    middles.length +
                    subs.length +
                    recordists.length +
                    sources.length +
                    habitats.length}
                  )
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {filterMenuOpen && (
        <div className="py-3 bg-white border-t border-b border-gray-400">
          <div className="flex flex-row items-center max-w-screen-xl px-3 mx-auto">
            <div className="flex flex-row w-5/6 mr-4 space-x-4">
              <div className="relative w-1/3">
                <button
                  type="button"
                  className="flex flex-row items-center justify-between w-full px-3 py-2 bg-white border-2 border-gray-400 focus:outline-none hover:border-blue-800 focus:border-blue-800"
                  onClick={() => toggleShowCategories(!showCategories)}
                >
                  <span className="text-base font-bold text-gray-900 lg:text-lg">
                    Categories ({categories.length})
                  </span>
                  <GelIcon
                    className="w-4 h-4"
                    name={showCategories ? 'chevron-up' : 'chevron-down'}
                    fill={THEME_COLOURS.SECONDARY}
                  />
                </button>
                {showCategories && (
                  <div className="absolute z-10 block w-64 lg:w-full">
                    <div className="bg-white border-b-2 border-l-2 border-r-2 border-gray-400">
                      <ul className="py-2 overflow-y-auto scrolling-touch h-72">
                        {NEW_CATEGORY_LIST.length &&
                          NEW_CATEGORY_LIST.map((category) => (
                            <li key={category.id}>
                              <button
                                type="button"
                                role="checkbox"
                                aria-checked={categories.includes(
                                  category.id.toString()
                                )}
                                onClick={() =>
                                  toggleCategory(category.id.toString())
                                }
                                className="flex flex-row items-center justify-between w-full px-4 py-2 focus:outline-none text-md hover:underline focus:underline"
                              >
                                <span className="text-gray-900 truncate">
                                  {category.name}
                                </span>
                                <div className="flex flex-row items-center">
                                  <small className="mr-2 text-gray-900">
                                    {categoryAggregations[category.id] &&
                                      `(${
                                        categoryAggregations[category.id]
                                          .doc_count
                                      })`}
                                  </small>
                                  {categories.includes(
                                    category.id.toString()
                                  ) ? (
                                    <div className="w-6 h-6 border-2 border-gray-900">
                                      <GelIcon
                                        className="z-20"
                                        name="check"
                                        fill={THEME_COLOURS.PRIMARY}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-6 h-6 border-2 border-gray-900" />
                                  )}
                                </div>
                              </button>
                            </li>
                          ))}
                      </ul>
                      <div className="flex w-full">
                        <div className="w-1/2">
                          <button
                            type="button"
                            className="items-center w-full h-full p-4 font-bold text-white bg-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75"
                            onClick={cancelCategoryFilter}
                          >
                            {t('cancel')}
                          </button>
                        </div>
                        <div className="w-1/2">
                          <button
                            type="button"
                            className="items-center w-full h-full p-4 font-bold text-white bg-teal-700 focus:outline-none hover:opacity-75 focus:opacity-75"
                            onClick={applyFilters}
                          >
                            {t('apply')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="relative w-1/3">
                <button
                  type="button"
                  className="flex flex-row items-center justify-between w-full px-3 py-2 bg-white border-2 border-gray-400 focus:outline-none hover:border-blue-800 focus:border-blue-800"
                  onClick={() => toggleShowDurations(!showDurations)}
                >
                  <span className="text-base font-bold text-gray-900 lg:text-lg">
                    Middle ({middles.length})
                  </span>
                  <GelIcon
                    className="w-4 h-4"
                    name={showDurations ? 'chevron-up' : 'chevron-down'}
                    fill={THEME_COLOURS.SECONDARY}
                  />
                </button>
                {showDurations && (
                  <div className="absolute z-10 block w-64 lg:w-full">
                    <div className="bg-white border-b-2 border-l-2 border-r-2 border-gray-400">
                      <ul className="py-2">
                        {NEW_CATEGORY_LIST.filter((cat) =>
                          categories.includes(cat.id.toString())
                        )
                          .flatMap((x) => x.sub)
                          .map((cat_middle) => (
                            <li key={cat_middle.id}>
                              <button
                                type="button"
                                role="checkbox"
                                aria-checked={middles.includes(
                                  cat_middle.id.toString()
                                )}
                                onClick={() =>
                                  toggleMiddles(cat_middle.id.toString())
                                }
                                className="flex flex-row items-center justify-between w-full px-4 py-2 focus:outline-none text-md hover:underline focus:underline"
                              >
                                <span className="text-gray-900 truncate">
                                  {cat_middle.name}
                                </span>
                                <div className="flex flex-row items-center">
                                  <small className="mr-2 text-gray-900">
                                    {categoryAggregations[cat_middle.id] &&
                                      `(${
                                        categoryAggregations[cat_middle.id]
                                          .doc_count
                                      })`}
                                  </small>
                                  {middles.includes(
                                    cat_middle.id.toString()
                                  ) ? (
                                    <div className="w-6 h-6 border-2 border-gray-900">
                                      <GelIcon
                                        className="z-20"
                                        name="check"
                                        fill={THEME_COLOURS.PRIMARY}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-6 h-6 border-2 border-gray-900" />
                                  )}
                                </div>
                              </button>
                            </li>
                          ))}
                      </ul>
                      <div className="flex w-full">
                        <div className="w-1/2">
                          <button
                            type="button"
                            className="items-center w-full h-full p-4 font-bold text-white bg-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75"
                            onClick={cancelDurationFilter}
                          >
                            {t('cancel')}
                          </button>
                        </div>
                        <div className="w-1/2">
                          <button
                            type="button"
                            className="items-center w-full h-full p-4 font-bold text-white bg-teal-700 focus:outline-none hover:opacity-75 focus:opacity-75"
                            onClick={applyFilters}
                          >
                            {t('apply')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="relative w-1/3">
                <button
                  type="button"
                  className="flex flex-row items-center justify-between w-full px-3 py-2 bg-white border-2 border-gray-400 focus:outline-none hover:border-blue-800 focus:border-blue-800"
                  onClick={() => toggleShowContinents(!showContinents)}
                >
                  <span className="text-base font-bold text-gray-900 lg:text-lg">
                    Sub ({subs.length})
                  </span>
                  <GelIcon
                    className="w-4 h-4"
                    name={showContinents ? 'chevron-up' : 'chevron-down'}
                    fill={THEME_COLOURS.SECONDARY}
                  />
                </button>
                {showContinents && (
                  <div className="absolute z-10 block w-64 lg:w-full">
                    <div className="bg-white border-b-2 border-l-2 border-r-2 border-gray-400">
                      <ul className="py-2">
                        {NEW_CATEGORY_LIST.flatMap((x) => x.sub)
                          .filter((x) => middles.includes(x.id.toString()))
                          .flatMap((x) => x.sub)
                          .map((sub_cat) => (
                            <li key={sub_cat.id}>
                              <button
                                type="button"
                                role="checkbox"
                                aria-checked={subs.includes(
                                  sub_cat.id.toString()
                                )}
                                onClick={() =>
                                  toggleSubs(sub_cat.id.toString())
                                }
                                className="flex flex-row items-center justify-between w-full px-4 py-2 focus:outline-none text-md hover:underline focus:underline"
                              >
                                <span className="text-gray-900 truncate">
                                  {sub_cat.name}
                                </span>
                                <div className="flex flex-row items-center">
                                  <small className="mr-2 text-gray-900">
                                    {categoryAggregations[sub_cat.id] &&
                                      `(${
                                        categoryAggregations[sub_cat.id]
                                          .doc_count
                                      })`}
                                  </small>
                                  {subs.includes(sub_cat.id.toString()) ? (
                                    <div className="w-6 h-6 border-2 border-gray-900">
                                      <GelIcon
                                        className="z-20"
                                        name="check"
                                        fill={THEME_COLOURS.PRIMARY}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-6 h-6 border-2 border-gray-900" />
                                  )}
                                </div>
                              </button>
                            </li>
                          ))}
                      </ul>
                      <div className="flex w-full">
                        <div className="w-1/2">
                          <button
                            type="button"
                            className="items-center w-full h-full p-4 font-bold text-white bg-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75"
                            onClick={cancelContinentFilter}
                          >
                            {t('cancel')}
                          </button>
                        </div>
                        <div className="w-1/2">
                          <button
                            type="button"
                            className="items-center w-full h-full p-4 font-bold text-white bg-teal-700 focus:outline-none hover:opacity-75 focus:opacity-75"
                            onClick={applyFilters}
                          >
                            {t('apply')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/5 lg:w-1/6">
              <button
                type="button"
                className="flex flex-row items-center justify-center w-full px-2 py-2 font-bold bg-blue-800 border-0 focus:outline-none hover:opacity-75 focus:opacity-75"
                onClick={resetFilters}
              >
                <GelIcon className="w-5 h-5" name="refresh" fill="white" />
                <span className="pl-2 text-base text-white lg:text-lg">
                  {t('reset_filters')}
                </span>
              </button>
            </div>
          </div>
          <AppliedFilters />
        </div>
      )}
    </div>
  );
}

WideFilter.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default withRouter(WideFilter);
