import { FAVOURITES_CONFIG, LOCALSTORAGE_FAVOURITES } from '../../constants';
import { UPDATE_FAVOURITES } from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_FAVOURITES: {
      const updatedFavourites = action.favouriteIds.slice(
        0,
        FAVOURITES_CONFIG.sizeLimit
      );

      window.localStorage.setItem(
        LOCALSTORAGE_FAVOURITES,
        JSON.stringify(updatedFavourites)
      );
      return {
        ...state,
        favouriteIds: updatedFavourites,
      };
    }
    default:
      return state;
  }
};
