import React, {
  useContext,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { CATEGORY_LIST_MOST_POPULAR } from '../../constants';
import { NEW_CATEGORY_LIST } from '../../category';
import { AppContext } from '../../Context/AppContext';
import GelIcon from '../../Components/GelIcon';
import getQueryString from '../../utilities/getQuerystring';
import Container from '../../Components/Container';
import Button from '../../Components/Button';
import CustomLink from '../../Components/Link';
import HomeSearchResultItem from '../../Components/HomeSearchResultItem';

const CategoryCard = ({ category, value, focusRef }) => {
  return (
    <Link
      to={`/search?cat=${category.id}`}
      className="flex items-center p-3 bg-black border-2 border-transparent border-solid rounded-md shadow-sm group hover:border-yellow focus:border-yellow focus:outline-none"
      forwardRef={focusRef}
    >
      <div
        className={`flex items-center justify-center w-4 h-4 mr-2 rounded-full group-hover:bg-yellow group-focus:bg-yellow bg-yellow text-teal-700 group-focus:text-white group-hover:text-white`}
      >
        <GelIcon
          name="viewBox"
          viewBox={category.viewBox}
          fill={null}
          className="w-2 h-2 fill-current"
        />
      </div>
      <p className="text-white px-1">
        <span className="font-semibold ">{category.name}</span>{' '}
        {value ? `(${value})` : ''}
      </p>
    </Link>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    viewBox: PropTypes.string,
  }),
  value: PropTypes.number.isRequired,
  focusRef: PropTypes.shape({}),
};

const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: clip;
  margin-bottom: 6.25rem;
`;

const MainBackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 65.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  #main-background-img {
    z-index: 1;
    top: 0;
    transform: scale(2.8) translateY(4%);
    position: absolute;
    width: 100%;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1400px;
`;

const HomeCenterWrapper = (props) => {
  const { style, children } = props;
  return (
    <div
      style={{
        fontFamily: 'Inter',
        top: 0,
        position: 'absolute',
        zIndex: 2,
        transform: style.transform,
      }}
    >
      {children}
    </div>
  );
};

HomeCenterWrapper.propTypes = {
  props: PropTypes.shape({
    style: PropTypes.shape({}),
  }),
};

const HomeTitleWrapper = styled.div`
  position: relative;
  width: 100%;
  color: #37ecec;
  font-weight: 700;
  font-size: 4.3rem;
  line-height: 3.5rem;
  z-index: 2;
  > span {
    font-size: 3.25rem;
  }
`;

const HomeDescWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
  color: #d9d9d9;
  font-weight: 400;
  font-size: 2rem;
  border-top: 2px solid 
  border-bottom: 2px solid 
`;

const HomeDescLineTop = styled.div`
  width: 100%;
  margin: 1.875rem 0;
  border-top: 2px solid transparent;
  background-image: linear-gradient(270deg, #37ecec 0%, #3b5ee8 100%);
  background-origin: border-box;
`;

const HomeDescLineBottom = styled.div`
  width: 100%;
  margin: 1.875rem 0 0 0;
  border-top: 2px solid transparent;
  background-image: linear-gradient(90deg, #37ecec 0%, #3b5ee8 100%);
  background-origin: border-box;
`;

const HomeDescButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 0%,
    rgba(59, 94, 232, 0.33) 100%
  );
`;

const CategoryTitleWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
`;

const CategoryTitle = styled.div`
  flex: 1;
  color: #d9d9d9;
  font-weight: 400;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

const CategoryTitleLineWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CategoryTitleLine = styled.div`
  width: 100%;
  border-top: 5px solid #3b5ee8;
`;

const CategoryTitleBottomLine = styled.div`
  width: 100%;
  border-top: 5px solid #e51ce4;
`;

const CategoriesWrapper = styled.div`
  width: 100%;
  margin: 2.125rem 0;
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

const SoundEffectsWrapper = styled.div`
  background: linear-gradient(180deg, #3b5ee8 0%, #e51ce4 100%);
  border-top: #3b5ee8 3px solid;
  border-bottom: #e51ce4 3px solid;
  position: relative;
  width: 100%;
`;

const EditMixerWrapper = styled.div`
  font-family: inter;
  position: relative;
  width: 100%;
  height: 82.5rem;
  background-image: url('/images/mixer_background.gif');
  background-size: cover;
  background-position: center;
`;

const EditMixerGradientWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 17.625rem;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
`;

const EditMixerColorWrapper = styled.div`
  position: absolute;
  z-index: 3;
  background-color: #3b5ee854;
  width: 100%;
  height: 100%;
`;

const EditMixerContentWrapper = styled.div`
  top: 50%;
  transform: translateY(-40%);
  position: relative;
  z-index: 4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EditMixerIconWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const EditMixerDescWrapper = styled.div`
  position: relative;
  width: 100%;
  color: #d9d9d9;
  font-weight: 400;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  margin-bottom: 100px;
`;

const EditMixerButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ApplicationWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: inter;
  color: #d9d9d9;
  background-color: #131f4d;
`;

const ApplicationContainer = styled.div`
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ApplicationTitleWrapper = styled.div`
  position: relative;
  font-family: Inter;
  font-weight: 700;
  font-size: 3rem;
  z-index: 2;
  margin-top: 18.5rem;
  margin-bottom: 1rem;
`;

const ApplicationContentsWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1500px;
  height: 58.125rem;
  background-color: #3b5ee8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4.625rem;
  margin-bottom: 18.5rem;
`;

const ApplicationImageWrapper = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    margin: 105px 150px 60px 130px;
  }
`;

const ApplicationDescWrapper = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transform: translateY(3%);
`;

const ApplicationDescTitleWrapper = styled.div`
  width: 100%;
  font-family: Inter;
  font-weight: 700;
  font-size: 3rem;
`;

const ApplicationDescInnerWrapper = styled.div`
  width: 100%;
  font-family: Inter;
  font-weight: 700;
  font-size: 2rem;
  color: #d9d9d9;
`;

const ApplicationDescAppIconWrapper = styled.div`
  width: 100%;
  > img {
    width: 18.75rem;
  }
`;

const FAQWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  background-color: #131f4d;
`;

const FAQContainer = styled.div`
  position: relative;
  display: flex;
  width: 90%;
  max-width: 1500px;
  flex-direction: column;
  justify-content: center;
`;

const FAQTitleWrapper = styled.div`
  position: relative;
  justify-content: center;
  font-size: 3rem;
  font-weight: 700;
  color: #d9d9d9;
  margin: 5rem 0 3.75rem 0;
  text-align: center;
`;

const FAQItemsWrapper = styled.div`
  positiion: relative;
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 6.875rem 0;
`;

const FAQItemWrapper = styled.div`
  width: 100%;
  background-color: #3b5ee8;
  border-radius: 80px;
  padding: 0.313rem 1.25rem;
  margin-bottom: 2.5rem;
`;

const StyledAccordion = styled(Accordion)`
  width: 100% !important;
  box-shadow: none !important;
  color: white !important;
  background-color: #ffffff00 !important;
  font-family: Inter !important;
  font-weight: 700 !important;
  font-size: 2rem !important;
  padding: 1rem !important;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiSvgIcon-root {
    color: white;
    width: 6rem;
    height: 6rem;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  color: #d9d9d9 !important;
  font-size: 1.5rem !important;
`;

const Home = ({ history }) => {
  const {
    state: { results, resultsLoading },
    searchByCategory,
    echoTrack,
  } = useContext(AppContext);
  const [imageSize, setImageSize] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(1);
  const categoryRef = useRef();
  const imageRef = useRef();
  const { t } = useTranslation();

  const onImageLoad = (event) => {
    setImageSize(event.target.height);
  };

  const handleResize = useCallback(() => {
    if (imageRef.current) {
      setImageSize(imageRef.current.height);
    }
  }, [imageSize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    searchByCategory(currentCategory);
  }, [currentCategory]);

  // Clear search results from state on component mount
  useLayoutEffect(() => {
    const queryParams = getQueryString();
    if (queryParams && (queryParams.q || queryParams.cat)) {
      //redirect to the search page, assuming no prior search state
      history.push(`/search?
      ${queryParams.q ? `q=${queryParams.q}` : ''}
      ${queryParams.q && queryParams.cat ? '&' : ''}
      ${queryParams.cat ? `cat=${queryParams.cat}` : ''}`);
    }
    echoTrack('homepage');
  }, [history]);

  const categoriesToShow = CATEGORY_LIST_MOST_POPULAR.map((category) =>
    NEW_CATEGORY_LIST.find((x) => x.id === category)
  );

  console.log(categoriesToShow);

  return (
    <>
      <Helmet title="사운드윅스 SOUNDWIX" />
      <MainWrapper>
        <Container>
          <InnerContainer>
            <MainBackgroundWrapper
              style={{ height: `calc(34.375rem + ${imageSize}px)` }}
            >
              <img
                ref={imageRef}
                id="main-background-img"
                src="/images/main_background.gif"
                onLoad={onImageLoad}
              />
              <HomeCenterWrapper
                style={{
                  transform: `translateY(${imageSize / 2}px) translateY(-25%)`,
                }}
              >
                <HomeTitleWrapper>
                  SOUNDWIX
                  <br />
                  <span>SOUNDEFFECT</span>
                </HomeTitleWrapper>
                <HomeDescLineTop />
                <HomeDescWrapper>
                  SoundsEffects
                  <br />
                  Premium Quality
                  <br />
                  All the Music you Want
                </HomeDescWrapper>
                <HomeDescLineBottom />
                <HomeDescButtonWrapper>
                  <CustomLink to={'/about'}>ABOUT</CustomLink>
                </HomeDescButtonWrapper>
              </HomeCenterWrapper>
            </MainBackgroundWrapper>
          </InnerContainer>
        </Container>
      </MainWrapper>
      <CategoryWrapper>
        <Container>
          <InnerContainer>
            <CategoryTitleWrapper>
              <CategoryTitleLineWrapper>
                <CategoryTitleLine />
              </CategoryTitleLineWrapper>
              <CategoryTitle>HOT & NEW</CategoryTitle>
              <CategoryTitleLineWrapper>
                <CategoryTitleLine />
              </CategoryTitleLineWrapper>
            </CategoryTitleWrapper>
            <CategoriesWrapper>
              {categoriesToShow
                .slice(0, NEW_CATEGORY_LIST.length)
                .map((category, index) => {
                  return (
                    <Button
                      key={category.id}
                      onClick={() => setCurrentCategory(category.id)}
                    >
                      {category.name}
                    </Button>
                  );
                })}
            </CategoriesWrapper>
            <SoundEffectsWrapper>
              {results.length === 0 && resultsLoading ? (
                <div className="py-10 text-2xl font-bold text-center">
                  Loading...
                </div>
              ) : (
                <>
                  {results.length > 0 ? (
                    <>
                      {results.slice(0, 7).map((asset) => (
                        <HomeSearchResultItem key={asset.id} asset={asset} />
                      ))}
                    </>
                  ) : (
                    <div style={{ border: '4px solid transparent' }}>
                      <div className="py-10 text-2xl bg-white font-bold text-center">
                        No results found
                      </div>
                    </div>
                  )}
                </>
              )}
            </SoundEffectsWrapper>
            <CategoryTitleWrapper>
              <CategoryTitleLineWrapper>
                <CategoryTitleBottomLine />
              </CategoryTitleLineWrapper>
              <CategoryTitle>
                <CustomLink
                  to={`/search?cat=${currentCategory}`}
                  forwardRef={categoryRef}
                >
                  MORE
                </CustomLink>
              </CategoryTitle>
              <CategoryTitleLineWrapper>
                <CategoryTitleBottomLine />
              </CategoryTitleLineWrapper>
            </CategoryTitleWrapper>
          </InnerContainer>
        </Container>
      </CategoryWrapper>
      <EditMixerWrapper>
        <EditMixerGradientWrapper />
        <EditMixerColorWrapper />
        <EditMixerContentWrapper>
          <InnerContainer>
            <EditMixerIconWrapper>
              <img src="/images/audiomixer.png" />
            </EditMixerIconWrapper>
            <EditMixerDescWrapper>Create your Music</EditMixerDescWrapper>
            <EditMixerButtonWrapper>
              <CustomLink to={'/search'}>TRY NOW</CustomLink>
            </EditMixerButtonWrapper>
          </InnerContainer>
        </EditMixerContentWrapper>
      </EditMixerWrapper>
      <ApplicationWrapper id="application" name="application">
        <ApplicationContainer>
          <ApplicationTitleWrapper>APPLICATIONS</ApplicationTitleWrapper>
          <ApplicationContentsWrapper>
            <ApplicationImageWrapper>
              <img src="/images/application_image.png" />
            </ApplicationImageWrapper>
            <ApplicationDescWrapper>
              <ApplicationDescTitleWrapper>
                SOUNDWIXAPP
              </ApplicationDescTitleWrapper>
              <ApplicationDescInnerWrapper>
                Lorem ipsum dolor sit amet,
                <br />
                consectetur adipiscing elit.
                <br />
                sed do eiusmod tempor incididunt
                <br />
                ut labore et dolore magna aliqua.
                <br />
                Ut enim ad minim veniam.
              </ApplicationDescInnerWrapper>
              <ApplicationDescAppIconWrapper>
                <img src="/images/google_play_icon.png" />
              </ApplicationDescAppIconWrapper>
            </ApplicationDescWrapper>
          </ApplicationContentsWrapper>
        </ApplicationContainer>
      </ApplicationWrapper>
      <FAQWrapper>
        <FAQContainer>
          <FAQTitleWrapper>FAQs</FAQTitleWrapper>
          <FAQItemsWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  사운드윅스에서 컨텐츠를 다운로드 받으려면 어떻게 해야되나요?
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  사운드윅스 구독 가입 후 이용권을 구매하시면 컨텐츠를 다운로드
                  할 수 있습니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  결제 버튼을 눌렀는데 아무 반응이 없어요.
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  모바일 브라우저 및 PC 브라우저에서 팝업차단 기능이 활성화 된
                  경우 결제 창이 뜨지 않습니다. 팝업차단 기능을 해제하고 다시
                  시도해주세요.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  사운드윅스 컨텐츠를 사용한 영상으로 유튜브 광고수익을 받을 수
                  있나요?
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  네, 가능합니다. 사운드윅스의 컨텐츠는 저작자들과의 계약을 통해
                  저작권이 100% 해결된 음악입니다. 사운드윅스 컨텐츠를
                  유튜브에서 사용하시면 저작권 걱정 없이 광고수익 창출이
                  가능합니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  사운드윅스에서 다운로드 받은 컨텐츠가 제3자의 소유라는
                  YouTube의 고지를 받았습니다. 어떻게 해야하나요?
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  사운드윅스의 모든 음악은 저작권법을 100% 준수합니다.
                  <br />
                  이용권을 구매하시는 구독님들께서 저작권 문제 없이 사용
                  가능하도록 최선을 다해 서비스하고 있으나, 유튜브 알고리즘의
                  문제로 음원을 다른 곡으로 인식하여 저작권 침해 주장이 제기되는
                  경우가 간혹 있습니다. 이러한 경우 가장 효율적인 대응 방법은
                  사운드윅스 컨텐츠 구매 내역과 함께 YouTube에 이의제기를
                  신청하는 것입니다.
                  <br />
                  사운드윅스 측에 이메일로 설명과 함께 해당 영상의 링크를
                  전달해주시면 자세한 해결 방법을 안내드립니다. 이 와 같은
                  문제는 영상 업로드 시 크레딧을 표기하는 방법으로 확률을 줄일
                  수 있으니 크레딧 표기하는 것을 권장합니다. 또한 저작권 침해
                  주장 기간 동안에 발생한 영상 수익은 보류되었다가 주장이
                  철회되면 정상적으로 지급되는 점도 참고 바랍니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  개인인데, 여러 개의 유튜브 채널에서 사용하고 싶어요. 어떻게
                  해야 하나요?
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  하나의 사운드윅스 계정에서 여러 개의 이용권을 중복하여 구매
                  또는 기업 자사 콘텐츠 이용권(곡별 결제 제외)으로 사용
                  가능합니다. 2개 이상 구독 시, 결제일이 빠른 이용권부터
                  다운로드 횟수가 차감됩니다. 또한 중복 구독 시, 이미 구독중인
                  이용권은 자동 해지 되지 않으며, 마이페이지 에서 직접 해지가
                  가능합니다. 기업 월간/연간 이용권 구독 시, 5개의 유튜브
                  채널에서 사용 가능하며 마이페이지 결제 내역에서 채널 등록
                  가능합니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  한 번 다운 받으면 평생 사용이 가능한가요?
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  구독 기간 중에 업로드(공표)하신 영상에 한하여, 구독 종료
                  후에도 광고수익 창출이 가능 합니다. 구독 기간 내에 받은
                  컨텐츠이라도, 구독 종료 후 새로 업로드하는 영상에는 사용할 수
                  없습니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  다운 받은 음원을 편집해서 사용해도 되나요?
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  다운 받은 음원의 단순 편집(크롭, 보컬 커버, 악기추가, 리믹스
                  등)은 가능합니다.
                  <br />
                  그러나 편집한 음원의 사용범위는 컨텐츠의 사용과 동일하게
                  제한되어, 어떠한 경우에도 아래 목적의 컨텐츠 사용은
                  금지됩니다.
                  <br />
                  ㆍP2P 업로드, 타인에게 제공 및 재판매
                  <br />
                  음원 발매를 원하실 경우에는 help@digiwix.com로 문의주시면,
                  별도 계약체결 후 가능하시며, 자세한 사용범위는 [사용 범위
                  안내]를 클릭하여 확인할 수 있습니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  같은 곡을 여러 번 다운로드 받을 수 있나요?
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  다운로드한 음원은 ‘마이페이지>다운로드’ 에서 확인이 가능하며,
                  하나의 ID당 최대 3개의 기기에서 다운로드 가능합니다. 월
                  자동결제 이용권으로 다운로드한 음원은 최초 다운로드한 날로부터
                  1년 이내에, 이용권 구독중일 경우에만 다시 다운로드가
                  가능합니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  월 정기 결제를 해지했는데, 다운로드 받은 음원은 언제까지
                  사용할 수 있나요?
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  종료일로부터 30일 이후에 음원 사용하여 영상을 업로드하시는
                  경우 저작권 침해 신고가 제기될 수 있습니다. 구독 종료 이후
                  30일 이내에 업로드하신 영상은 이후에도 계속해서 저작권 침해
                  주장이 제기되지 않습니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  이용권을 환불 받고 싶어요.
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  한 곡 구매를 하신 경우 환불이 불가능하며, 월 정기 이용권의
                  환불은 마이페이지에서 직접 해당 이용권 해지 신청 후에만
                  가능합니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  월 정기 결제를 해지했는데, 다운로드 받은 음원은 언제까지
                  사용할 수 있나요?
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  종료일로부터 30일 이후에 음원 사용하여 영상을 업로드하시는
                  경우 저작권 침해 신고가 제기될 수 있습니다. 구독 종료 이후
                  30일 이내에 업로드하신 영상은 이후에도 계속해서 저작권 침해
                  주장이 제기되지 않습니다.
                  <br />
                  해지 신청 시, 구독 및 결제 일로부터 7일 이내에 음원 다운로드
                  이력이 없는 경우에 한 하여 결제 취소가 가능합니다.
                  <br />
                  다만, 휴대폰으로 결제 시 당월 결제 건에 대해서만 취소가
                  가능하기 때문에, help@soundwix.com으로 환불 사유와 함께 문의
                  주시면 환불 가능한 건에 한해 환불 처리 도와드리겠습니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
            <FAQItemWrapper>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  아이폰에서는 다운로드 할 수 없나요?
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  아이폰 정책 상 크롬, 네이버앱, 페이스북, 카카오톡앱을 통해
                  접속 시 영상, 음원파일이 다운로드 되지 않으며, 아이폰 내장
                  어플인 ‘safari’ 로 접속하시면 mp3 파일을 다운로드 할 수
                  있습니다. 다운로드된 음원은 아이폰 내장 어플 중 ‘파일’에서
                  ‘최근항목’을 누르시면 확인이 가능합니다.
                </StyledAccordionDetails>
              </StyledAccordion>
            </FAQItemWrapper>
          </FAQItemsWrapper>
        </FAQContainer>
      </FAQWrapper>
    </>
  );
};

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

export default withRouter(Home);
