import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { AppContext } from '../../Context/AppContext';
import GelIcon from '../../Components/GelIcon';
import { MixerContext } from '../../Context/MixerContext';
import getQueryString from '../../utilities/getQuerystring';
import { useTranslation } from 'react-i18next';

const CategoryCard = ({ category, value, focusRef }) => {
  return (
    <Link
      to={`/search?cat=${category.name}`}
      className="flex items-center p-3 bg-white border-2 border-transparent border-solid rounded-md shadow-sm group hover:border-teal-700 focus:border-teal-700 focus:outline-none"
      ref={focusRef}
    >
      <div
        className={`flex items-center justify-center w-8 h-8 mr-2 rounded-full group-hover:bg-teal-700 group-focus:bg-teal-700 bg-teal-100 text-teal-700 group-focus:text-white group-hover:text-white`}
      >
        <GelIcon
          name={category.icon}
          viewBox={category.viewBox}
          fill={null}
          className="w-4 h-4 fill-current"
        />
      </div>
      <p className="text-black">
        <span className="font-semibold no-underline group-focus:underline group-hover:underline">
          {category.name.replace('_', ' ')}
        </span>{' '}
        {value ? `(${value})` : ''}
      </p>
    </Link>
  );
};

const divStyle = {
  height: '10px',
  width: '250px',
};

CategoryCard.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    viewBox: PropTypes.string,
  }),
  value: PropTypes.number.isRequired,
  focusRef: PropTypes.shape({}),
};

function HomeApplication({ history }) {
  const {
    clearSearchResults,
    state: { categoryAggregations },
    echoTrack,
  } = useContext(AppContext);
  const {
    state: { mixerModeOn },
    toggleMixer,
  } = useContext(MixerContext);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const firstCategoryRef = useRef();
  const { t } = useTranslation();

  // Clear search results from state on component mount
  useEffect(() => {
    const queryParams = getQueryString();
    if (queryParams && (queryParams.q || queryParams.cat)) {
      //redirect to the search page, assuming no prior search state
      history.push(`/search?
      ${queryParams.q ? `q=${queryParams.q}` : ''}
      ${queryParams.q && queryParams.cat ? '&' : ''}
      ${queryParams.cat ? `cat=${queryParams.cat}` : ''}`);
    }

    echoTrack('homepage');

    clearSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (showAllCategories) {
      firstCategoryRef.current.focus();
    }
  }, [showAllCategories]);

  return (
    <>
      <Helmet title="�������� SOUNDWIX" />

      <div className={`bg-black transition-all duration-200 ease-in-out`}>
        <div className="max-w-screen-xl px-3 mx-auto bg-black">
          <div className="block w-full pt-0 pb-4 md:py-4">
            <h2
              className="text-xl font-bold md:text-3xl md:mt-40"
              style={{ color: 'White' }}
            >
              <span style={{ color: 'Yellow' }}>A</span>
              pplications <br />
              IOS
              <span style={{ color: 'Green' }}>Android</span>
            </h2>
          </div>

          <div className="mb-5 mr-0 md:mr-5 md:mb-0" style={divStyle}>
            <Link
              to="/search"
              className="items-center relative flex flex-col justify-center p-4 overflow-hidden text-xl font-bold leading-tight text-white duration-200 transform bg-blue-800 rounded-lg lg:text-2xl md:h-20  hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1 bg-gradient-to-br from-blue-800 to-blue-500"
            >
              <h2>{t('free_start_now')}</h2>
            </Link>
          </div>
          <div className="flex flex-col py-10 md:flex-row md:space-y-0 md:mt-80 md:border-t">
            <Link
              to="/about"
              className="relative flex justify-between w-full h-auto p-4 mt-5 mb-5 text-lg font-bold leading-tight text-white duration-200 transform bg-black rounded-lg md:text-xl lg:text-2xl md:flex-row md:justify-between md:h-32 hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1 md:mt-0 md:mb-0 md:mr-5"
            >
              <h2>{t('about_digwix_sound_effects')}</h2>
            </Link>
            <Link
              to="/faqs"
              className="relative flex justify-between w-full h-auto p-4 mb-5 text-lg font-bold leading-tight text-white duration-200 transform bg-black rounded-lg md:text-xl lg:text-2xl md:flex-row md:justify-between md:h-32 hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1 md:my-0 md:mr-5"
            >
              <h2>{t('frequently_asked_questions')}</h2>
            </Link>

            <Link
              to="/terms"
              className="relative flex justify-between w-full h-auto p-4 text-lg font-bold leading-tight text-white duration-200 transform bg-black rounded-lg md:text-xl lg:text-2xl md:flex-row md:justify-between md:h-32 hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1 md:flex md:flex-wrap"
            >
              <h2>{t('terms_of_use')}</h2>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

HomeApplication.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

export default withRouter(HomeApplication);
