import React, { useState, useContext, useEffect } from 'react';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import { FavouritesContext } from '../../Context/FavouritesContext';

import GelIcon from '../GelIcon';
import { THEME_COLOURS } from '../../constants';
import BatchDownloadModal from '../BatchDownloadModal';
import ShareMixModal from './ShareMixModal';
import RecordMixModal from './RecordMixModal';
import RecordingNotSupportedModal from './RecordingNotSupportedModal';
import { useTranslation } from 'react-i18next';

function MixerOverlayHeader() {
  const { echoTrack } = useContext(AppContext);
  const {
    state: {
      mixerPlaying,
      mixerAssets,
      playingMixerAssets,
      readyMixerAssets,
      audioCtx,
    },
    setMixerPlaying,
    setMixerPlaybackReset,
    setMixerOpen,
  } = useContext(MixerContext);
  const {
    state: { favouriteIds },
    addAssetsToFavourites,
    removeAssetsFromFavourites,
  } = useContext(FavouritesContext);
  const [showDownloadPopover, setShowDownloadPopover] = useState(false);
  const [downloadType, setDownloadType] = useState(null);
  const [mixAssetsInFavourites, setMixAssetsInFavourites] = useState(false);
  const [showShareMixModal, setShowShareMixModal] = useState(false);
  const [showRecordMixModal, setShowRecordMixModal] = useState(false);
  const [playDisabled, setPlayDisabled] = useState(
    Object.keys(mixerAssets).length === 0 ||
      readyMixerAssets !== Object.keys(mixerAssets).length
  );

  const recordingSupported = !!window.MediaRecorder;
  const { t } = useTranslation();
  useEffect(() => {
    if (playDisabled) {
      setMixerPlaying(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playDisabled]);

  useEffect(() => {
    if (
      Object.keys(mixerAssets).some((mixerId) =>
        favouriteIds.includes(`${mixerAssets[mixerId].id}`)
      )
    ) {
      setMixAssetsInFavourites(true);
    } else {
      setMixAssetsInFavourites(false);
    }

    setPlayDisabled(
      Object.keys(mixerAssets).length === 0 ||
        readyMixerAssets < Object.keys(mixerAssets).length
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixerAssets, readyMixerAssets, favouriteIds]);

  useEffect(() => {
    if (mixerPlaying && playingMixerAssets === 0) {
      setMixerPlaying(false);
    }

    if (
      !mixerPlaying &&
      playingMixerAssets > 0 &&
      playingMixerAssets === Object.keys(mixerAssets).length
    ) {
      setMixerPlaying(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingMixerAssets]);

  const handlePlayAll = () => {
    echoTrack('mixer', { action: 'mixer_header_playall' }, 'click');

    // Mixer uses an audio context which may need to be initialised after a user action
    if (audioCtx.state === 'suspended') {
      audioCtx.resume();
    }

    setMixerPlaying(!mixerPlaying);
  };

  const handleReset = () => {
    echoTrack('mixer', { action: 'mixer_header_reset' }, 'click');
    setMixerPlaybackReset();
  };

  const handleFavouriting = () => {
    echoTrack('mixer', { action: 'mixer_header_favourite' }, 'click');

    !mixAssetsInFavourites
      ? addAssetsToFavourites(
          Object.keys(mixerAssets).map((mixerId) => mixerAssets[mixerId].id)
        )
      : removeAssetsFromFavourites(
          Object.keys(mixerAssets).map((mixerId) => mixerAssets[mixerId].id)
        );
  };

  const handleShare = () => {
    echoTrack('mixer', { action: 'mixer_header_share' }, 'click');
    setShowShareMixModal(true);
  };

  const handleDownload = (val) => {
    echoTrack(
      'mixer',
      { action: 'mixer_header_download', data: { showModal: val } },
      'click'
    );
    setShowDownloadPopover(val);
  };

  const handleClose = () => {
    echoTrack('mixer', { action: 'mixer_header_close' }, 'click');
    setMixerOpen(false);
  };

  const handleRecord = () => {
    setMixerPlaying(false);
    setMixerPlaybackReset();
    setShowRecordMixModal(true);
  };

  return (
    <>
      <div className="fixed bottom-0 z-40 w-full h-32 text-white transition-all duration-500 ease-in-out bg-gray-900 md:h-24 md:relative md:bottom-auto md:top-0">
        <div className="flex flex-col items-center justify-between w-full h-full max-w-screen-xl mx-auto md:flex-row md:px-3">
          <div className="flex flex-row-reverse items-center justify-between w-full h-full px-6 py-4 md:py-0 md:px-0 md:w-auto md:h-auto md:justify-center md:space-x-6 md:flex-row">
            <button
              type="button"
              className={`focus:outline-none flex flex-row-reverse items-center md:space-y-2 md:flex-col hover:opacity-75 focus:opacity-75 md:w-16 ${
                playDisabled ? 'cursor-not-allowed opacity-75' : ''
              }`}
              onMouseDown={handlePlayAll}
              disabled={playDisabled}
            >
              <GelIcon
                className="w-6 h-6"
                name={mixerPlaying ? 'pause' : 'play'}
                fill={playDisabled ? THEME_COLOURS.DARK_GREY : 'white'}
              />
              <span className="mr-1 font-semibold md:text-sm md:mr-0">
                {mixerPlaying ? t('pause_all') : t('play_all')}
              </span>
            </button>
            <button
              title="Reset playback"
              aria-label="Reset playback"
              type="button"
              className={`focus:outline-none flex flex-row-reverse items-center md:space-y-2 md:flex-col hover:opacity-75 focus:opacity-75 md:w-12 ${
                playDisabled ? 'cursor-not-allowed opacity-75' : ''
              }`}
              onClick={handleReset}
              disabled={playDisabled}
            >
              <GelIcon
                className="w-6 h-6"
                name="refresh"
                fill={playDisabled ? THEME_COLOURS.DARK_GREY : 'white'}
              />
              <span className="mr-1 font-semibold md:text-sm md:mr-0">
                Reset
              </span>
            </button>
            <div className="hidden font-semibold rounded-md lg:block">
              {`${Object.keys(mixerAssets).length} sound${
                Object.keys(mixerAssets).length > 1 ||
                Object.keys(mixerAssets).length === 0
                  ? 's'
                  : ''
              }`}
            </div>
          </div>
          <div className="flex flex-row items-center justify-between w-full h-full md:space-x-2 md:justify-center md:w-auto">
            <button
              type="button"
              className={`relative flex flex-row items-center justify-center w-full h-full px-2 py-3 space-x-2 border-white focus:outline-none md:h-auto md:py-1 md:w-auto md:rounded-md md:border hover:opacity-75 focus:opacity-7 ${
                playDisabled ? 'cursor-not-allowed opacity-75' : ''
              }`}
              onClick={handleRecord}
              disabled={playDisabled}
            >
              <GelIcon
                className="w-4 h-4 mx-1"
                name="music-track"
                fill="white"
              />
              <span className="mr-4 text-sm font-semibold">
                Record
                <span className="hidden md:inline"> mix</span>
              </span>
            </button>
            <button
              type="button"
              className={`flex flex-row items-center justify-center w-full h-full px-2 py-3 space-x-2 border-white focus:outline-none md:h-auto md:py-1 md:w-auto md:rounded-md md:border hover:opacity-75 focus:opacity-7 ${
                playDisabled ? 'cursor-not-allowed opacity-75' : ''
              }`}
              onClick={handleFavouriting}
              disabled={playDisabled}
            >
              <GelIcon className="w-4 h-4 mx-1" name="love" fill="white" />
              <span className="mr-4 text-sm font-semibold">
                {!mixAssetsInFavourites ? 'Add' : 'Remove'}
                <span className="hidden md:inline">
                  {' '}
                  all {!mixAssetsInFavourites ? 'to' : 'from'} favourites
                </span>
              </span>
            </button>
            <button
              type="button"
              className={`flex flex-row items-center justify-center w-full h-full px-2 py-3 space-x-2 border-white focus:outline-none md:h-auto md:py-1 md:w-auto md:rounded-md md:border hover:opacity-75 focus:opacity-75 ${
                playDisabled ? 'cursor-not-allowed opacity-75' : ''
              }`}
              onClick={handleShare}
              disabled={playDisabled}
            >
              <GelIcon className="w-4 h-4 mx-1" name="share" fill="white" />
              <span className="mr-4 text-sm font-semibold">
                Share
                <span className="hidden md:inline"> mix</span>
              </span>
            </button>
            <div className="relative">
              <button
                type="button"
                className={`focus:outline-none hidden md:flex flex-row items-center justify-center w-full h-full px-2 py-3 space-x-2 ${
                  showDownloadPopover
                    ? 'bg-white text-gray-900 md:rounded-t-md'
                    : 'bg-transparent border-white md:rounded-md'
                } md:h-auto md:py-1 md:w-auto md:border hover:opacity-75 focus:opacity-75 ${
                  playDisabled ? 'cursor-not-allowed opacity-75' : ''
                }`}
                onClick={() => handleDownload(!showDownloadPopover)}
                disabled={playDisabled}
              >
                <GelIcon
                  className="w-4 h-4 mx-1"
                  name="download"
                  fill={showDownloadPopover ? THEME_COLOURS.DARK_GREY : 'white'}
                />
                <span className="mr-4 text-sm font-semibold">
                  Download<span className="hidden md:inline"> all</span>
                </span>
              </button>
              {showDownloadPopover && (
                <>
                  <button
                    type="button"
                    className="fixed top-0 left-0 z-40 w-full h-full bg-transparent cursor-default focus:outline-none"
                    onClick={() => handleDownload(false)}
                  >
                    <span className="hidden">Close popup</span>
                  </button>
                  <div className="absolute left-0 z-50 w-full p-2 text-gray-900 bg-white rounded-b-lg shadow-lg">
                    <p className="text-sm font-bold text-left">Download as:</p>
                    <p className="flex justify-around text-lg align-middle">
                      <button
                        type="button"
                        className="focus:outline-none hover:underline focus:underline"
                        onClick={() => setDownloadType('mp3')}
                      >
                        mp3
                      </button>
                    </p>
                  </div>
                </>
              )}
            </div>
            <button
              type="button"
              className="flex flex-row items-center justify-center w-full h-full px-2 py-3 space-x-2 text-gray-900 bg-white border-white focus:outline-none md:hidden hover:opacity-50 focus:opacity-50"
              onClick={handleClose}
            >
              <GelIcon
                className="w-4 h-4"
                name="close"
                fill={THEME_COLOURS.PRIMARY}
              />
              <span className="mr-4 text-sm font-semibold">Hide</span>
            </button>
          </div>
          <button
            className="items-center justify-center hidden ml-2 md:flex-col md:space-y-2 md:w-12 focus:outline-none md:flex hover:opacity-50 focus:opacity-50"
            onClick={handleClose}
          >
            <GelIcon className="w-6 h-6 mx-1" name="close" fill="white" />
            <span className="text-sm font-semibold">Close</span>
          </button>
        </div>
      </div>
      {downloadType && (
        <BatchDownloadModal
          fileType={downloadType}
          setDownloadType={setDownloadType}
          assetType="mixer"
        />
      )}
      {showShareMixModal && (
        <ShareMixModal setShowShareModal={setShowShareMixModal} />
      )}
      {showRecordMixModal && recordingSupported && (
        <RecordMixModal setShowRecordMixModal={setShowRecordMixModal} />
      )}
      {showRecordMixModal && !recordingSupported && (
        <RecordingNotSupportedModal
          setShowRecordMixModal={setShowRecordMixModal}
        />
      )}
    </>
  );
}

export default MixerOverlayHeader;
