import axios from 'axios';
import { API_URL } from '../constants';

// 회원 전용 api
const authUrl = `/api/user/`;
const METHOD_TYPE = { GET: 0, POST: 1 };
export const AxiosClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

AxiosClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.response.data.message === 'jwt expired.'
    ) {
      const res = await CheckLogin();
      if (res.status === 200) {
        return AxiosClient(originalRequest);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

let tokenInterceptor;

const setClientHeader = (token) => {
  if (token !== undefined && token !== null) {
    tokenInterceptor = AxiosClient.interceptors.request.use(
      (config) => {
        if (config && token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
};

export const clearClientHeader = () => {
  if (tokenInterceptor !== null) {
    AxiosClient.interceptors.request.eject(tokenInterceptor);
    tokenInterceptor = null;
  }
};

const Capsuled_Packet = async (method_type, url, props) => {
  try {
    const res =
      method_type === METHOD_TYPE.GET
        ? await AxiosClient.get(url, props)
        : await AxiosClient.post(url, props, { withCredentials: true });

    return res;
  } catch (e) {
    console.log(e);
    return e?.response;
  }
};

export async function CheckLogin() {
  const res = await Capsuled_Packet(
    METHOD_TYPE.POST,
    authUrl + 'get-access-token',
    {}
  );
  if (res.status === 200) {
    clearClientHeader();
    setClientHeader(res.data?.access_token);
  }
  return res;
}

export async function Signup({ account, password, email, mobile }) {
  const res = await Capsuled_Packet(METHOD_TYPE.POST, authUrl + 'signup', {
    account: account,
    password: password,
    email: email,
    mobile: mobile,
  });
  return res;
}

export async function Signin({ account, password }) {
  const res = await Capsuled_Packet(METHOD_TYPE.POST, authUrl + 'signin', {
    account: account,
    password: password,
  });
  if (res.status === 200) {
    clearClientHeader();
    setClientHeader(res.data?.access_token);
  }
  return res;
}

export async function Signout() {
  const res = await Capsuled_Packet(METHOD_TYPE.POST, authUrl + 'signout', {});
  return res;
}

export async function GetUserInfo() {
  const res = await Capsuled_Packet(METHOD_TYPE.GET, authUrl + 'userinfo', {});
  return res;
}

export async function ChangeUserInfo({ password, new_password, email }) {
  const res = await Capsuled_Packet(METHOD_TYPE.POST, authUrl + 'changeinfo', {
    password,
    new_password,
    email,
  });
  return res;
}

export async function RequestVerifyEmail({ email }) {
  const res = await Capsuled_Packet(
    METHOD_TYPE.POST,
    authUrl + 'req-mail-cert',
    {
      email,
    }
  );
  return res;
}

export async function VerifyEmail({ code }) {
  const res = await Capsuled_Packet(
    METHOD_TYPE.POST,
    authUrl + 'done-mail-cert',
    {
      code,
    }
  );
  return res;
}

export async function ChangePassword({ password, newpassword }) {
  const res = await Capsuled_Packet(
    METHOD_TYPE.POST,
    authUrl + 'changepassword',
    {
      password: password,
      newpassword: newpassword,
    }
  );
  if (res.status === 200) {
    clearClientHeader();
    setClientHeader(res.data?.access_token);
  }
  return res;
}

export async function AcceptSubscribe({ period }) {
  const res = await Capsuled_Packet(
    METHOD_TYPE.POST,
    authUrl + 'accept-subscribe',
    {
      period,
    }
  );
  return res;
}

export async function CancelSubscribe() {
  const res = await Capsuled_Packet(
    METHOD_TYPE.POST,
    authUrl + 'cancel-subscribe',
    {}
  );
  return res;
}

export async function RegistUse({ sfxId }) {
  const res = await Capsuled_Packet(METHOD_TYPE.POST, authUrl + 'regist-use', {
    sfxId,
  });
  return res;
}
