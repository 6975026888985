import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Link, withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookie from 'js-cookie';
import i18next from '../../locale/i18n';
import { useTranslation } from 'react-i18next';
import getQuerystring from '../../utilities/getQuerystring';
import { UserContext } from '../../Context/UserContext';
import { THEME_COLOURS, USER_LOGON } from '../../constants';
import { Signout, clearClientHeader } from '../../Route/Route';
import GelIcon from '../GelIcon';
import Container from '../Container';

const MenuItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  > a {
    width: 100%;
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
    padding: 0.75rem 1rem;
  }
`;

function MenuItem(props) {
  const { href, text } = props;
  if (href.includes('#')) {
    return (
      <MenuItemWrapper>
        <a href={href}>{text}</a>
      </MenuItemWrapper>
    );
  }
  return (
    <MenuItemWrapper>
      <Link to={href}>{text}</Link>
    </MenuItemWrapper>
  );
}

function MenuButton(props) {
  return (
    <div className="mt-1">
      <button
        type="button"
        onClick={props.onClick}
        className="navbar-item px-6 font-bold text-center text-white"
        style={{ fontSize: '1.5rem' }}
      >
        {props.text}
      </button>
    </div>
  );
}

const NavbarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 4;
`;

const Nav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: left;
  font-family: inter;
  font-weight: 600;
`;

const LogoLink = styled(Link)`
  color: white;
  font-size: 4rem;
`;

const NavMenuWrapper = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 1300px) {
    flex-direction: column;
  }
`;

const LanguageButtonWrapper = styled.div`
  position: relative;
  width: 10.5rem;
  font-weight: 600;
  margin: 0 1rem;
`;

const Navbar = ({ history }) => {
  const { t } = useTranslation();
  const { clearUserInfo } = useContext(UserContext);
  const [showLang, toggleShowLang] = useState(false);
  const navigate = useHistory();
  const onLogout = async () => {
    console.log('click logout');
    const res = await Signout();
    clearUserInfo();
    if (res.status !== 200) {
      console.log('failed');
      Cookie.remove(USER_LOGON);
      navigate.push('/');
      //에러
    } else {
      console.log('success');
      clearClientHeader();
      navigate.push('/');
    }
  };

  useEffect(() => {
    {
      document.documentElement.style.position = '';
      document.documentElement.style.width = '';
    }
  }, []);

  const changeLanguage = (loc) => {
    i18next.changeLanguage(loc);
    localStorage.setItem('loc', loc);
    toggleShowLang(false);
    if (history.location.pathname === '/search') {
      const parsedQuerystring = getQuerystring();
      const searchText = parsedQuerystring.q;
      history.push(
        searchText && searchText.length > 0
          ? `/search?q=${searchText}`
          : '/search'
      );
    }
  };

  let menuItem = <MenuItem href="/login" text="LOGIN" />;
  let menuItem2 = <MenuButton onClick={onLogout} text="LOGOUT" />;
  if (Cookie.get(USER_LOGON)) {
    menuItem = <MenuItem href="/user-info" text="INFO" />;
  } else {
    menuItem = <MenuItem href="/login" text="LOGIN" />;
    menuItem2 = null;
  }

  return (
    <NavbarWrapper>
      {showLang && (
        <button
          className="fixed top-0 left-0 w-full h-screen bg-transparent focus:outline-none"
          onClick={() => {
            toggleShowLang(false);
          }}
        />
      )}
      <Container>
        <Nav>
          <LogoLink to="/">SOUNDWIX</LogoLink>
          <NavMenuWrapper>
            <MenuItem href="/" text="SOUNDEFFECTS" />
            <MenuItem href="/search" text="EDITMIXER" />
            <MenuItem href="/#application" text="APPLICATION" />
            <MenuItem href="/subscribe" text="SUBSCRIBE" />
            {menuItem}
            {menuItem2}
            <LanguageButtonWrapper>
              <button
                type="button"
                className="flex flex-row items-center justify-between w-full px-3 py-2 bg-white border-2 border-gray-400"
                onClick={() => toggleShowLang(!showLang)}
              >
                <span className="text-base text-gray-900">{t('language')}</span>
                <GelIcon
                  className="w-4 h-4"
                  name={showLang ? 'chevron-up' : 'chevron-down'}
                  fill={THEME_COLOURS.SECONDARY}
                />
              </button>
              {showLang && (
                <div className="absolute z-60 block lg:w-full">
                  <div className="bg-white border-b-2 border-l-2 border-r-2 border-gray-400">
                    <ul className="py-2 overflow-y-auto scrolling-touch h-52">
                      <li key="ko">
                        <button
                          type="button"
                          role="checkbox"
                          onClick={() => {
                            changeLanguage('ko');
                          }}
                          className="flex flex-row items-center justify-between px-4 py-2 focus:outline-none text-md hover:underline focus:underline"
                        >
                          <span className="text-gray-900 truncate">한국어</span>
                        </button>
                      </li>

                      <li key="en">
                        <button
                          type="button"
                          role="checkbox"
                          onClick={() => {
                            changeLanguage('en');
                          }}
                          className="flex flex-row items-center justify-between w-full px-4 py-2 focus:outline-none text-md hover:underline focus:underline"
                        >
                          <span className="text-gray-900 truncate">
                            English
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </LanguageButtonWrapper>
          </NavMenuWrapper>
        </Nav>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default withRouter(Navbar);
