import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

import { API_URL } from '../../constants';
import useCustomSnackbars from '../../hooks/useCustomSnackbars';
import { AppContext } from '../../Context/AppContext';

const Feedback = () => {
  const { echoTrack } = useContext(AppContext);

  const [feedback, setFeedback] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const { openErrorSnackbar } = useCustomSnackbars();

  useEffect(() => {
    echoTrack('feedback');

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!feedback || !feedback.name || !feedback.email || !feedback.message) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [feedback]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFeedback((prevVal) => ({ ...prevVal, [name]: value }));
  };

  const handleSubmit = (e) => {
    echoTrack('feedback', { action: 'feedback_submitted' }, 'click');

    e.preventDefault();
    setSubmitDisabled(true);

    fetch(`${API_URL}/api/sfx/feedback`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feedback }),
    })
      .then((res) => res.json())
      .then(() => setSubmitted(true))
      .catch((err) => {
        setSubmitDisabled(false);
        openErrorSnackbar(`Error submitting: ${err.message}`, 2000);
      });
  };

  return (
    <>
      <Helmet title="Submit Feedback | DIGWIX Sound Effects" />
      <div className="max-w-screen-xl min-h-screen px-3 mx-auto">
        <h2 className="py-4 text-2xl font-bold">Submit Feedback</h2>
        {!submitted ? (
          <>
            <p className="pb-4 text-xl">
              Please use the feedback form below to send us your comments on the
              site. All fields are required.
            </p>
            <form
              onSubmit={handleSubmit}
              className="w-full mx-auto bg-white rounded md:w-2/3"
            >
              <div className="mb-4">
                <label htmlFor="feedback_name">Name:</label>
                <input
                  id="feedback_name"
                  name="name"
                  className="w-full p-3 border-2 focus:outline-none focus:border-teal-700"
                  type="text"
                  placeholder="Please enter your name"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="feedback_email">Email:</label>
                <input
                  id="feedback_email"
                  name="email"
                  className="w-full p-3 border-2 focus:outline-none focus:border-teal-700"
                  type="email"
                  placeholder="someone@email.com"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="feedback_message">Message:</label>
                <textarea
                  id="feedback_message"
                  name="message"
                  className="w-full p-3 border-2 focus:outline-none focus:border-teal-700"
                  rows="10"
                  placeholder="Enter your message"
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className={`px-4 py-2 text-lg font-bold text-white bg-blue-800 focus:outline-none ${
                  submitDisabled
                    ? 'opacity-50 cursor-not-allowed'
                    : 'focus:opacity-75 hover:opacity-75'
                }`}
                disabled={submitDisabled}
              >
                Submit
              </button>
            </form>
          </>
        ) : (
          <>
            <p className="pb-4 text-xl">
              Thank you. Your feedback has been submitted.
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default Feedback;
