import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Unpublished, CheckCircle } from '@mui/icons-material';
import {
  ChangeUserInfo,
  RequestVerifyEmail,
  VerifyEmail,
} from '../../Route/Route';
import Button from '../../Components/Button';
import { AppContext } from '../../Context/AppContext';
import { UserContext } from '../../Context/UserContext';

const Title = styled.div`
  font-size: 4rem;
  font-weight: 600;
  font-family: Inter;
  color: white;
  margin-bottom: 5rem;
`;

const Label = styled.label`
  color: white;
  font-size: 2rem;
  margin-right: 15px;
  font-family: Inter;
`;

const InfoWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  max-width: 1500px;
  padding: 10rem 0;
  align-items: center;
  input {
    color: black;
  }
`;

const UserInfo = () => {
  const { t } = useTranslation();
  const [inputId, setInputId] = useState('');
  const [inputPw, setInputPw] = useState('');
  const [inputNPw, setInputNPw] = useState('');
  const [inputCode, setInputCode] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [verify, setVerify] = useState(false);
  const [ErrMss, setErrMss] = useState('');
  const { startRequest, endRequest, openMss } = useContext(AppContext);
  const {
    state: { user },
  } = useContext(UserContext);

  useEffect(() => {
    setInputId(user.account ? user.account : '');
    setInputPw('');
    setInputNPw('');
    setInputEmail(user.email ? user.email : '');
    setEmailVerified(user.done_mail_cert ? user.done_mail_cert : false);
  }, [user]);

  const ErrorMessage = (data) => {
    setErrMss(data);
  };

  const handleInputPw = (e) => {
    setInputPw(e.target.value);
  };

  const handleInputNPw = (e) => {
    setInputNPw(e.target.value);
  };

  const handleInputEmail = (e) => {
    setInputEmail(e.target.value);
  };

  const handleInputCode = (e) => {
    setInputCode(e.target.value);
  };

  const onChangeInfo = async () => {
    if (inputPw.length === 0) {
      ErrorMessage(t('signin.fill_pw'));
      return;
    }

    if (inputEmail.length === 0) {
      ErrorMessage(t('signin.fill_email'));
      return;
    }

    const res = await ChangeUserInfo({
      password: inputPw,
      new_password: inputNPw,
      email: inputEmail,
    });
    if (res.status !== 200) {
      ErrorMessage(t('signin.fail_change'));
      //에러
    } else {
      openMss(t('info.success_change'), () => {
        ErrorMessage('');
        window.location.reload();
      });
    }
  };

  const requestVerifyEmail = async () => {
    startRequest();
    const res = await RequestVerifyEmail({ inputEmail });
    endRequest();
    if (res.status !== 200) {
      ErrorMessage(t('info.fail_request_verify'));
      //에러
    } else {
      ErrorMessage('');
      setVerify(true);
    }
  };

  const verifyEmail = async () => {
    startRequest();
    const res = await VerifyEmail({ code: inputCode });
    endRequest();
    if (res.status !== 200) {
      openMss(t('info.fail_verify_email'));
      //에러
    } else {
      openMss(t('info.success_verify_email'), () => {
        window.location.reload();
      });
      ErrorMessage('');
      setInputCode('');
    }
  };

  return (
    <>
      <Helmet title="SOUNDWIX | USER INFO" />
      <InfoWrapper>
        {!verify ? (
          <InfoContainer>
            <Title>INFORMATION</Title>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  width: '10rem',
                  alignItems: 'center',
                }}
              >
                <Label>ID</Label>
              </div>
              <div>
                <input
                  type="text"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  name="input_id"
                  autoComplete="off"
                  value={inputId}
                  // onChange={handleInputId}
                  disabled
                />
              </div>
            </div>

            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  width: '10rem',
                  alignItems: 'center',
                }}
              >
                <Label>PW</Label>
              </div>
              <div>
                <input
                  type="password"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  name="input_pw"
                  autoComplete="off"
                  value={inputPw}
                  onChange={handleInputPw}
                />
              </div>
            </div>

            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  width: '10rem',
                  alignItems: 'center',
                }}
              >
                <Label>NEW PW</Label>
              </div>
              <div>
                <input
                  type="password"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  name="input_npw"
                  autoComplete="off"
                  value={inputNPw}
                  onChange={handleInputNPw}
                />
              </div>
            </div>

            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  width: '10rem',
                  alignItems: 'center',
                }}
              >
                <Label>
                  EMAIL
                  {emailVerified ? (
                    <CheckCircle
                      style={{ color: '#0f9d58', fontSize: '2.25rem' }}
                    />
                  ) : (
                    <Unpublished
                      style={{ color: 'red', fontSize: '2.25rem' }}
                    />
                  )}
                </Label>
              </div>
              <div>
                <input
                  type="text"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  name="input_email"
                  autoComplete="off"
                  value={inputEmail}
                  onChange={handleInputEmail}
                />
              </div>
            </div>
            {
              <div
                style={{
                  marginTop: '3rem',
                  color: 'red',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontSize: '1.5rem',
                }}
              >
                {ErrMss.length > 0 && ErrMss}
              </div>
            }
            <div
              className="flex justify-end items-end text-base mt-4"
              style={{
                color: 'White',
                marginBottom: '3rem',
                marginTop: '2rem',
              }}
            >
              {emailVerified ? (
                <Button onClick={onChangeInfo}>CHANGE</Button>
              ) : (
                <Button style={{ width: '18rem' }} onClick={requestVerifyEmail}>
                  VERIFY EMAIL
                </Button>
              )}
            </div>
          </InfoContainer>
        ) : (
          <InfoContainer>
            <Title style={{ marginBottom: '3rem' }}>VERIFY EMAIL</Title>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  width: '8rem',
                  alignItems: 'center',
                }}
              >
                <Label>CODE</Label>
              </div>
              <div>
                <input
                  type="text"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  name="input_id"
                  autoComplete="off"
                  value={inputCode}
                  onChange={handleInputCode}
                />
              </div>
            </div>
            <div
              className="flex justify-end items-end text-base mt-4"
              style={{
                color: 'White',
                marginBottom: '3rem',
                marginTop: '2rem',
              }}
            >
              <Button onClick={verifyEmail}>VERIFY</Button>
            </div>
          </InfoContainer>
        )}
      </InfoWrapper>
    </>
  );
};

export default UserInfo;
