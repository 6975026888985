import React, { useState, useEffect, useRef, useContext } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
import PropTypes from 'prop-types';

import GelIcon from '../GelIcon';
import {
  THEME_COLOURS,
  WAVEFORM_URL,
  MEDIA_LOW_QUALITY_URL,
} from '../../constants';
import formatAssetDuration from '../../utilities/formatAssetDuration';
import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import checkIsIOS from '../../utilities/checkIsIOS';

const MixerAudioPlayer = ({
  asset,
  isLooping,
  setIsLooping,
  isMuted,
  currentVolume,
}) => {
  const { echoTrack } = useContext(AppContext);
  const {
    state: {
      mixerPlaying,
      mixerOpen,
      mixerPlaybackReset,
      audioCtx,
      streamDestination,
    },
    addPlayingMixerAsset,
    removePlayingMixerAsset,
    addReadyMixerAsset,
  } = useContext(MixerContext);

  const [regionSeekTime, setRegionSeekTime] = useState(null);
  const [wavesurferPlayer, setWavesurferPlayer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [wavesurferRegion, setWaveSurferRegion] = useState(null);
  const [delayTimeout, setDelayTimeout] = useState(null);
  const [delayCountdown, setDelayCountdown] = useState(null);

  const waveContainerRef = useRef();
  const mediaElementSource = useRef();

  const { isIOS } = checkIsIOS();

  const getRegionSeekTime = (seekTime) => {
    const seekTimePercentage = seekTime / wavesurferPlayer.getDuration();

    // Return a smaller float precision for iOS
    if (isIOS) return parseFloat(seekTimePercentage.toFixed(2));

    return seekTimePercentage;
  };

  useEffect(() => {
    if (!wavesurferPlayer) {
      const wavesurferInstance = WaveSurfer.create({
        container: waveContainerRef.current,
        waveColor: THEME_COLOURS.DARK_GREY,
        progressColor: '#fff',
        cursorColor: '#fff',
        barWidth: 2,
        barMinHeight: 1,
        responsive: true,
        hideScrollbar: true,
        height: 60,
        loopSelection: true,
        backend: 'MediaElement',
        normalize: true,
        audioContext: audioCtx,
        plugins: [RegionsPlugin.create()],
      });

      wavesurferInstance.on('ready', () => {
        setIsLoading(false);
        addReadyMixerAsset();
      });

      wavesurferInstance.on('audioprocess', (secondsElapsed) => {
        setRegionSeekTime(secondsElapsed);
      });

      wavesurferInstance.on('region-created', (region) => {
        if (!isNaN(region.end)) {
          wavesurferInstance.regions.clear();
          setWaveSurferRegion({
            id: region.id,
            start: region.start,
            end: region.end,
          });
          setRegionSeekTime(region.start);
        }
      });

      // Mix import causes an error if this if statement is not in place
      if (asset.file) {
        fetch(`${WAVEFORM_URL}/${asset.file.small.name}.json`)
          .then((res) => {
            return res.json();
          })
          .then((peaks) => {
            const audioElement = new Audio(
              `${MEDIA_LOW_QUALITY_URL}/${asset.file.small.name}.mp3`
            );
            audioElement.preload = 'metadata';
            audioElement.crossOrigin = 'anonymous';

            wavesurferInstance.load(audioElement, peaks.data);

            mediaElementSource.current = new MediaElementAudioSourceNode(
              wavesurferInstance.backend.ac,
              { mediaElement: audioElement }
            );
            mediaElementSource.current.connect(streamDestination);
            mediaElementSource.current.connect(
              wavesurferInstance.backend.ac.destination
            );
          })
          .then(() => {
            // Check any existing 'mixerSettings' to pre-render the selected region
            const { mixerSettings } = asset;

            if (mixerSettings) {
              wavesurferInstance.addRegion({
                ...mixerSettings,
                loop: isLooping,
                drag: false,
                resize: false,
              });
            } else {
              // Clear regions on asset change
              wavesurferInstance.regions.clear();
            }
          })
          .catch((e) => {
            console.error('error', e);
          });
      }

      setWavesurferPlayer(wavesurferInstance);
    }

    return () => {
      clearTimeout(delayTimeout);
      mediaElementSource.current && mediaElementSource.current.disconnect();
      if (wavesurferPlayer) {
        wavesurferPlayer.un('ready');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update event handlers if looping prop changes
  useEffect(() => {
    if (wavesurferPlayer) {
      wavesurferPlayer.un('finish');

      if (isLooping) {
        wavesurferPlayer.on('finish', () => {
          wavesurferPlayer.play();
        });
      } else {
        wavesurferPlayer.on('finish', () => {
          wavesurferPlayer.pause();
          wavesurferPlayer.seekTo(0);
          setIsPlaying(false);
        });
      }
    }
  }, [wavesurferPlayer, isLooping]);

  // Decide whether to play the entire audio file, or just the selected region
  const playFullOrRegion = () => {
    if (wavesurferRegion) {
      const playerPosition =
        Math.floor(wavesurferPlayer.getCurrentTime() * 1000000) || 0;
      const regionStart = Math.floor(wavesurferRegion.start * 1000000) || 0;

      // Apply delay only when player is at start of any region
      if (
        asset.mixerSettings.delay &&
        (wavesurferPlayer.getCurrentTime() === 0 ||
          playerPosition === regionStart)
      ) {
        setDelayTimeout(
          setTimeout(() => {
            wavesurferPlayer.seekTo(getRegionSeekTime(regionSeekTime));
            wavesurferPlayer.play();
          }, asset.mixerSettings.delay * 1000)
        );

        // Set countdown value
        setDelayCountdown(asset.mixerSettings.delay * 1000);
      } else {
        wavesurferPlayer.seekTo(getRegionSeekTime(regionSeekTime));
        wavesurferPlayer.play();
      }
    } else {
      // Apply delay only if player is at the start of the clip
      if (
        asset.mixerSettings &&
        asset.mixerSettings.delay &&
        wavesurferPlayer.getCurrentTime() === 0
      ) {
        setDelayTimeout(
          setTimeout(() => {
            wavesurferPlayer.play();
          }, asset.mixerSettings.delay * 1000)
        );

        // Set countdown value
        setDelayCountdown(asset.mixerSettings.delay * 1000);
      } else {
        wavesurferPlayer.play();
      }
    }
  };

  // Create timer for delay countdown
  useEffect(() => {
    // Don't countdown if the sound isn't playing or no delay is set
    if (!isPlaying || !delayCountdown) return;

    const intervalId = setInterval(() => {
      setDelayCountdown(delayCountdown - 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isPlaying, delayCountdown]);

  // Set the delay countdown value when user changes delay input text
  useEffect(() => {
    if (asset.mixerSettings.delay) {
      setDelayCountdown(asset.mixerSettings.delay * 1000);
    }
  }, [asset.mixerSettings.delay]);

  // Listen to changes in mute and volume control
  useEffect(() => {
    if (wavesurferPlayer) {
      if (isMuted) {
        wavesurferPlayer.setVolume(0);
      } else {
        wavesurferPlayer.setVolume(currentVolume);
      }
    }
  }, [wavesurferPlayer, isMuted, currentVolume]);

  // Listen to changes in master playback
  useEffect(() => {
    if (wavesurferPlayer) {
      setIsPlaying(mixerPlaying);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixerPlaying, wavesurferPlayer]);

  // Listen to changes on playback reset
  useEffect(() => {
    if (wavesurferPlayer) {
      if (mixerPlaybackReset) {
        setIsPlaying(false);

        // Reset countdown value
        setDelayCountdown(asset.mixerSettings.delay * 1000);

        if (wavesurferRegion) {
          wavesurferPlayer.pause();
          wavesurferPlayer.seekTo(getRegionSeekTime(wavesurferRegion.start));
          setRegionSeekTime(wavesurferRegion.start);
        } else {
          wavesurferPlayer.pause();
          wavesurferPlayer.seekTo(0);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixerPlaybackReset, wavesurferPlayer, wavesurferRegion]);

  // Listen for changes in mixer mode
  // If it changes, the waveform needs to be redrawn to fit container properly
  useEffect(() => {
    if (wavesurferPlayer) {
      wavesurferPlayer.setHeight(60);
    }
  }, [mixerOpen, wavesurferPlayer]);

  // Check if the asset model changes and update the Wavesurfer region
  useEffect(() => {
    if (wavesurferPlayer) {
      // Check any existing 'mixerSettings' to pre-render the selected region
      const { mixerSettings } = asset;

      // Unsubscribe from the 'region-out' event on re-renders
      wavesurferPlayer.un('region-out');

      if (mixerSettings && mixerSettings.start && mixerSettings.end) {
        wavesurferPlayer.regions.clear();
        wavesurferPlayer.addRegion({
          ...mixerSettings,
          loop: isLooping,
          drag: false,
          resize: false,
        });

        // Subscribe to the 'region-out' if the audio asset is using 'mixerSettings'
        wavesurferPlayer.on('region-out', (region) => {
          // Only reset the playback if the asset IS NOT looping
          if (!isLooping) {
            wavesurferPlayer.pause();
            wavesurferPlayer.seekTo(
              region.start / wavesurferPlayer.getDuration()
            );
            setIsPlaying(false);
          }
        });
      } else {
        // Clear regions on asset change
        wavesurferPlayer.regions.clear();
        setWaveSurferRegion(null);
      }
    }
  }, [wavesurferPlayer, isLooping, asset]);

  // Add/remove assets from playback tracker and play/pause as requred
  useEffect(() => {
    clearTimeout(delayTimeout);
    if (wavesurferPlayer) {
      if (!isPlaying) {
        removePlayingMixerAsset();
        wavesurferPlayer.pause();
      } else {
        addPlayingMixerAsset();
        playFullOrRegion();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  const handlePlayClick = () => {
    echoTrack(
      'mixer',
      { action: 'asset_playback', data: { isPlaying } },
      'click'
    );

    // Mixer uses an audio context which may need to be initialised after a user action
    if (audioCtx.state === 'suspended') {
      audioCtx.resume();
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <div className="flex flex-row-reverse items-center justify-between w-full md:flex-row md:space-x-4">
      <button
        type="button"
        title={!isPlaying ? 'Play sound' : 'Pause sound'}
        className={`p-2 ml-4 rounded-md focus:outline-none md:ml-0 hover:bg-gray-800 focus:bg-gray-800 ${
          isLoading ? 'cursor-not-allowed' : ''
        }`}
        onClick={handlePlayClick}
        aria-label={isPlaying ? 'Pause' : 'Play'}
        disabled={isLoading}
      >
        <GelIcon
          name={!isPlaying ? 'play' : 'pause'}
          className="w-5 h-5"
          fill={isLoading ? 'grey' : 'white'}
        />
      </button>
      <div className="relative z-0 w-full h-full">
        {isLoading && (
          <div className="absolute w-full h-full">
            <GelIcon
              name="loading"
              fill={THEME_COLOURS.DARK_GREY}
              className="w-8 h-8 mx-auto my-3 opacity-25 animate-spin"
            />
          </div>
        )}
        <div ref={waveContainerRef} />
      </div>
      <div className="flex flex-col items-center">
        <p className="text-sm" title="Duration">
          {formatAssetDuration(asset.duration)}
        </p>
        <p
          className={`bg-gray-800 px-2 mt-1 rounded-md font-bold text-xs text-center${
            delayCountdown && delayCountdown !== 0 && isPlaying
              ? ' text-pulsate'
              : ''
          }`}
          title="Playback Delay"
        >
          {delayCountdown ? `${formatAssetDuration(delayCountdown)}` : '0:00'}
        </p>
      </div>
      <button
        type="button"
        className="hidden p-2 rounded-md focus:outline-none hover:bg-gray-800 focus:bg-gray-800 md:block"
        onClick={() => setIsLooping(!isLooping)}
        aria-label={isLooping ? 'Disable looping' : 'Enable looping'}
        title={isLooping ? 'Disable looping' : 'Enable looping'}
      >
        <GelIcon
          name={isLooping ? 'movement-on' : 'movement-off'}
          fill={isLooping ? 'white' : THEME_COLOURS.DARK_GREY}
          className="w-6 h-6"
        />
      </button>
    </div>
  );
};

MixerAudioPlayer.propTypes = {
  asset: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    duration: PropTypes.number,
    file: PropTypes.shape({
      small: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    mixerSettings: PropTypes.shape(),
  }).isRequired,
  isLooping: PropTypes.bool.isRequired,
  setIsLooping: PropTypes.func.isRequired,
  isMuted: PropTypes.bool.isRequired,
  currentVolume: PropTypes.number.isRequired,
};

export default MixerAudioPlayer;
