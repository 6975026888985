import { useSnackbar } from 'react-simple-snackbar';

import { DEFAULT_SNACKBAR_OPTIONS, ERROR_SNACKBAR_OPTIONS } from '../constants';

function useCustomSnackbars() {
  const [openDefaultSnackbar] = useSnackbar(DEFAULT_SNACKBAR_OPTIONS);
  const [openErrorSnackbar] = useSnackbar(ERROR_SNACKBAR_OPTIONS);

  return { openDefaultSnackbar, openErrorSnackbar };
}

export default useCustomSnackbars;
