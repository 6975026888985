import { useContext, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import styled from 'styled-components';
import Button from '../../Components/Button';
import { UserContext } from '../../Context/UserContext';
import { AppContext } from '../../Context/AppContext';
import { AcceptSubscribe } from '../../Route/Route';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Label = styled.label`
  color: white;
  font-size: 2rem;
  margin-right: 15px;
  font-family: Inter;
`;

const SubscribeWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Inter';
  font-weight: 700;
`;

const SubscribeContainer = styled.div`
  padding: 10rem 0;
  display: flex;
  width: 90%;
  max-width: 1500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubscribeTitle = styled.div`
  position: relative;
  font-size: 5rem;
  margin-bottom: 4rem;
`;

const SubscribeFee = styled.div`
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 3rem;
  > span {
    font-weight: 500;
    font-size: 2rem;
    color: #d9d9d9;
  }
`;

const SubscribeDesc = styled.div`
  font-size: 1.5rem;
  margin-bottom: 3rem;
  color: white;
  input {
    width: 10rem;
    color: black;
    text-align: right;
    &:disabled {
      background-color: lightgray;
      cursor: not-allowed;
    }
  }
`;

const SubscribeDescSmall = styled.div`
  font-size: 1rem;
  margin-bottom: 3rem;
  color: white;
  max-width: 42.5rem;
`;

const Subscribe = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [month, setMonth] = useState(1);
  const [step, setStep] = useState(1);
  const { openMss } = useContext(AppContext);
  const {
    state: { user },
  } = useContext(UserContext);

  const setNextStep = () => {
    setStep(2);
  };

  const handleChange = (event) => {
    setMonth(event.target.value);
  };

  const subscribeAction = useCallback(async () => {
    if (user.account === '') {
      openMss(t('subscribe.please_login'));
      return;
    } else if (user.subscribe === true) {
      openMss(t('subscribe.already_subscribed'));
      return;
    }
    const res = await AcceptSubscribe({
      period: month,
    });
    if (res.status !== 200) {
      openMss(t('subscribe.fail_subscribe'));
      return;
    }
    setStep(3);
  }, [month, user]);

  return (
    <>
      <Helmet title="SOUNDWIX | SUBSCRIBE" />
      <SubscribeWrapper>
        <SubscribeContainer>
          {user.subscribe === false ? (
            <>
              <SubscribeTitle>SUBSCRIBE NOW</SubscribeTitle>
              <SubscribeFee>
                9,900₩<span>/month(VAT included)</span>
              </SubscribeFee>
              {step === 1 && (
                <>
                  <SubscribeDesc>
                    Unlimited usage of Sound Mixer.
                    <br />
                    Free usage of our sounds.
                    <br />
                    Covers everything from personal to commercial use.
                  </SubscribeDesc>
                  <SubscribeDescSmall>
                    * {t('subscribe.desc1')}
                    <br />
                    <br />* {t('subscribe.desc2')}
                    <br />
                    <br />* {t('subscribe.desc3')}
                  </SubscribeDescSmall>
                  <Button onClick={setNextStep}>SUBSCRIBE</Button>
                </>
              )}
              {step === 2 && (
                <>
                  <SubscribeDesc>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          display: 'flex',
                          width: '8rem',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Label>MONTH</Label>
                      </div>
                      <div>
                        <input
                          type="number"
                          style={{
                            width: '12rem',
                            fontSize: '2rem',
                            padding: '5px',
                            marginLeft: '1rem',
                          }}
                          name="month"
                          autoComplete="off"
                          value={month}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '1rem' }}>
                      <div
                        style={{
                          display: 'flex',
                          width: '8rem',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Label>PRICE</Label>
                      </div>
                      <div>
                        <input
                          type="text"
                          style={{
                            width: '12rem',
                            fontSize: '2rem',
                            padding: '5px',
                            marginLeft: '1rem',
                          }}
                          name="price"
                          autoComplete="off"
                          value={`${month * 9900} ₩`}
                          disabled
                        />
                      </div>
                    </div>
                  </SubscribeDesc>
                  <Button onClick={subscribeAction}>CONFIRM</Button>
                </>
              )}
              {step === 3 && (
                <>
                  <SubscribeDesc style={{ textAlign: 'center' }}>
                    Subcribe Completed!
                    <br />
                    Thank you for subscribing.
                    <br />
                  </SubscribeDesc>
                </>
              )}
            </>
          ) : (
            <>
              <SubscribeTitle>SUBSCRIBE</SubscribeTitle>
              <SubscribeDesc>
                YOU'RE ALREADY IN MEMBERSHIP
                <br />
                <br />
                <br />
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '8rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Label>FROM</Label>
                  </div>
                  <div>
                    <input
                      type="text"
                      style={{
                        width: '14rem',
                        fontSize: '2rem',
                        padding: '5px',
                        marginLeft: '1rem',
                        textAlign: 'center',
                      }}
                      name="subscribe_start"
                      autoComplete="off"
                      value={moment(user.subscribe_start).format('YYYY-MM-DD')}
                      disabled
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: '1rem' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '8rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Label>TO</Label>
                  </div>
                  <div>
                    <input
                      type="text"
                      style={{
                        width: '14rem',
                        fontSize: '2rem',
                        padding: '5px',
                        marginLeft: '1rem',
                        textAlign: 'center',
                      }}
                      name="subscribe_end"
                      autoComplete="off"
                      value={moment(user.subscribe_end).format('YYYY-MM-DD')}
                      disabled
                    />
                  </div>
                </div>
              </SubscribeDesc>
              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                BACK
              </Button>
            </>
          )}
        </SubscribeContainer>
      </SubscribeWrapper>
    </>
  );
};

export default Subscribe;
