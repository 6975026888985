import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';

const MixerToggle = ({ history }) => {
  const { echoTrack } = useContext(AppContext);
  const {
    state: { mixerModeOn },
    toggleMixer,
  } = useContext(MixerContext);

  const handleMixerToggle = () => {
    const pageContext = history.location.pathname.substring(1);

    echoTrack(pageContext, { action: 'mixer_toggle' }, 'click');
    toggleMixer(!mixerModeOn);
  };

  return (
    <button
      type="button"
      onClick={handleMixerToggle}
      className="flex flex-row items-center justify-between cursor-pointer hover:underline focus:outline-none focus:underline"
      title={mixerModeOn ? 'Disable mixer mode' : 'Enable mixer mode'}
      aria-label={mixerModeOn ? 'Disable mixer mode' : 'Enable mixer mode'}
    >
      <span className="mr-2 text-lg font-bold text-gray-900 md:text-base lg:text-xl">
        Sound Mixer
      </span>
      <div
        className={`relative w-10 h-5 ${
          mixerModeOn ? 'bg-gray-900' : 'bg-gray-700'
        } transition-all duration-300 rounded-full`}
      >
        <span
          className="absolute w-4 h-4 transition-all duration-300 bg-white rounded-full"
          style={{
            top: '2px',
            left: mixerModeOn ? '1.4rem' : '2px',
            right: mixerModeOn ? '2px' : '22px',
          }}
        ></span>
      </div>
    </button>
  );
};

MixerToggle.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default withRouter(MixerToggle);
