/* eslint-disable no-undef */
const packageJson = require('../package.json');

const appUrls = {
  local: {
    client: `${process.env.REACT_APP_CLIENT_URL}`,
    api: `${process.env.REACT_APP_API_URL}`,
    batchDownload: `${process.env.REACT_APP_WS_URL}`,
  },
  production: {
    client: `${process.env.REACT_APP_CLIENT_URL}`,
    api: `${process.env.REACT_APP_API_URL}`,
    batchDownload: `${process.env.REACT_APP_WS_URL}`,
  },
};

export const VERSION = packageJson.version;
export const ENVIRONMENT = process.env.REACT_APP_APP_ENV || 'production';

export const APP_URL = appUrls[ENVIRONMENT].client;
export const API_URL = appUrls[ENVIRONMENT].api;
export const BATCH_DOWNLOAD_FUNCTION = appUrls[ENVIRONMENT].batchDownload;
export const MEDIA_BASE_URL = `${process.env.REACT_APP_API_URL}`;
export const MEDIA_LOW_QUALITY_URL = `${MEDIA_BASE_URL}/mp3`;
export const MEDIA_HIGH_QUALITY_URL = `${MEDIA_BASE_URL}/wav`;
export const WAVEFORM_URL = `${MEDIA_BASE_URL}/meta`;
export const DOWNLOAD_URL = `${MEDIA_BASE_URL}`;

export const CATEGORY_TYPE = 0;
export const CATEGORY_MIDDLE = 1;
export const CATEGORY_SUB = 2;

export const CATEGORY_LIST_MOST_POPULAR = [1, 2, 3, 4];

export const INITIAL_CATEGORIES_MD = 12;
export const INITIAL_CATEGORIES_SM = 4;

export const SORT_OPTIONS = [
  {
    label: 'Relevance',
    sort: 'relevance',
  },
  {
    label: 'Duration (asc)',
    sort: 'duration-asc',
  },
  {
    label: 'Duration (desc)',
    sort: 'duration-desc',
  },
];

export const SOURCES = {
  bbc_archive: {
    text: 'DIGWIX Sound Effects',
  },
  natural_history_unit: {
    text: 'Natural History Unit',
  },
  soundwix_archive: {
    text: 'DIGWIX Sound Effects',
  },
};

export const LOCALSTORAGE_FAVOURITES = 'bbcsfx-favourites';
export const LOCALSTORAGE_ACCEPT_FAVOURITES_LIMIT =
  'bbcsfx-accept-favourites-limit';

export const COOKIES_EXPIRY = 395; // days, approx equivalent to bbc.co.uk
export const COOKIE_TERMS_AGREEMENT = 'bbcsfx-download-terms-agreed';
export const COOKIE_RATING_SUBMITTED = 'bbcsfx-rating-submitted';
export const COOKIE_BANNER_ACCEPTED = 'bbcsfx-accepted-cookies';
export const COOKIE_FAVOURITES_BANNER_ACCEPTED =
  'bbcsfx-favourites-banner-dismissed';
export const USER_LOGON = 'user_logon';

export const DEFAULT_PAGE_ROUTE = '/';

// Rating modal
export const RATING_MODAL_TIMER = 180000; // 3 minutes

export const RATING_VALUES = [
  {
    value: 1,
    icon: 'rating-sad',
    title: 'No',
  },
  {
    value: 3,
    icon: 'rating-neutral',
    title: 'Somewhat',
  },
  {
    value: 5,
    icon: 'rating-happy',
    title: 'Yes',
  },
];

// Useful for GEL icons
export const THEME_COLOURS = {
  PRIMARY: '#333E48',
  SECONDARY: '#007E8F',
  TERTIARY: '#3A549C',
  OFF_WHITE: '#F9F9F9', // Off White
  MEDIUM_GREY: '#E3E3E3', // Medium Gray
  GREY: '#CECECE', // Gray
  DARK_GREY: '#C8C8C8', // Dark Gray
  BLUE_GREY: '#CFD5DA', // Blue Gray
  NAVY_GREY: '#848B90', // Navy Gray
  WARNING: '#8F0000', // Dark red
};

export const RESULT_SIZE_CONFIG = {
  incrementSize: 10,
  sizeLimit: 300,
};

export const DOWNLOAD_SEGMENT_CONFIG = {
  segmentSize: 100,
};

export const FAVOURITES_CONFIG = {
  sizeLimit: 100,
};

export const MIXER_CONFIG = {
  sizeLimit: 100,
};

export const DEFAULT_SNACKBAR_OPTIONS = {
  position: 'top-right',
  style: {
    backgroundColor: THEME_COLOURS.SECONDARY,
    fontFamily: 'ReithSans, sans-serif',
    fontWeight: 'bold',
  },
};

export const ERROR_SNACKBAR_OPTIONS = {
  ...DEFAULT_SNACKBAR_OPTIONS,
  style: {
    ...DEFAULT_SNACKBAR_OPTIONS.style,
    backgroundColor: THEME_COLOURS.WARNING,
  },
};
