import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import Cookie from 'js-cookie';

import BaseModal from '../BaseModal';
import GelIcon from '../GelIcon';
import {
  COOKIES_EXPIRY,
  COOKIE_TERMS_AGREEMENT,
  THEME_COLOURS,
} from '../../constants';
import { AppContext } from '../../Context/AppContext';
import { useTranslation } from 'react-i18next';

function TermsOfUseModal({ asset, setShowTermsModal, selectedFile }) {
  const { echoTrack } = useContext(AppContext);

  const handleAgreeClick = () => {
    Cookie.set(COOKIE_TERMS_AGREEMENT, true, {
      expires: COOKIES_EXPIRY,
      domain:
        process.env.REACT_APP_APP_ENV === 'development' ? null : 'soundwix.com',
    });
    setShowTermsModal(false);
    window.open(selectedFile);
    echoTrack(
      'audioplayer',
      { action: 'download_media', data: { assetId: asset.id } },
      'click'
    );
  };
  const { t } = useTranslation();
  return (
    <BaseModal
      labelId="download_modal_title"
      descriptionId="download_modal_desc"
    >
      <div className="text-left text-gray-900">
        <div className="px-6 pt-6 sm:px-8 sm:pt-8">
          <div className="flex items-center justify-between pb-3">
            <div className="flex flex-row items-center space-x-3">
              <GelIcon
                name="download"
                fill={THEME_COLOURS.PRIMARY}
                className="w-4 h-4"
              />
              <span className="text-2xl font-bold" id="download_modal_title">
                Download
              </span>
            </div>
            <button
              type="button"
              className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300"
              onClick={() => setShowTermsModal(false)}
              aria-label="Close"
            >
              <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
            </button>
          </div>
          <p className="mb-5" id="download_modal_desc">
            By continuing, you agree to comply with the terms of the&nbsp;
            <a
              href="/licensing"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold text-blue-800 underline"
            >
              RemArc Licence
            </a>
            &nbsp;for this and any future downloads.
          </p>
          <p className="mb-6">
            Commercial use of this content is not allowed under the RemArc
            license.
          </p>
          <p className="mb-6">
            For commercial use, buy the sound effect from{' '}
            <strong>Pro Sound Effects</strong> which can be found in the{' '}
            <strong>{t('more_detail')}</strong> section for each sound effect.
          </p>
        </div>
        <div className="flex justify-end w-full mt-4 sm:pr-8 sm:pb-8">
          <button
            type="button"
            className="w-full px-6 py-3 font-bold text-white bg-teal-700 focus:outline-none sm:w-auto hover:opacity-75 focus:opacity-75"
            onClick={handleAgreeClick}
          >
            Agree
          </button>
        </div>
      </div>
    </BaseModal>
  );
}

TermsOfUseModal.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    file: PropTypes.shape({
      small: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
  setShowTermsModal: PropTypes.func.isRequired,
  selectedFile: PropTypes.string.isRequired,
};

export default TermsOfUseModal;
