import React from 'react';
import PropTypes from 'prop-types';

import BaseModal from '../../Components/BaseModal';
import GelIcon from '../../Components/GelIcon';
import { THEME_COLOURS } from '../../constants';

const VolumeWarningModal = ({ setShowVolumeWarningModal }) => {
  return (
    <BaseModal labelId="volumeinfo_modal_title">
      <div className="text-left">
        <div className="flex items-center justify-between px-6 py-8 sm:px-8">
          <div className="flex flex-row items-center space-x-3">
            <GelIcon
              name="music-track"
              fill={THEME_COLOURS.PRIMARY}
              className="w-5 h-5"
            />
            <span
              className="text-2xl font-bold text-gray-900"
              id="volumeinfo_modal_title"
            >
              Volume Control Not Supported
            </span>
          </div>
          <button
            type="button"
            className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300"
            onClick={() => setShowVolumeWarningModal(false)}
            aria-label="Close"
          >
            <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
          </button>
        </div>
        <div className="px-6 text-gray-900 sm:px-8">
          <p>
            Unfortunately individual volume controls for mixer assets are not
            yet available on this device. If you imported this mix via a link
            sent to you, any volume adjustments that may have been applied by
            the mix author won&apos;t be applied here.
          </p>
          <br />
          <p>
            Volume controls will work in desktop browsers and Android devices.
          </p>
        </div>
        <div className="flex justify-center w-full mt-10 md:justify-end md:pb-8 md:px-6">
          <button
            className="w-full px-4 py-3 font-semibold text-white bg-teal-700 md:w-1/3 focus:outline-none hover:opacity-75 focus:opacity-75"
            onClick={() => setShowVolumeWarningModal(false)}
          >
            Okay, got it
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

VolumeWarningModal.propTypes = {
  setShowVolumeWarningModal: PropTypes.func.isRequired,
};

export default VolumeWarningModal;
