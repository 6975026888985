import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';
import { FavouritesContext } from '../../Context/FavouritesContext';

import BaseModal from '../../Components/BaseModal';
import GelIcon from '../../Components/GelIcon';
import SocialIcons from '../../Components/SocialIcons';

import { APP_URL, THEME_COLOURS } from '../../constants';

function ShareFavouritesModal({ setShowShareModal }) {
  const { echoTrack } = useContext(AppContext);
  const {
    state: { favouriteIds },
  } = useContext(FavouritesContext);

  const inputRef = useRef(null);

  const [encodedString, setEncodedString] = useState(null);
  const [copyText, setCopyText] = useState('Copy');

  // Encode favourite IDs on mount
  // Check the route on mount to see if shared page route
  useEffect(() => {
    try {
      setEncodedString(window.btoa(JSON.stringify({ favouriteIds })));
    } catch (e) {
      setShowShareModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouriteIds]);

  const handleCopy = () => {
    echoTrack('favourites', { action: 'favourites_share_copied' }, 'click');

    inputRef.current.removeAttribute('disabled');

    inputRef.current.select();
    inputRef.current.setSelectionRange(0, 99999);

    document.execCommand('copy');

    inputRef.current.setAttribute('disabled', true);

    setCopyText('Copied');
  };

  return (
    <BaseModal
      labelId="share_favourites_modal_title"
      descriptionId="share_favourites_modal_desc"
    >
      <p className="sr-only" id="share_favourites_modal_desc">
        A tool to share your favourites with either a URL or to social media.
      </p>
      <div className="px-6 py-8 text-left sm:px-8">
        <div className="flex items-center justify-between pb-3">
          <div className="flex flex-row items-center space-x-3">
            <GelIcon
              name="share"
              fill={THEME_COLOURS.PRIMARY}
              className="w-4 h-4"
            />
            <span
              className="text-2xl font-bold text-gray-900"
              id="share_favourites_modal_title"
            >
              Share Favourites
            </span>
          </div>
          <button
            type="button"
            className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300"
            onClick={() => setShowShareModal(false)}
            aria-label="Close"
          >
            <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
          </button>
        </div>
        <div>
          <p className="mb-3 text-gray-900">Share link</p>
          <div className="relative flex flex-row items-center">
            <div className="absolute top-0 flex flex-col justify-center h-full ml-3">
              <GelIcon
                name="link"
                fill={THEME_COLOURS.PRIMARY}
                className="w-3 h-3 opacity-75"
              />
            </div>
            <label className="sr-only" htmlFor="share-link">
              Share link
            </label>
            <input
              id="share-link"
              ref={inputRef}
              className="w-full py-3 pl-8 pr-2 text-gray-900 bg-gray-100"
              disabled
              type="text"
              value={`${APP_URL}/favourites?favouritesstring=${encodedString}`}
            />
            <button
              type="button"
              className="px-4 py-3 font-bold text-white bg-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75"
              onClick={handleCopy}
            >
              {copyText}
            </button>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end mt-8 space-x-3 sm:justify-start">
          <SocialIcons
            shareUrl={`${APP_URL}/favourites?favouritesstring=${encodedString}`}
            className="w-6 h-6 text-white"
          />
        </div>
      </div>
    </BaseModal>
  );
}

ShareFavouritesModal.propTypes = {
  setShowShareModal: PropTypes.func,
};

export default ShareFavouritesModal;
