import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

import { AppContext } from '../../Context/AppContext';
import { useTranslation } from 'react-i18next';

function About() {
  const { echoTrack } = useContext(AppContext);
  const { t } = useTranslation();
  useEffect(() => {
    echoTrack('about');

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet title="About | DIGWIX Sound Effects" />
      <div
        style={{ color: 'white', fontFamily: 'Inter' }}
        className="max-w-screen-xl min-h-screen px-3 mx-auto"
      >
        <h2
          style={{ fontSize: '3rem' }}
          className="py-5 text-3xl font-bold md:py-10"
        >
          About
        </h2>
        <div style={{ fontSize: '1.5rem' }} className="pb-10 space-y-5">
          <p>{t('about_desc_1')}</p>
          <p>{t('about_desc_2')}</p>
          <br />
          <h2 style={{ fontSize: '2rem' }} className="text-xl font-bold">
            {t('about_desc_3')}
          </h2>
          <p>{t('about_desc_4')}</p>
          <p>{t('about_desc_5')}</p>
          <p>{t('about_desc_6')}</p>
        </div>
      </div>
    </>
  );
}

export default About;
