import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line consistent-return
const getIcon = (name) => {
  if (name === 'next') {
    return <path d="M29 16L3 0v7.2L17.6 16 3 24.8V32" />;
  }
  if (name === 'chevron-left') {
    return (
      <path d="M10.4 14.3L26.5 31h-6.4L5.5 16 20.1 1h6.4L10.4 17.7v-3.4z"></path>
    );
  }
  if (name === 'chevron-up') {
    return (
      <path d="M17.7 9.4L.9 27.8v-6.7L16 4.2 31.1 21v6.7L14.3 9.4h3.4zm0 0L.9 27.8v-6.7L16 4.2 31.1 21v6.7L14.3 9.4h3.4z"></path>
    );
  }
  if (name === 'chevron-down') {
    return (
      <path d="M17.7 22.6L.9 4.2v6.7L16 27.8 31.1 11V4.3L14.3 22.6h3.4zm0 0L.9 4.2v6.7L16 27.8 31.1 11V4.3L14.3 22.6h3.4z"></path>
    );
  }
  if (name === 'chevron-right') {
    return (
      <path d="M21.6 14.3L5.5 31h6.4l14.6-15L11.9 1H5.5l16.1 16.7v-3.4z"></path>
    );
  }
  if (name === 'close') {
    return (
      <path d="M30 4.6l-2.8-2.8L2 27.4l2.8 2.8L30 4.6zM4.8 1.8L1.9 4.7l25.2 25.5 2.9-2.9L4.8 1.8z"></path>
    );
  }
  if (name === 'add') {
    return (
      <path d="M29.6 13.4H18.7v-11h-5.4v11H2.4v5.3h10.9v11h5.4v-11h10.9v-5.3z"></path>
    );
  }
  if (name === 'check') {
    return (
      <path d="M32 7.2l-2.5-2.4L11 23.3h2L2.4 12.6 0 15.1l12 12.1 20-20z"></path>
    );
  }
  if (name === 'list-view-text') {
    return (
      <path d="M1 7.5h30V1.9H1v5.6zm0 22.6h30v-5.6H1v5.6zm0-11.3h30v-5.6H1v5.6z"></path>
    );
  }
  if (name === 'share') {
    return (
      <path d="M7.8 17L25.3 7l-1.2-2.3L6.6 15l1.2 2zm-1.2 0l17.5 10.3 1.2-2.3L7.8 15l-1.2 2zm5.6-1c0-2.7-2.2-5-5-5-2.7 0-4.9 2.2-4.9 5 0 2.7 2.2 4.9 4.9 4.9 2.8 0 5-2.2 5-4.9zM29.7 5.9c0-2.7-2.2-5-5-5-2.7 0-4.9 2.2-4.9 5 0 2.7 2.2 4.9 4.9 4.9 2.8 0 5-2.2 5-4.9zm0 20.2c0-2.7-2.2-5-5-5-2.7 0-4.9 2.2-4.9 5 0 2.7 2.2 4.9 4.9 4.9 2.8.1 5-2.2 5-4.9z"></path>
    );
  }
  if (name === 'play') {
    return <path d="M29 16L5.8 1v30L29 16z"></path>;
  }
  if (name === 'pause') {
    return (
      <path d="M4.7 30.3h6.5V1.7H4.7v28.6zM20.8 1.7v28.6h6.5V1.7h-6.5z"></path>
    );
  }
  if (name === 'audio') {
    return (
      <path d="M26.9 16c0-3.9-1.5-7.4-3.9-10.1l-1.8 1.8c2 2.2 3.2 5.1 3.2 8.3s-1.2 6.1-3.2 8.3l1.8 1.8c2.4-2.7 3.9-6.2 3.9-10.1zm2.6 0c0 4.6-1.8 8.8-4.7 11.9l1.8 1.8C29.9 26.1 32 21.3 32 16S29.9 5.9 26.5 2.3l-1.8 1.8c3 3.1 4.8 7.3 4.8 11.9zm-23 3.9V12H1.2c-.7 0-1.2.5-1.2 1.2v5.4c0 .7.5 1.2 1.2 1.2h5.3zM.3 15.1l2.4 1.4 11.9-7v13L3.5 16.1l-2.4 1.4L17 26.7V5.3L.3 15.1z"></path>
    );
  }
  if (name === 'sound-on-off') {
    return (
      <path d="M20.7 11.8l9.8 9.8 1.5-1.5-9.8-9.8-1.5 1.5zm11.3 0l-1.5-1.5-9.8 9.8 1.5 1.5 9.8-9.8zM6.7 19.7v-7.4H1.3c-.7 0-1.3.6-1.3 1.3v4.8c0 .7.6 1.3 1.3 1.3h5.4zM.3 15.1l2.5 1.4L15 9.3v13.3L3.6 16.1l-2.5 1.4 16.4 9.4V5.1L.3 15.1z"></path>
    );
  }
  if (name === 'love-filled') {
    return (
      <path d="M30.7 10.9c0-4.4-2.9-7.8-7.4-7.8-2.8 0-5.3.8-7.3 4-2-3.2-4.5-4-7.3-4-4.5 0-7.4 3.4-7.4 7.8C1.3 16.1 6.2 22 16 28.8c9.8-6.7 14.7-12.6 14.7-17.9z"></path>
    );
  }
  if (name === 'love') {
    return (
      <path d="M30.7 10.9c0-4.4-2.9-7.8-7.4-7.8-2.8 0-5.3.8-7.3 4-2-3.2-4.5-4-7.3-4-4.5 0-7.4 3.4-7.4 7.8C1.3 16.1 6.2 22 16 28.8c9.8-6.7 14.7-12.6 14.7-17.9zm-7.4-5.1c2.9 0 4.8 2.3 4.8 5.2 0 4.1-4 8.9-12.1 14.7C7.9 19.8 3.9 15 3.9 10.9c0-2.9 1.9-5.2 4.8-5.2 2.2 0 4.1 1.1 5.7 3.4.6.8 1.1 1.1 1.6 1.1s1.1-.3 1.6-1.1c1.5-2.2 3.3-3.3 5.7-3.3z"></path>
    );
  }
  if (name === 'download') {
    return (
      <path d="M14.7 20.3h2.6V1h-2.6v19.3zM31 25.8v-6h-2.6v6c0 2-.6 2.6-2.6 2.6H6.2c-2 0-2.6-.6-2.6-2.6v-6H1v6c0 2.8.2 5.2 5.2 5.2h19.5c5.1 0 5.3-2.4 5.3-5.2zm-14.9-7.1L6.5 9.1 4.6 11 16 22.4 27.5 11l-1.8-1.9-9.6 9.6z"></path>
    );
  }
  if (name === 'filter') {
    return (
      <path d="M17.4 27.7H19V16l-1.1 2.9 3.7-4.1c3.6-3.7 5.5-7.3 5.5-10.6H4.9c0 3.3 1.9 6.8 5.5 10.6l3.7 4.1L13 16v9l4.4 2.7z"></path>
    );
  }
  if (name === 'search') {
    return (
      <path d="M13 24.3c6.5 0 11.7-5.1 11.7-11.7S19.6 1 13 1 1.4 6.1 1.4 12.7 6.5 24.3 13 24.3zm0-2.6c-5.1 0-9-3.9-9-9.1s3.9-9.1 9-9.1 9 3.9 9 9.1-3.9 9.1-9 9.1zm5.8.4l8.9 8.9 2.9-2.9-8.9-8.9-2.9 2.9z"></path>
    );
  }
  if (name === 'refresh') {
    return (
      <path d="M7.6 22.3l-2 1.4c2.1 3.4 5.8 5.5 10.6 5.5 6.7 0 12.4-4.9 12.4-12.7v-1.8h-2.5v1.8c0 6.3-4.4 10.2-10.1 10.2-3.9.1-6.9-1.8-8.4-4.4zM32 18v-2.2l-4.7-3.3-4.7 3.3V18l4.7-3.1L32 18zm-7.5-8.3l2-1.4C24.4 5 20.7 2.8 15.9 2.8 9.2 2.8 3.5 7.7 3.5 15.5v1.8H6v-1.8C6 9.1 10.4 5.2 16.1 5.2c3.9 0 6.8 1.9 8.4 4.5zM0 14v2.2l4.7 3.3 4.7-3.3V14l-4.7 3.1L0 14z"></path>
    );
  }
  if (name === 'loop') {
    // Not in GEL
    return (
      <path d="M42.2352 0.071066C42.0229 0.142132 41.9522 0.426396 41.9522 0.781726V6.96447H6.15488C4.59847 6.96447 2.97132 7.53299 1.76864 8.74112C0.565966 9.94924 0 11.5838 0 13.1472V42.9239C0 44.4873 0.565966 46.1218 1.76864 47.3299C2.97132 48.5381 4.59847 49.1066 6.15488 49.1066H12.3098V41.6447H7.35755V14.3553H41.8815V20.5381C41.8815 21.2487 42.3767 21.4619 42.8719 21.0355L55.6769 11.4416C56.1721 10.9442 56.1721 10.3756 55.6769 9.87817L42.9426 0.284264C42.7304 0 42.4474 -0.071066 42.2352 0.071066ZM61.6903 6.96447V14.4264H66.6424V41.7157H32.0478V35.533C32.0478 34.8223 31.5526 34.6091 31.0574 35.0355L18.2524 44.5584C17.7572 45.0558 17.7572 45.6244 18.2524 46.1218L31.0574 55.7157C31.5526 56.2132 32.0478 56 32.0478 55.2183V49.0355H67.8451C69.4015 49.0355 71.0287 48.467 72.2314 47.2589C73.434 46.0508 74 44.4162 74 42.8528V13.1472C74 11.5838 73.434 9.94924 72.2314 8.74112C71.0287 7.53299 69.4015 6.96447 67.8451 6.96447H61.6903Z" />
    );
  }
  if (name === 'movement-on') {
    return (
      <path d="M8.6 12.5h-2V5h19.3v20.3h-5.1v-2h3.1V7H8.6v5.5zm12.8 12.8H9.2l.3-.9 5.2 5.2-1.4 1.3-6.7-6.6 6.7-6.7 1.3 1.5-5.2 5.1-.3-.9h12.3v2z"></path>
    );
  }
  if (name === 'movement-off') {
    return (
      <path d="M19.3 7H8.5v5.6h-2V5h14.9l-2.1 2zm6.6-2v20.3h-5.1v-2h3.1V7l2-2zm-4.5 20.3H9.1l.2-.9 5.2 5.2-1.3 1.4-6.7-6.7 6.7-6.7 1.3 1.5-5.2 5.1-.3-.8h12.3l.1 1.9zM3.3 21.2L23.5 1l1.4 1.4L4.7 22.6l-1.4-1.4z"></path>
    );
  }
  if (name === 'loading') {
    return (
      <path d="M29 8.3l-2.5 1.5c1.1 1.8 1.6 3.9 1.6 6.2 0 6.9-5.3 12.2-12.1 12.2-6.9 0-12.1-5.3-12.1-12.2S9.1 3.8 16 3.8V1C7.5 1 1 7.5 1 16s6.5 15 15 15 15-6.5 15-15c0-2.9-.7-5.5-2-7.7z"></path>
    );
  }
  if (name === 'link') {
    return (
      <path d="M20.7 9.5l-11 11.1 1.8 1.8 11.1-11.1-1.9-1.8zm.6 6.3l-2 2c2.1 1.4 5.9.8 8.7-2l.6-.6c3-3 3.9-7.6-.1-11.7-4-4-8.6-3-11.6 0l-.6.5c-3 3-3.5 6.7-2 8.7l2-2c-.3-1.1-.2-2.7 2-4.9l.6-.6c2.4-2.4 5.4-2.5 7.9 0 2.5 2.6 2.4 5.5 0 7.9l-.6.6c-2.2 2.3-3.9 2.4-4.9 2.1zm-5.5 5.5c.3 1 .2 2.7-2 4.8l-.6.6c-2.4 2.4-5.4 2.5-7.9 0s-2.4-5.5 0-7.9l.6-.6c2.1-2.1 3.8-2.3 4.9-2l2-2c-2-1.4-5.7-.9-8.7 2l-.6.6c-3 3-4 7.6 0 11.6s8.6 3.1 11.7.1l.6-.6c2.9-2.9 3.5-6.7 2-8.7l-2 2.1z"></path>
    );
  }
  if (name === 'external-link') {
    return (
      <path d="M21.9 16.9v11.5H3.7V10.2h11.1V7.6H1V31h23.6V16.9h-2.7zM18 1v2.6h10.2l-.5-1.1L11 19l1.9 1.9L29.6 4.3l-1.2-.5V14H31V1H18z"></path>
    );
  }
  if (name === 'edit') {
    return (
      <path d="M2.4 28.6l1 1L13.9 27l15.8-15.7-8.9-8.9L5 18.1 2.4 28.6zM15 25.2l-8.3-8.3-.5 2.3 6.6 6.6 2.2-.6zm-1-.3L3.5 27.5l.9.9L7.1 18l-1.6 2.8L21.8 4.5l-1.4.6 6.7 6.6.4-1.5-16.3 16.3 2.8-1.6zm-6.7 1.8l-2.1-2.1-1.1 3.2 3.2-1.1z"></path>
    );
  }
  if (name === 'scissors') {
    return (
      <path d="M14.2 16.1l1.7 1.3 12.4 8c1 .6 1.8.2 2.2-.6.4-.8.1-1.4-.9-2.1l-10.1-7.3-2.3-1.5-7.5-5.4-.9 1.7 5.4 5.9zm-5.4 5.8l.9 1.7 8-5.6 1.8-1.2 10.2-7.4c1-.7 1.2-1.4.9-2.1-.5-.8-1.2-1.2-2.2-.6l-12.4 8-1.8 1.3-5.4 5.9zm-7.5 1.7c0 2.6 2 4.7 4.7 4.7s4.7-2 4.7-4.7-2-4.7-4.7-4.7-4.7 2-4.7 4.7zm2.2 0C3.5 22.1 4.6 21 6 21c1.5 0 2.6 1.1 2.6 2.6 0 1.4-1.1 2.5-2.6 2.5-1.4 0-2.5-1.1-2.5-2.5zM1.3 8.5c0 2.6 2 4.7 4.7 4.7s4.7-2 4.7-4.7-2-4.7-4.7-4.7c-2.7-.1-4.7 2-4.7 4.7zm2.2 0C3.5 7 4.6 5.9 6 5.9c1.5 0 2.6 1.1 2.6 2.6C8.6 9.9 7.5 11 6 11c-1.4-.1-2.5-1.1-2.5-2.5z"></path>
    );
  }
  if (name === 'save') {
    return (
      <path d="M25.4 4c-1-1.4-2-1.6-5-1.6H2.3v27.1h27.4V12.8c0-2-.2-2.9-1.1-4.3L25.4 4zm-1.7 2.1l2.8 4c.6.8.6 1.1.6 2.8V27H4.9V5.1h16.2c1.5 0 2 .1 2.6 1zM10.3 4.2v6.6h10.5V4.2H10.3zM8.8 28.5h2.6v-9.7h8.9v9.7H23V16.2H8.8v12.3z"></path>
    );
  }
  if (name === 'help') {
    return (
      <path d="M15.8 26.7c-1.7 0-2.6-1-2.6-2.3s.9-2.3 2.6-2.3c1.7 0 2.6 1 2.6 2.3s-.9 2.3-2.6 2.3zm-1.9-6.9v-.3c0-1.8.6-3.1 2.4-4.3 1.8-1.2 2.3-1.7 2.3-2.9 0-1.4-1.2-2.1-3.3-2.1-1.7 0-3.2.4-4.8 1.4V8c1.7-.9 3.6-1.3 5.6-1.3 4.3 0 7 2 7 5.1 0 2.3-1 3.5-3 4.9-1.9 1.3-2.3 1.8-2.3 2.7v.3h-3.9zM16 31c8.5 0 15-6.5 15-15S24.5 1 16 1 1 7.5 1 16s6.5 15 15 15z"></path>
    );
  }
  if (name === 'info') {
    return (
      <path d="M16 31c8.5 0 15-6.5 15-15S24.5 1 16 1 1 7.5 1 16s6.5 15 15 15zm-2.2-5.5V12.7h4.4v12.9h-4.4zM16 10c-1.7 0-2.7-.9-2.7-2.4s1-2.4 2.7-2.4 2.7.9 2.7 2.4-1 2.4-2.7 2.4z"></path>
    );
  }
  if (name === 'label') {
    return (
      <path d="M3.9 18l13.6 13.6 14-14L18 4C13-1 7.7-.2 3.8 3.8-.3 7.7-1.1 13 3.9 18zm2-1.8C2.3 12.6 2.2 9 5.6 5.6c3.4-3.4 7-3.3 10.6.3l12.9 12.9v-2.4L16.4 29.1h2.4L5.9 16.2zm7.5-2.8c-1.5 1.5-3.9 1.5-5.4 0C6.4 11.8 6.4 9.6 8 8c1.6-1.6 3.8-1.5 5.4 0 1.5 1.5 1.5 3.8 0 5.4zm-1.6-1.6c.7-.6.6-1.7 0-2.3-.7-.7-1.7-.7-2.3 0-.7.7-.7 1.6 0 2.3s1.6.7 2.3 0z"></path>
    );
  }
  if (name === 'clock') {
    return (
      <>
        <path d="M16 31c8.5 0 15-6.5 15-15S24.5 1 16 1 1 7.5 1 16s6.5 15 15 15zm0-2.7C9 28.3 3.7 23 3.7 16S9 3.7 16 3.7C23 3.7 28.3 9 28.3 16S23 28.3 16 28.3zm6.2-6.7l1-1.5-5.7-4.5-.6-8.6H15l-.7 10.5 7.9 4.1z"></path>
      </>
    );
  }
  if (name === 'map') {
    return (
      <path d="M25.1 17.2c1.2-1.8 1.9-3.9 1.9-6.2 0-6.1-4.9-11-11-11S5 4.9 5 11c0 2.3.7 4.4 1.9 6.2L16 32l9.1-14.8zM16 7c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4z"></path>
    );
  }
  if (name === 'person') {
    // Not in GEL
    return (
      <>
        <path d="M30.1221 41.0156C41.3627 41.0156 50.4749 31.834 50.4749 20.5078C50.4749 9.18166 41.3627 0 30.1221 0C18.8816 0 9.76935 9.18166 9.76935 20.5078C9.76935 31.834 18.8816 41.0156 30.1221 41.0156Z" />
        <path d="M47.0556 38.2266C42.6594 42.4922 36.7164 45.1172 30.1221 45.1172C23.4464 45.1172 17.5034 42.4102 13.0258 38.0625C5.21031 43.5586 0 52.6641 0 63H60C60 52.7461 54.8711 43.6406 47.0556 38.2266Z" />
      </>
    );
  }
  if (name === 'collection') {
    return (
      <path d="M5.5 26H26V5.4h-2.4v18.2H5.5V26zM1 21.1h20V1H1v20.1zM9.8 31H31V9.7h-2.4v18.9H9.8V31z"></path>
    );
  }
  if (name === 'in-season') {
    return (
      <path d="M14.8 30.4H17V21h-2.3v9.4zm6.3-7.8c-2 1.2-2.8 2.4-2.8 4.8v.6l4.6-2.7c2-1.2 2.8-2.4 2.8-4.8v-.6l-4.6 2.7zm-13.7-1v.6c0 2.3.8 3.6 2.8 4.8l3.7 2.1c.5.3.9.6.9 1.3l.1-1V29c0-2.1-.9-3.7-2.8-4.8l-4.7-2.6zm8.5-.2c5.3 0 9.5-4.8 9.5-10.8V1.9c0-.2-.1-.3-.3-.2-1.7 1.2-3.3 2.6-4.6 4.3-1.3-1.8-2.7-3.2-4.4-4.3-.2-.2-.4-.2-.6 0-1.7 1.1-3.1 2.5-4.4 4.3-1.2-1.7-2.8-3.1-4.4-4.2-.2-.2-.3-.1-.3.1v8.7c0 6 4.2 10.8 9.5 10.8zM8.7 7.2c0-.2.1-.2.3-.1.7.8 1.5 1.8 2.3 2.9 1.9-2.6 3.3-4.4 4.3-5.2.2-.2.4-.2.6 0 1 .9 2.4 2.7 4.3 5.2.8-1.1 1.6-2.1 2.3-2.9.2-.1.3-.1.3.1v3.3c0 4.7-3.2 8.5-7.2 8.5s-7.2-3.7-7.2-8.5V7.2z"></path>
    );
  }
  if (name === 'commercial-plane') {
    return (
      <path d="M3.42657 24.084L3.91608 24.5736L8.12587 23.0071L10.3776 27.6085L11.5524 26.4337L10.3776 20.6575L15.1748 15.9582L23.3007 28.098L24.4755 26.9232L20.3636 10.7694L23.986 7.14698C27.4126 3.7204 28.5874 0.979145 27.8042 0.195928C27.021 -0.587288 24.2797 0.587537 20.8531 4.01411L17.2308 7.63649L1.07692 3.5246L0 4.60152L12.1399 12.8253L7.34266 17.5246L1.66434 16.3498L0.489511 17.6225L5.09091 19.8742L3.42657 24.084Z" />
    );
  }
  if (name === 'paw') {
    return (
      <path d="M11.0997 11.4126C13.9038 11.4126 15.5395 9.15385 15.5395 6.3007C15.5395 2.73427 13.9038 0 11.567 0C9.23024 0 6.77663 2.4965 6.77663 5.46853C6.77663 7.25175 8.52921 11.4126 11.0997 11.4126ZM21.8488 11.5315C24.4192 11.5315 26.2887 7.37063 26.2887 5.58741C26.2887 2.4965 23.8351 0.118881 21.3814 0.118881C19.0447 0.118881 17.5258 2.97203 17.5258 6.41958C17.4089 9.27273 19.1615 11.5315 21.8488 11.5315ZM4.43986 19.6154C6.89347 19.6154 8.99656 17.8322 8.99656 15.6923C8.99656 12.2448 6.89347 9.51049 4.43986 9.51049C1.98625 9.51049 0 12.1259 0 15.3357C0 17.7133 1.98625 19.6154 4.43986 19.6154ZM29.0928 20.0909C31.8969 20.0909 34 17.951 34 15.4545C34 11.8881 31.8969 9.03496 29.0928 9.03496C26.4055 9.03496 24.3024 12.1259 24.3024 15.8112C24.3024 18.1888 26.4055 20.0909 29.0928 20.0909ZM16.4742 13.7902C13.3196 13.7902 11.2165 16.6434 10.7491 19.1399C9.93127 23.4196 4.5567 21.7552 4.5567 28.2937C4.5567 31.2657 7.01031 34 10.866 34C13.4364 34 14.488 32.3357 16.4742 32.3357C18.4605 32.3357 19.512 34 22.0825 34C25.9381 34 28.3918 31.2657 28.3918 28.2937C28.3918 21.6364 23.0172 23.4196 22.1993 19.1399C21.732 16.5245 19.6289 13.7902 16.4742 13.7902Z" />
    );
  }
  if (name === 'notifications') {
    return (
      <path d="M17.8 3v3.5c1.8.3 3.2 1.1 4.2 2.3s1.5 2.8 1.5 4.7v3.3c0 1.2.3 2.1.8 2.6.5.6 1.4 1 2.6 1.1l1.3.2v2.5H3.8v-2.5l1.3-.2c1.2-.1 2.1-.5 2.6-1.1.5-.6.8-1.5.8-2.6v-3.3c0-1.9.5-3.5 1.5-4.7s2.4-2 4.2-2.4V3h3.6zM16 29c-1.1 0-1.9-.4-2.6-1.1-.7-.7-1-1.6-1-2.6h7.1c0 1-.3 1.8-1 2.6-.6.8-1.4 1.1-2.5 1.1z"></path>
    );
  }
  if (name === 'comedy') {
    return (
      <path d="M31 16c0 8.2-6.8 15-15 15S1 24.2 1 16 7.8 1 16 1s15 6.8 15 15zm-10.2-4c.8 0 1.5.3 1.8 1 .2.5.5.6.9.6.5 0 .9-.4.6-1-.6-1.4-2-2.1-3.3-2.1-1.4 0-2.7.7-3.3 2.1-.3.6.1 1 .6 1 .4 0 .7-.2.9-.6.3-.7 1-1 1.8-1zm-9.6 0c.8 0 1.5.3 1.8 1 .2.5.5.6.9.6.5 0 .9-.4.6-1-.6-1.4-2-2.1-3.3-2.1-1.4 0-2.7.7-3.3 2.1-.3.6.1 1 .6 1 .4 0 .7-.2.9-.6.2-.7 1-1 1.8-1zm-4.1 5.4c0 4.9 4 8.9 8.9 8.9s8.9-4 8.9-8.9H7.1z" />
    );
  }
  if (name === 'group') {
    return (
      <path d="M25.2 26c-1.2-4-4.9-7-9.2-7s-8.1 3-9.2 7h18.4zM16 17.6c-3.2 0-5.8-2.6-5.8-5.8S12.8 6 16 6s5.8 2.6 5.8 5.8-2.6 5.8-5.8 5.8zm9 1.7c2.4 0 4.4-2 4.4-4.4s-2-4.4-4.4-4.4c-.5 0-.9 0-1.3.1.1.4.1.8.1 1.2 0 2.2-.9 4.3-2.4 5.7.9 1 2.1 1.8 3.6 1.8zM7 10.4c-2.4 0-4.4 2-4.4 4.4s2 4.4 4.4 4.4c1.5 0 2.8-.7 3.6-1.8C9 16 8.1 14 8.1 11.8c0-.4 0-.8.1-1.2-.4-.2-.8-.2-1.2-.2zM32 26c-.9-3-3.7-5.3-7-5.3-.3 0-.7 0-1 .1 1.3 1.4 2.4 3.2 3.3 5.2H32zM7 20.8c-3.3 0-6.1 2.2-7 5.3h4.7c.8-2 1.9-3.8 3.3-5.2-.3-.1-.7-.1-1-.1z"></path>
    );
  }
  if (name === 'platform-game') {
    return (
      <path d="M8.9 31.4h11.3v-5.8H8.9v5.8zm10.5-10.9v5.8h11.3v-5.8H19.4zM14.3 18l1 6.1h1.6l-.2-7.3-3.7-2.2 1-5.1 2.4 2.4h.6l2.7-4.8-1.2-.5-2.8 3.5 1.7-.1-3.1-3.5c-.7-.5-1.6-.8-2.6-.8-.7-.1-1.7.1-2.5.5L5.5 9.1l-2.6 4.1 1.1.6 2.5-3.1 2.6-1.8-.8 5.3L7 17.7l-5.7 2.1.4 1.4 7.2-1.7 1.8-2.5 3.6 1zM10.5 2.8c0-1.2.9-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1z"></path>
    );
  }
  if (name === 'disruption') {
    return (
      <path d="M30.4 3H1.6v16.2h28.9V3zm-1.7 14.6H3.3V4.7h25.5v12.9zm-26-1.4L14.2 4.8l-2.9-1.2-1.6.1-7.1 7.2.1 5.3zm8.3 2.7L25.2 4.8l-2.8-1.1-1.7.1L5.9 18.6l.4.4H11zm11.4-.4l7.4-7.4-.4-5.1L17 18.5l.3.3 5.1-.3zM7 19.1L4.2 29h3.4l2.8-9.9H7zm14.6 0l2.8 9.9h3.4L25 19.1h-3.4z"></path>
    );
  }
  if (name === 'pc') {
    return (
      <path d="M3.5 21.9h24.8V7.2c0-1.5-1-2.5-2.5-2.5H6c-1.5 0-2.5 1-2.5 2.5v14.7zM6 7.2h19.7v13.2H6V7.2zm22.3 14.7H3.5L.4 25.6c-.2.2-.4.6-.4 1v.7h32v-.7c0-.3-.1-.7-.4-.9l-3.3-3.8z"></path>
    );
  }
  if (name === 'events') {
    return (
      <path d="M1.8 9H27V2.3h-4.4v2.5H20V2.3H8.8v2.5H6.2V2.3H1.8V9zm22.5 4H27V8.7h-2.7V13zm-1.1 11.7l-4.5 5-.3-6.7-6.1-2.7 6.3-2.3.8-6.7 4.2 5.3 6.6-1.3-3.8 5.6 3.3 5.9-6.5-2.1zm-8.6 1.7v-2.6h-8c-1.6 0-2.1-.5-2.1-2.1v-13H1.8V22c0 3.4 1.2 4.4 4.4 4.4h8.4z"></path>
    );
  }
  if (name === 'fire') {
    return (
      <path d="M14.3 14.7c1.2.5 3.8 2.9 3.8 6.4 0 .7-.1 1.3-.5 2 .6 0 2-1.2 2.2-2.3 1 1.5 1.5 3.1 1.5 4.9 0 2.1-1.2 3.9-2.9 4.7 4.2-1 7.2-4.6 7.2-9.2 0-3.2-.9-6.2-2.7-8.8-.4 2-3 4.1-4 4.1.6-1.2.8-2.3.8-3.5 0-6.3-4.7-10.7-6.8-11.5.3 1.1.4 2.1.4 3 0 7.3-7.2 9.2-7.2 16.7 0 4.6 3 8.2 7.3 9.2-1.8-.9-2.9-2.6-2.9-4.8 0-4.1 4-5.2 4-9.3.1-.5 0-1-.2-1.6z"></path>
    );
  }
  if (name === 'footprints') {
    return (
      <path d="M28.7 17.8c1.5-4.5-1.3-8.1-5.3-8.1-3.1 0-5.5 2.2-5.5 5.3 0 3 2 4.2 2 6.6 0 2.4-2.1 4.1-2.1 6.5 0 2 1.5 3.4 3.6 3.4 1.8 0 3-.9 3.7-3l3.6-10.7zM16.9 6.7c0-1.2.9-2.1 2.1-2.1s2 .9 2 2.1c0 1.1-.9 2.1-2.1 2.1s-2-1-2-2.1zm5.1 0c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5S22 7.5 22 6.7zm4.2 1.1c0-.7.6-1.3 1.4-1.3.7 0 1.3.6 1.3 1.3 0 .7-.6 1.4-1.3 1.4-.7 0-1.4-.7-1.4-1.4zm3.3 2.5c0-.7.6-1.3 1.2-1.3.7 0 1.3.6 1.3 1.3 0 .7-.6 1.2-1.3 1.2-.6.1-1.2-.5-1.2-1.2zM6.8 24.4c.7 2.1 1.9 3 3.7 3 2.1 0 3.6-1.4 3.6-3.4 0-2.4-2.1-4-2.1-6.5 0-2.4 2-3.5 2-6.6 0-3.1-2.4-5.3-5.5-5.3-4 0-6.8 3.6-5.3 8.1l3.6 10.7zm8.3-21.9c0 1.1-.9 2.1-2.1 2.1s-2-1-2-2.1c0-1.2.9-2.1 2.1-2.1s2 1 2 2.1zm-5.1 0C10 3.3 9.3 4 8.4 4c-.8 0-1.5-.7-1.5-1.5S7.6 1 8.4 1c.9 0 1.6.7 1.6 1.5zM5.8 3.6c0 .8-.7 1.4-1.4 1.4S3 4.4 3 3.6c0-.7.6-1.3 1.3-1.3s1.5.6 1.5 1.3zM2.5 6.1c0 .7-.6 1.2-1.3 1.2C.6 7.4 0 6.8 0 6.1s.6-1.3 1.3-1.3c.6 0 1.2.7 1.2 1.3z"></path>
    );
  }
  if (name === 'briefcase') {
    return (
      <path d="M1 6.8v4.5c0 1.8 1.2 3 3 3h24c1.8 0 3-1.2 3-3V6.8H1zm2 9.4c-.7 0-1.1.5-1.1 1.1v12.8c0 .7.5 1.1 1.1 1.1h26c.7 0 1.1-.5 1.1-1.1V17.3c0-.7-.5-1.1-1.1-1.1H17.4V23l-1.4.9-1.4-.9v-6.8H3zm8.3-12.6h9.4v1.8h2.8V.8h-15v4.7h2.8V3.6z"></path>
    );
  }
  if (name === 'settings') {
    return (
      <path d="M31 19.3v-6.5l-3.9-.5c-.1-.4-.3-.9-.5-1.5l2.4-3-4.7-4.7-3.1 2.4c-.4-.2-.9-.4-1.5-.6L19.2 1h-6.5l-.5 3.9c-.6.2-1.1.4-1.5.6l-3-2.4L3 7.7l2.4 3c-.2.5-.4 1.1-.5 1.5l-3.9.5v6.5l3.9.5c.2.5.3 1 .6 1.5l-2.4 3 4.7 4.7 3-2.3c.4.2 1 .5 1.5.6l.5 3.8h6.5l.5-3.8c.5-.2 1.1-.4 1.5-.6l3 2.3 4.7-4.7-2.4-3 .6-1.5 3.8-.4zm-2.1-1.9l-3.4.5c-.3 1.5-.8 2.7-1.6 3.6l2.1 2.6-1.9 2-2.6-2c-1 .7-2.5 1.3-3.7 1.5l-.5 3.3h-2.6l-.5-3.3c-1.2-.2-2.7-.8-3.7-1.5l-2.6 2-1.9-2 2.1-2.6c-.8-.9-1.2-2.1-1.6-3.6l-3.4-.5v-2.7l3.5-.5c.2-1.1.6-2.3 1.4-3.7L6 7.9l1.9-2L10.6 8c.8-.7 1.7-1.1 3.6-1.6l.5-3.4h2.6l.5 3.4c1.9.5 2.8.9 3.6 1.6l2.7-2L26 8l-2 2.5c.8 1.4 1.2 2.6 1.4 3.7l3.5.5v2.7zM16 20.8c-2.7 0-4.7-2-4.7-4.8 0-2.7 2-4.7 4.7-4.7s4.7 2 4.7 4.7-2 4.8-4.7 4.8zm0 2.2c3.9 0 6.9-3 6.9-7 0-3.9-3-6.9-6.9-6.9s-6.9 3-6.9 6.9c0 4 3 7 6.9 7z"></path>
    );
  }
  if (name === 'health-1') {
    return (
      <path d="M11.8 4.5c0-1.5-1-2.7-2.6-2.7-1 0-1.9.3-2.6 1.4C6 2.1 5.1 1.8 4.1 1.8 2.5 1.8 1.5 3 1.5 4.5c0 1.8 1.7 3.9 5.2 6.3 3.4-2.4 5.1-4.5 5.1-6.3zM1.5 14.7h8.2l3.2 9.8-1.2.1 8.1-22.9 4.5 12.9h6.3v2.6h-8.2l-3-9.4h.9l-7.9 22.3-4.7-12.8H1.5v-2.6z"></path>
    );
  }
  if (name === 'star-filled') {
    return (
      <path d="M16 25.1l-9.9 6.1 2.8-11.3L0 12.4l11.6-.9L16 .8l4.4 10.8 11.6.8-8.9 7.5 2.8 11.3-9.9-6.1z"></path>
    );
  }
  if (name === 'tree') {
    return (
      <path d="M14.2 21.9v8.6h3.6v-8.6h-3.6zM16 23c1.1 1.3 2.9 2 5.2 2 3.7 0 6.3-2.6 6.3-5.8 0-2.6-1.3-4.4-3.5-5.2 1-.8 1.4-2.1 1.4-3.3 0-2.2-2-4.2-4.8-4.1.1-3.1-1.9-5-4.5-5s-4.6 1.9-4.5 5c-2.8-.1-4.8 1.9-4.8 4.1-.1 1.2.2 2.6 1.2 3.3-2.2.7-3.5 2.6-3.5 5.2 0 3.2 2.6 5.8 6.3 5.8 2.3-.1 4.1-.7 5.2-2z"></path>
    );
  }
  if (name === 'sport-general') {
    return (
      <path d="M13 17c.8-1 2.3-2.7 4.2-4l-2.7-1.9c-.2-.2-.4-.2-.6 0L11.2 13c-.2.2-.3.3-.2.6l1.1 3.1c.1.2.3.3.5.3h.4zm-3 8.8c0-1.6.4-3.5.6-4.4L9.7 20c-.1-.2-.3-.3-.6-.3l-3.3-.5c-.3 0-.4.1-.4.3l-.1 1.4c-1.7-1.9-2.9-4.4-2.9-7.2l1.1.9c.2.2.3.2.5 0L6.1 12c.2-.2.2-.4.2-.7l-.6-3.4c0-.3-.2-.3-.5-.3L3.9 8c1.3-2.3 3.5-4.1 6-4.9l-.6 1.2c-.1.2 0 .4.2.5L12.7 6c.2.1.5.1.7 0l3-1.5c.2-.1.3-.3.2-.5l-.8-1.2c2.7.6 5 2.1 6.6 4.2L21 6.8c-.3 0-.4.1-.4.3l-.2 3.8c2.2-.5 3.5-.7 5.5-.9C24.2 4.5 19.2.8 13.6.8 6.3.7.6 6.5.6 13.5c0 5.9 4.1 10.7 9.4 12.3zm21.1-5.9c.2-1 .3-2.1.3-3.1 0-1.4-.2-2.9-.6-4.4-1.5-.4-2.9-.6-4.4-.6-1.1 0-2.1.1-3.1.3l7.8 7.8zm-10-7.2c-1.7.6-3.5 1.6-5.1 3.2-1.6 1.6-2.6 3.4-3.2 5.2l9.3 9.4c1.7-.6 3.5-1.6 5.1-3.2 1.6-1.6 2.6-3.4 3.2-5.2l-9.3-9.4zm-8.9 10.5c-.2 1-.3 2.1-.3 3.1 0 1.4.2 2.9.6 4.4 1.5.4 2.9.6 4.4.6 1.1 0 2.1-.1 3.1-.3l-7.8-7.8zm4-1c-.2-.2-.2-.3 0-.5l.5-.5-.8-.8c-.2-.2-.2-.3 0-.5s.3-.2.5 0l.8.8 1.7-1.7-.9-.6c-.2-.2-.2-.3 0-.5s.3-.2.5 0l.8.8L21 17l-.8-.8c-.2-.2-.2-.3 0-.5s.3-.2.5 0l.8.8.5-.5c.2-.2.3-.2.5 0s.2.3 0 .5l-.7.5.8.8c.2.2.2.3 0 .5s-.3.2-.5 0l-.8-.8-1.7 1.7.8.8c.2.2.2.3 0 .5s-.3.2-.5 0l-.8-.8-1.7 1.7.8.8c.2.2.2.3 0 .5s-.3.2-.5 0l-.8-.8-.5.5c.1-.1-.1-.1-.2-.2z"></path>
    );
  }
  if (name === 'game') {
    return (
      <path d="M4.5 26.7c1.8 0 2.9-.7 3.7-2.8L10 19h11.9l1.8 4.9c.8 2.1 1.9 2.8 3.7 2.8 2.1 0 3.6-1.4 3.4-4.5l-.5-9.2c-.3-4.9-3.1-7.6-7-7.6-2.8 0-5.3 1.5-6.5 3.7l2.6-1.6h-6.8L15.2 9C14 6.8 11.5 5.3 8.7 5.3c-3.9 0-6.7 2.7-7 7.6l-.5 9.2c-.2 3.2 1.2 4.6 3.3 4.6zm4.2-11.1c-2 0-3.5-1.5-3.5-3.5s1.5-3.5 3.5-3.5 3.5 1.5 3.5 3.5-1.5 3.5-3.5 3.5zm14.9.2c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1c0 .6-.5 1.1-1.1 1.1zM20.8 13c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1c.6 0 1.1.5 1.1 1.1s-.4 1.1-1.1 1.1zm5.5 0c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1c.6 0 1.1.5 1.1 1.1S27 13 26.3 13zm-2.7-2.8c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1c0 .6-.5 1.1-1.1 1.1z"></path>
    );
  }
  if (name === 'car') {
    return (
      <path d="M7.1 24.8H25v2.1c0 .6.3.9.9.9H28c.6 0 .9-.3.9-.9v-2.1h.2c.9 0 1.3-.4 1.3-1.3v-8.6c0-.9-.4-1.3-1.3-1.3h-.9L26 5.7c-.3-1.1-1-1.6-2.2-1.6H8.2C7 4.1 6.3 4.6 6 5.7l-2.2 7.8h-.9c-.9 0-1.3.4-1.3 1.3v8.6c0 .9.4 1.3 1.3 1.3h.2v2.1c0 .6.3.9.9.9h2.1c.6 0 .9-.3.9-.9v-2zm19.1-11.3H5.8l1.9-6.7c.2-.6.5-.9 1.2-.9h14.4c.6 0 1 .3 1.2.9l1.7 6.7zm-1.7 8.6c.3 0 .5.2.5.5s-.2.5-.5.5h-17c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h17zm-20.2-3v-2.9h5.4v2.9H4.3zm18 0v-2.9h5.4v2.9h-5.4z"></path>
    );
  }
  if (name === 'microphone') {
    return (
      <path d="M20.4 4.3c-.9.9-1.4 2-1.5 3.1l5.6 5.6c1.1-.1 2.3-.6 3.1-1.5 2-2 2-5.3 0-7.3-1.9-1.9-5.2-1.9-7.2.1zm-17 21.3c-.9 1-.8 1.8.1 2.7l.2.2c.9.9 1.7.9 2.7.1L9 26.3 5.7 23l-2.3 2.6zm7-.5l12.5-11-5-5-11 12.5 3.5 3.5z"></path>
    );
  }
  if (name === 'rating-sad') {
    return (
      <>
        <path d="M32.2 28.2C33.6 28.3 34.8 27.2 34.8 25.8C34.8 24.4 33.8 23.2 32.4 23.2C26.4 22.9 18.2 23.9 13.2 30.5C12.4 31.6 12.6 33.2 13.7 34C14.2 34.3 14.7 34.5 15.2 34.5C16 34.5 16.7 34.2 17.2 33.5C20.9 28.7 27.4 28 32.2 28.2Z" />
        <path d="M57.5 23.2C56.1 23.3 55.1 24.4 55.1 25.8C55.2 27.2 56.4 28.2 57.7 28.2C62.5 28 69 28.7 72.6 33.5C73.1 34.1 73.8 34.5 74.6 34.5C75.1 34.5 75.7 34.3 76.1 34C77.2 33.2 77.4 31.6 76.6 30.5C71.7 24 63.5 22.9 57.5 23.2Z" />
        <path d="M45 0C20.2 0 0 20.2 0 45C0 69.8 20.2 90 45 90C69.8 90 90 69.8 90 45C90 20.2 69.8 0 45 0ZM45 85C22.9 85 5 67.1 5 45C5 22.9 22.9 5 45 5C67.1 5 85 22.9 85 45C85 67.1 67.1 85 45 85Z" />
        <path d="M45 63.5C38.5 63.5 32.2 66.1 27.5 70.9C26.5 71.9 26.5 73.5 27.5 74.4C28.5 75.4 30.1 75.4 31 74.4C34.8 70.6 39.8 68.5 45 68.5C50.2 68.5 55.2 70.6 59 74.4C59.5 74.9 60.1 75.1 60.8 75.1C61.4 75.1 62.1 74.9 62.6 74.4C63.6 73.4 63.6 71.8 62.6 70.9C57.8 66.1 51.5 63.5 45 63.5Z" />
        <path d="M28 50.7C31.148 50.7 33.7 48.148 33.7 45C33.7 41.852 31.148 39.3 28 39.3C24.852 39.3 22.3 41.852 22.3 45C22.3 48.148 24.852 50.7 28 50.7Z" />
        <path d="M62 50.7C65.148 50.7 67.7 48.148 67.7 45C67.7 41.852 65.148 39.3 62 39.3C58.852 39.3 56.3 41.852 56.3 45C56.3 48.148 58.852 50.7 62 50.7Z" />
      </>
    );
  }
  if (name === 'rating-neutral') {
    return (
      <>
        <path d="M45 0C20.2 0 0 20.2 0 45C0 69.8 20.2 90 45 90C69.8 90 90 69.8 90 45C90 20.2 69.8 0 45 0ZM45 85C22.9 85 5 67.1 5 45C5 22.9 22.9 5 45 5C67.1 5 85 22.9 85 45C85 67.1 67.1 85 45 85Z" />
        <path d="M62.8 42.3C65.948 42.3 68.5 39.748 68.5 36.6C68.5 33.452 65.948 30.9 62.8 30.9C59.652 30.9 57.1 33.452 57.1 36.6C57.1 39.748 59.652 42.3 62.8 42.3Z" />
        <path d="M27.2 42.3C30.348 42.3 32.9 39.748 32.9 36.6C32.9 33.452 30.348 30.9 27.2 30.9C24.052 30.9 21.5 33.452 21.5 36.6C21.5 39.748 24.052 42.3 27.2 42.3Z" />
        <path d="M62.8 55.3H27.2C25.8 55.3 24.7 56.4 24.7 57.8C24.7 59.2 25.8 60.3 27.2 60.3H62.7C64.1 60.3 65.2 59.2 65.2 57.8C65.2 56.4 64.1 55.3 62.8 55.3Z" />
      </>
    );
  }
  if (name === 'rating-happy') {
    return (
      <>
        <path d="M45 0C20.2 0 0 20.2 0 45C0 69.8 20.2 90 45 90C69.8 90 90 69.8 90 45C90 20.2 69.8 0 45 0ZM45 85C22.9 85 5 67.1 5 45C5 22.9 22.9 5 45 5C67.1 5 85 22.9 85 45C85 67.1 67.1 85 45 85Z" />
        <path d="M62.8 42.3C65.948 42.3 68.5 39.748 68.5 36.6C68.5 33.452 65.948 30.9 62.8 30.9C59.652 30.9 57.1 33.452 57.1 36.6C57.1 39.748 59.652 42.3 62.8 42.3Z" />
        <path d="M27.2 42.3C30.348 42.3 32.9 39.748 32.9 36.6C32.9 33.452 30.348 30.9 27.2 30.9C24.052 30.9 21.5 33.452 21.5 36.6C21.5 39.748 24.052 42.3 27.2 42.3Z" />
        <path d="M61.1 54C52.1 62 37.9 62 28.9 54C27.9 53.1 26.3 53.2 25.4 54.2C24.5 55.2 24.6 56.8 25.6 57.7C31 62.6 38 65 45 65C52 65 59 62.6 64.4 57.7C65.4 56.8 65.5 55.2 64.6 54.2C63.7 53.2 62.1 53.1 61.1 54Z" />
      </>
    );
  }
  if (name === 'applause') {
    return (
      <>
        <g>
          <g>
            <path d="M30,21l-5.4-5.4c-0.9-0.9-2.4-1-3.4,0c-0.4,0.4-0.6,0.9-0.7,1.4l11.7,11.7C31.3,26.2,30.3,23.4,30,21z" />
            <path
              d="M49.4,47c0.8-5-0.9-12.5-6.8-18.5c-1.1-1.1-2.6-3.8-4-6.2l0,0c-1.1-2-2-3.8-2.2-4.3c-0.4-1-1.5-1.7-2.4-1.7
            c-4.8,0.2,0.1,12.5,2.1,16.2c0.2,0.4,0.1,1-0.3,1.3c-0.4,0.3-1,0.3-1.3-0.1L18.8,18.1c0,0,0,0-0.1-0.1l-2.5-2.5
            c-0.9-0.9-2.4-1-3.4,0c-0.5,0.5-0.7,1.1-0.7,1.7s0.2,1.2,0.7,1.7l12.3,12.3c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0L11.5,20.4L9,17.9
            c-1-1-2.6-0.9-3.5,0.1c-0.8,0.9-0.7,2.5,0.3,3.4l3.7,3.7l0,0l0,0l0,0l11,11c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0l-11-11
            c-1-0.9-2.6-0.9-3.5,0.1c-0.8,0.9-0.7,2.5,0.3,3.4l5,5l0,0l0,0l0,0l5.9,5.9c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0l-5.9-5.9l0,0
            c-0.4-0.4-1-0.7-1.7-0.7c-0.6,0-1.2,0.2-1.7,0.7s-0.7,1.1-0.7,1.7c0,0.6,0.2,1.2,0.7,1.7c7,7,7.7,7.6,9.3,9.6
            c2.4,3.1,10.8,13.4,17.5,13.8c0.7,0,1.3-0.2,1.8-0.7l15.1-14.1C49,48,49.3,47.6,49.4,47z"
            />
            <path
              d="M44.7,18.1c-0.4-1-1.4-1.7-2.4-1.7c-1.1,0-2.7,1.3-1.9,5c1.2,2.3,2.7,4.8,3.6,5.7c6.1,6.2,8.2,14.2,7.3,20.2
            c-0.1,1-0.6,1.9-1.4,2.6L36.6,62.3c1.7,0.8,3.9,1.4,5.1,0.2l15.1-14.1c0.4-0.4,0.7-0.9,0.8-1.4c0.8-5.3-1.1-12.7-6.8-18.5
            C48.9,26.6,45.2,19.3,44.7,18.1z"
            />
          </g>
          <g>
            <g>
              <path
                d="M20.1,13.3c-0.4-0.3-0.5-1-0.2-1.4l3.9-5.1c0.3-0.4,1-0.5,1.4-0.2s0.5,1,0.2,1.4l-3.9,5.1C21.2,13.5,20.5,13.6,20.1,13.3
              z"
              />
            </g>
            <g>
              <path
                d="M14,11.1l-1-7.2c-0.1-0.5,0.3-1.1,0.9-1.1C14.5,2.7,15,3.1,15,3.6l1,7.2c0.1,0.5-0.3,1.1-0.9,1.1
              C14.6,12.1,14.1,11.7,14,11.1z"
              />
            </g>
            <g>
              <path d="M9.3,14.8L2.9,9.9c-0.4-0.3-0.5-1-0.2-1.4s1-0.5,1.4-0.2l6.4,4.9c0.4,0.3,0.5,1,0.2,1.4S9.8,15.1,9.3,14.8z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M60.5,23.4H54c-0.6,0-1-0.4-1-1s0.4-1,1-1h6.4c0.6,0,1,0.4,1,1S61,23.4,60.5,23.4z" />
            </g>
            <g>
              <path d="M51.1,17.7c-0.4-0.4-0.4-1,0-1.4l5.1-5.1c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-5.1,5.1C52.1,18.1,51.5,18.1,51.1,17.7z" />
            </g>
            <g>
              <path d="M46.3,15.8c-0.6,0-1-0.4-1-1v-8c0-0.6,0.4-1,1-1s1,0.4,1,1v8C47.3,15.3,46.9,15.8,46.3,15.8z" />
            </g>
          </g>
        </g>
      </>
    );
  }
  if (name === 'electronics') {
    return (
      <polygon points="43.5,21.8 59.3,3.1 44.8,3 21,31.3 33.8,31.4 18,50.2 30.8,50.2 10.8,74 52.1,42.1 39.3,42.1 56.3,21.9 " />
    );
  }
  if (name === 'military') {
    return (
      <>
        <polygon points="59.1,44.8 33,59.7 7,44.8 7,59.7 33,74.5 59.1,59.7 " />
        <path d="M34,4.6l5.6,11.3c0.2,0.3,0.5,0.5,0.8,0.6l12.5,1.8c0.9,0.1,1.3,1.2,0.6,1.9l-9,8.8c-0.3,0.3-0.4,0.6-0.3,1l2.1,12.5 c0.2,0.9-0.8,1.6-1.6,1.2l-11.2-5.9c-0.3-0.2-0.7-0.2-1,0l-11.2,5.9c-0.8,0.4-1.7-0.3-1.6-1.2L21.9,30c0.1-0.4-0.1-0.7-0.3-1 l-9.1-8.8c-0.7-0.6-0.3-1.7,0.6-1.9l12.5-1.8c0.4-0.1,0.7-0.3,0.8-0.6L32,4.6C32.4,3.7,33.6,3.7,34,4.6z" />
      </>
    );
  }
  if (name === 'bird') {
    return (
      <path d="M57.9,22.8c-0.8-5.5-5.5-9.8-11.2-9.8c-3.3,0-6,1.3-8.3,3.6L32,23L10,1c-3.7,3.7-5.3,24,7.3,36.7L0,55h20.7 C38.9,55,54,44.3,57.3,30.1l8.7-3.8L57.9,22.8z M48,27c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7S49.5,27,48,27z" />
    );
  }
  if (name === 'music-track') {
    return (
      <path d="M16 31c8.5 0 15-6.5 15-15S24.5 1 16 1 1 7.5 1 16s6.5 15 15 15zm0-9c-3.4 0-6-2.6-6-6s2.6-6 6-6 6 2.6 6 6-2.6 6-6 6zm0-4.5c.9 0 1.5-.6 1.5-1.5s-.6-1.5-1.5-1.5-1.5.6-1.5 1.5.6 1.5 1.5 1.5z" />
    );
  }
  if (name === 'record') {
    return (
      <path d="M16 31c8.5 0 15-6.5 15-15S24.5 1 16 1 1 7.5 1 16s6.5 15 15 15zm-6.2-7V7.9H16c4.5 0 6.9 2 6.9 5.3 0 2.1-.9 3.5-2.6 4.5 1.4 2 2.6 4.1 3.8 6.3h-4.3c-.9-1.8-1.9-3.6-3-5.4h-3.3V24H9.8zm3.7-8.3h2.3c2.1 0 3.2-.8 3.2-2.5s-1.1-2.5-3.2-2.5h-2.3v5z" />
    );
  }
  if (name === 'warning') {
    return (
      <path d="M3.1 30.2h25.8c1.6 0 2.3-.8 2.3-1.9 0-.8-.3-1.4-1-2.9L18.6 3.8c-.8-1.5-1.5-2-2.6-2s-1.8.5-2.6 2L1.8 25.4C1 26.9.7 27.5.7 28.3c.1 1.1.8 1.9 2.4 1.9zM16 26.9c-1.6 0-2.4-1-2.4-2.1 0-1.2.8-2.1 2.4-2.1s2.4 1 2.4 2.1-.8 2.1-2.4 2.1zm-1.7-6.7l-.8-10.6h5l-.8 10.6h-3.4z" />
    );
  }
  if (name === 'series-catch-up') {
    return (
      <path d="M10.9 26H31V6H10.9v20zm2.7-17.4h14.7v14.8H13.6V8.6zm4 12.5V10.9l8.1 5.1-8.1 5.1zM6 6v20h2.4V6H6zM1 6v20h2.4V6H1z" />
    );
  }
  if (name === 'continuous-play') {
    return (
      <path d="M2.7 29.2h16.9V12.4H2.7v16.8zm4.5-2.8V15.1l9 5.6-9 5.7zm-1.7-18h18.1v18.2H26V6H5.5v2.4zM1 31h20.1V10.9H1V31zm2.7-17.5h14.7v14.8H3.7V13.5zM9.8 3.4h18.8v18.9H31V1H9.8v2.4z" />
    );
  }
  if (name === 'continuous-play-off') {
    return (
      <>
        <path
          d="M17.8,12.3H36.6V31.1H39V9.9H17.8Zm-6.1,10H26.4V37.2H11.7ZM9,39.8H29.1V19.7H9Zm4.5-22.6H31.6V35.4H34V14.8H13.5ZM10.6,38.1H27.5V21.4H10.6Z"
          transform="translate(-9 -9.9)"
        />
        <path
          style={{ fill: '#333E48' }}
          d="M14.1,23.5l-1.3,1.3L24,36l1.2-1.3Zm11.1,1.2L24,23.5,12.8,34.8,14.1,36Z"
          transform="translate(-9 -9.9)"
        />
      </>
    );
  }
};

function GelIcon({ className, fill, name, viewBox }) {
  return (
    <svg className={className} viewBox={viewBox} style={{ fill }}>
      {getIcon(name)}
    </svg>
  );
}

GelIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  viewBox: PropTypes.string,
  name: PropTypes.string.isRequired,
};

GelIcon.defaultProps = {
  className: 'w-6 h-6',
  fill: 'black',
  viewBox: '0 0 32 32',
};

export default GelIcon;
