import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import modifyExistingQuerystring from '../../utilities/modifyExistingQuerystring';
import { AppContext } from '../../Context/AppContext';
import { THEME_COLOURS } from '../../constants';
import { NEW_CATEGORY_LIST, getParents } from '../../category';
import GelIcon from '../GelIcon';
import FileMetadata from '../FileMetadata';
import { SET_MIXER_OPEN } from '../../Context/actionTypes';
import ProSoundInfoModal from './ProSoundInfoModal';
import { RegistUse } from '../../Route/Route';
import { useTranslation } from 'react-i18next';

function MoreDetails({ history, asset, mixerVersion }) {
  const { echoTrack, dispatch, openMss } = useContext(AppContext);
  const { t } = useTranslation();

  const [showProSoundInfoModal, setShowProSoundInfoModal] = useState(false);

  const handleCategoryClick = (category) => {
    echoTrack(
      'audioplayer',
      { action: 'category_clicked', data: { assetId: asset.id, category } },
      'click'
    );

    if (mixerVersion) {
      dispatch({ type: SET_MIXER_OPEN, value: false });
    }

    const parentCats = getParents(+category);
    history.push(
      `/search?${modifyExistingQuerystring(
        'cat',
        parentCats[0].id.toString(),
        modifyExistingQuerystring(
          'durations',
          parentCats.length > 1 ? parentCats[1].id.toString() : [],
          modifyExistingQuerystring(
            'continents',
            parentCats.length > 2 ? parentCats[2].id.toString() : []
          )
        )
      )}`
    );
  };

  const handleRegistUseClick = () => {
    const wrapper = async () => {
      const res = await RegistUse({ sfxId: asset.id });
      if (res.status === 200) {
        openMss(t('registUse.success_regist'));
      } else {
        console.log(res.data.message);
        if (res.data.message === 'Unauthorized') {
          openMss(t('registUse.please_login'));
        } else if (res.data.message === 'already registed!') {
          openMss(t('registUse.already_registed'));
        } else {
          openMss(t('registUse.fail_regist'));
        }
      }
    };
    wrapper();
  };

  // const getProsoundUrl = (description) => {
  /*
    const descriptionParameter = encodeURI(
      description.replace(/[A-Za-z0-9\s&]*?\s-\s/, "")
    ).replace("&", "%26");
    return `https://download.prosoundeffects.com/#!explorer?s=${descriptionParameter}&custom_17%5B%5D=BBC%20Complete`;
    */
  //   return '';
  // };
  const allCat = NEW_CATEGORY_LIST.concat(
    NEW_CATEGORY_LIST.flatMap((x) => x.sub),
    NEW_CATEGORY_LIST.flatMap((x) => x.sub).flatMap((x) => x.sub)
  );
  const categories = asset.categories
    .map((x) => allCat.find((cat) => cat.id === x))
    .filter((x) => x !== undefined);
  return (
    <div
      className={`flex flex-col items-center py-4 border-t border-gray-400 md:flex-row ${
        mixerVersion ? 'px-6 md:px-0' : 'px-6 lg:px-0'
      }`}
    >
      <FileMetadata placement="block mb-2 md:hidden" asset={asset} />
      <div className="flex flex-col justify-between w-full md:flex-row">
        <div className="w-full md:w-1/3 lg:w-1/2">
          {categories.length > 0 &&
            categories.map((cat) => {
              return (
                <button
                  type="button"
                  key={cat.id}
                  onClick={() => handleCategoryClick(cat.id.toString())}
                  className={`px-5 py-1 mb-1 mr-2 text-xs font-bold border rounded-full focus:outline-none ${
                    mixerVersion
                      ? 'bg-gray-900 border-white hover:bg-white hover:text-gray-900 focus:bg-white focus:text-gray-900'
                      : 'bg-white border-teal-700 text-teal-700 hover:bg-teal-700 hover:text-white focus:bg-teal-700 focus:text-white'
                  }`}
                >
                  {cat.name}
                </button>
              );
            })}
        </div>
        <div className="flex w-full md:w-2/3 lg:w-1/2 md:flex-row">
          <FileMetadata
            placement="hidden md:block"
            asset={asset}
            mixerVersion={mixerVersion}
          />
          <div className="w-full">
            <div className="flex flex-row justify-end">
              <a
                onClick={handleRegistUseClick}
                target="_blank"
                rel="noopener noreferrer"
                className={`flex flex-row items-center justify-between h-10 p-2 text-sm font-semibold border ${
                  mixerVersion
                    ? 'text-white border-white'
                    : 'text-blue-800 border-blue-800'
                } focus:outline-none focus:bg-gray-200 hover:bg-gray-600`}
              >
                <GelIcon
                  name="external-link"
                  fill={mixerVersion ? 'white' : THEME_COLOURS.TERTIARY}
                  className="w-4 h-4 mr-2"
                />
                <span>Regist Use</span>
              </a>
            </div>
            <div className="flex flex-row justify-end mt-2">
              <button
                type="button"
                onClick={() => setShowProSoundInfoModal(true)}
                className={`flex flex-row items-center justify-between text-sm ${
                  mixerVersion ? 'text-white' : 'text-blue-800'
                } font-bold focus:outline-none hover:underline focus:underline`}
              >
                <GelIcon
                  name="help"
                  fill={mixerVersion ? 'white' : THEME_COLOURS.TERTIARY}
                  className="w-4 h-5 mr-2"
                />
                <span>What&apos;s this?</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {showProSoundInfoModal && (
        <ProSoundInfoModal
          setShowProSoundInfoModal={setShowProSoundInfoModal}
        />
      )}
    </div>
  );
}

MoreDetails.propTypes = {
  asset: PropTypes.object,
  history: PropTypes.object,
  mixerVersion: PropTypes.bool,
};

export default withRouter(MoreDetails);
