import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GelIcon from '../GelIcon';
import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import { THEME_COLOURS } from '../../constants';

function MixerStickyBar() {
  const { echoTrack } = useContext(AppContext);
  const {
    state: {
      mixerPlaying,
      mixerAssets,
      mixerOpen,
      mixerModeOn,
      mixImporting,
      playingMixerAssets,
      readyMixerAssets,
      audioCtx,
    },
    setMixerPlaying,
    setMixerOpen,
  } = useContext(MixerContext);

  const { t } = useTranslation();
  const [assetAdded, setAssetAdded] = useState(false);
  const [playDisabled, setPlayDisabled] = useState(
    Object.keys(mixerAssets).length === 0 ||
      readyMixerAssets !== Object.keys(mixerAssets).length
  );

  useEffect(() => {
    if (playDisabled) {
      setMixerPlaying(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playDisabled]);

  useEffect(() => {
    if (Object.keys(mixerAssets).length === 0) return;
    setAssetAdded(true);

    const timeout = setTimeout(() => setAssetAdded(false), 150);

    return () => clearTimeout(timeout);
  }, [mixerAssets]);

  useEffect(() => {
    if (mixerPlaying && playingMixerAssets === 0) {
      setMixerPlaying(false);
    }

    if (
      !mixerPlaying &&
      playingMixerAssets > 0 &&
      playingMixerAssets === Object.keys(mixerAssets).length
    ) {
      setMixerPlaying(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingMixerAssets]);

  useEffect(() => {
    setPlayDisabled(
      Object.keys(mixerAssets).length === 0 ||
        readyMixerAssets < Object.keys(mixerAssets).length
    );
  }, [mixerAssets, readyMixerAssets]);

  const togglePlaying = () => {
    echoTrack('mixer', { action: 'mixer_sticky_bar_playall' }, 'click');

    // Mixer uses an audio context which may need to be initialised after a user action
    if (audioCtx.state === 'suspended') {
      audioCtx.resume();
    }

    setMixerPlaying(!mixerPlaying);
  };

  const openMixer = () => {
    echoTrack('mixer', { action: 'mixer_sticky_bar_open' }, 'click');
    setMixerOpen(true);
  };

  return (
    <div
      className="fixed left-0 right-0 z-30 w-full h-32 text-white bg-gray-900 md:h-24"
      style={{
        bottom: mixerModeOn && !mixerOpen ? 0 : -140,
        visibility: mixerModeOn ? 'visible' : 'hidden',
      }}
    >
      <div className="flex flex-col items-center justify-between w-full h-full max-w-screen-xl mx-auto md:flex-row md:px-3">
        <div className="flex flex-row-reverse items-center justify-between w-full h-full px-6 py-4 md:py-0 md:px-0 md:w-auto md:h-auto md:justify-center md:space-x-10 md:flex-row">
          <button
            type="button"
            className={`focus:outline-none flex flex-row-reverse items-center md:space-y-2 md:flex-col hover:opacity-75 focus:opacity-75 md:w-16 ${
              playDisabled ? 'cursor-not-allowed' : ''
            }`}
            onClick={togglePlaying}
            disabled={playDisabled}
          >
            <GelIcon
              className="w-6 h-6"
              name={mixerPlaying ? 'pause' : 'play'}
              fill={playDisabled ? THEME_COLOURS.DARK_GREY : 'white'}
            />
            <span className="mr-4 font-semibold md:text-sm md:mr-0">{`${
              mixerPlaying ? t('pause_all') : t('play_all')
            }`}</span>
          </button>
          <button
            aria-label={`${
              Object.keys(mixerAssets).length
            } sounds added to mixer - View mix`}
            type="button"
            className={`flex flex-row items-center py-2 font-semibold rounded-md md:text-base md:px-10 md:bg-white md:text-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75 transform duration-150 ease-in-out ${
              assetAdded ? 'scale-105' : 'scale-100'
            }`}
            onClick={() => setMixerOpen(true)}
          >
            {mixImporting ? (
              <>
                <span className="pr-2">Importing</span>
                <GelIcon
                  name="loading"
                  fill="white"
                  className="w-5 h-5 mx-auto animate-spin"
                />
              </>
            ) : (
              <span>{`${Object.keys(mixerAssets).length} ${t(
                'sounds_added_to_mixer'
              )}`}</span>
            )}
          </button>
        </div>
        <button
          aria-label="View mix"
          type="button"
          className="hidden px-16 py-2 font-semibold text-gray-900 bg-white focus:outline-none md:block hover:opacity-75 focus:opacity-75"
          onClick={openMixer}
        >
          {t('view_mix')}
        </button>
        <div className="flex flex-row justify-between w-full h-full md:hidden">
          {/* <button
            type="button"
            className="w-full py-2 font-semibold focus:outline-none hover:opacity-75 focus:opacity-75"
          >
            Hide Player
          </button> */}
          <button
            aria-label="View mix"
            type="button"
            className="w-full py-2 font-semibold bg-teal-700 focus:outline-none hover:opacity-75 focus:opacity-75"
            onClick={openMixer}
          >
            {t('view_mix')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MixerStickyBar;
