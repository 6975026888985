import React from 'react';
import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

function MenuLinks() {
  // const { t } = useTranslation();
  return (
    <nav className="flex flex-col space-y-6 text-2xl font-bold">
      <Link
        to="/search"
        className="outline-none hover:underline focus:underline"
      >
        Browse Sound Effects
      </Link>
      <Link
        to="/favourites"
        className="outline-none hover:underline focus:underline"
      >
        My Favourites
      </Link>
      <Link
        to="/terms"
        className="outline-none hover:underline focus:underline"
      >
        Licences &amp; Usage
      </Link>
      <Link
        to="/about"
        className="outline-none hover:underline focus:underline"
      >
        About DIGWIX Sound Effects
      </Link>
      <Link
        to="/cookies"
        className="outline-none hover:underline focus:underline"
      >
        Cookie Information
      </Link>
      <Link to="/faqs" className="outline-none hover:underline focus:underline">
        FAQs
      </Link>
      <Link
        to="/feedback"
        className="outline-none hover:underline focus:underline"
      >
        Submit Feedback
      </Link>
    </nav>
  );
}

export default MenuLinks;
