/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactFocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Cookie from 'js-cookie';
import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import MobileFilter from '../MobileFilter';
import WideFilter from '../WideFilter';
import GelIcon from '../GelIcon';
import MenuLinks from '../Navbar/MenuLinks';
import SearchInput from '../SearchInput';
import {
  COOKIE_FAVOURITES_BANNER_ACCEPTED,
  THEME_COLOURS,
  RESULT_SIZE_CONFIG,
  LOCALSTORAGE_ACCEPT_FAVOURITES_LIMIT,
} from '../../constants';
import LimitInfoModal from '../../Pages/Favourites/LimitInfoModal';
import getQuerystring from '../../utilities/getQuerystring';

function SearchBar({ history }) {
  // Store a reference to the page the user is on
  const pageContext = history.location.pathname;

  const parsedQuerystring = getQuerystring();

  const enableSearchFilter = pageContext
    ? pageContext === '/search' || pageContext.includes('/favourites')
    : false;

  const {
    state: { resultsLength },
    getAggregations,
    getCachedCategoryAggregations,
    setSelectedAsset,
    echoTrack,
  } = useContext(AppContext);
  const {
    state: { mixerModeOn },
  } = useContext(MixerContext);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [searchMenuOpen, setSearchMenuOpen] = useState(false);
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);
  const [showSearchHelpModal, setShowSearchHelpModal] = useState(false);
  const [showLimitInfoModal, setShowLimitInfoModal] = useState(
    !JSON.parse(
      window.localStorage.getItem(LOCALSTORAGE_ACCEPT_FAVOURITES_LIMIT)
    )
  );
  const [showFavouritesStorageBanner, setShowFavouritesStorageBanner] =
    useState(Cookie.get(COOKIE_FAVOURITES_BANNER_ACCEPTED) ? false : true);
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const { t } = useTranslation();

  const closeAllMenus = () => {
    setSearchMenuOpen(false);
    setUserMenuOpen(false);
    setUserDrawerOpen(false);

    // Reset scroll lock
    document.documentElement.style.position = '';
    document.documentElement.style.width = '';
  };

  // const handleUserMenuClick = () => {
  //   echoTrack(
  //     pageContext.substring(1),
  //     { action: 'user_menu_toggled' },
  //     'click'
  //   );

  //   setSearchMenuOpen(false);
  //   setUserMenuOpen(!userMenuOpen);

  //   // Prevent page scrolling when menu open on mobile
  //   document.documentElement.style.position = !userMenuOpen ? 'fixed' : '';
  //   document.documentElement.style.width = !userMenuOpen ? '100%' : '';
  // };

  // const handleSearchMenuClick = () => {
  //   echoTrack(
  //     pageContext.substring(1),
  //     { action: 'search_menu_toggled' },
  //     'click'
  //   );

  //   setUserMenuOpen(false);
  //   setSearchMenuOpen(!searchMenuOpen);

  //   // Reset scroll lock
  //   document.documentElement.style.position = '';
  //   document.documentElement.style.width = '';
  // };

  // const handleUserDrawerClick = () => {
  //   echoTrack(
  //     pageContext.substring(1),
  //     { action: 'user_menu_toggled' },
  //     'click'
  //   );
  //   setUserDrawerOpen(!userDrawerOpen);
  // };

  const handleLimitBannerDismiss = () => {
    Cookie.set(COOKIE_FAVOURITES_BANNER_ACCEPTED, true, { expires: 30 });
    setShowFavouritesStorageBanner(false);
  };

  // Listen to route changes (typically when a search filter is applied or new search is performed)
  // Close all the menus when the route changes
  // Update the category aggregations
  // Clear selected asset
  useEffect(
    () =>
      history.listen((location) => {
        closeAllMenus();
        setSelectedAsset(null);
        if (['/search', '/favourites'].includes(location.pathname)) {
          getAggregations(location.pathname);
        }

        if (location.pathname === '/') {
          getCachedCategoryAggregations();
        }

        if (location.pathname === '/favourites') {
          setShowLimitInfoModal(
            !JSON.parse(
              window.localStorage.getItem(LOCALSTORAGE_ACCEPT_FAVOURITES_LIMIT)
            )
          );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    [history]
  );

  // On mount get the category aggregations
  useEffect(() => {
    if (['/search', '/favourites'].includes(pageContext)) {
      getAggregations(pageContext);
    }

    if (pageContext === '/') {
      getCachedCategoryAggregations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showSearchHelpModal) {
      document.documentElement.style.position = 'fixed';
      document.documentElement.style.width = '100%';
    } else {
      document.documentElement.style.position = '';
      document.documentElement.style.width = '';
    }
  }, [showSearchHelpModal]);
  if (pageContext !== '/search' && pageContext !== '/favourites') {
    return <></>;
  } else
    return (
      <>
        <div
          className={`${
            mixerModeOn ? 'bg-gray-700' : 'bg-gray-100'
          } transition-all duration-200 ease-in-out`}
        >
          <div className="max-w-screen-xl px-0 mx-auto md:px-3">
            <div className="flex flex-row items-center justify-between py-0 md:h-24 md:py-6">
              <div
                className="px-3 mr-4 text-xl text-gray-900 sm:text-2xl md:text-3xl hover:text-gray-600 md:px-0"
                title={`${process.env.REACT_APP_NAME} v${process.env.REACT_APP_VERSION}`}
              >
                <h1>
                  <Link
                    to="/"
                    className="font-semibold tracking-tight text-gray-900 outline-none hover:opacity-75 focus:opacity-75"
                    onFocus={() => setSearchIsFocused(false)}
                    rel="up"
                  >
                    SOUND EFFECTS
                  </Link>
                </h1>
              </div>

              <div className="relative flex-row items-center flex-grow hidden md:flex">
                <SearchInput
                  searchIsFocused={searchIsFocused}
                  setSearchIsFocused={setSearchIsFocused}
                />
              </div>

              {/* <div className="flex justify-end">
                <button
                  type="button"
                  title="Open search"
                  className={`p-4 hover:bg-gray-300 focus:bg-gray-300 rounded-r-sm focus:outline-none md:hidden${
                    searchMenuOpen ? " bg-gray-900 text-white" : ""
                  }`}
                  onClick={handleSearchMenuClick}
                  aria-label={searchMenuOpen ? "Close search" : "Open search"}
                >
                  <GelIcon
                    name={searchMenuOpen ? "close" : "search"}
                    fill={searchMenuOpen ? "white" : THEME_COLOURS.SECONDARY}
                  />
                </button>
                <button
                  type="button"
                  title="Open menu"
                  className={`p-4 hover:bg-gray-300 focus:bg-gray-300 rounded-md focus:outline-none md:hidden${
                    userMenuOpen ? " bg-gray-900 text-white" : ""
                  }`}
                  onClick={handleUserMenuClick}
                  aria-label={
                    userMenuOpen ? "Close user menu" : "Open user menu"
                  }
                >
                  <GelIcon
                    name={userMenuOpen ? "close" : "list-view-text"}
                    fill={userMenuOpen ? "white" : THEME_COLOURS.SECONDARY}
                  />
                </button>
                <button
                  type="button"
                  title="Show search help"
                  className="hidden p-3 ml-2 rounded-md md:block focus:outline-none hover:bg-gray-300 focus:bg-gray-300"
                  onClick={() => setShowSearchHelpModal(!showSearchHelpModal)}
                  aria-label="Show search help"
                >
                  <GelIcon name="help" fill={THEME_COLOURS.TERTIARY} />
                </button>
                {showSearchHelpModal && (
                  <SearchHelpModal
                    setShowSearchHelpModal={setShowSearchHelpModal}
                  />
                )}
                <button
                  type="button"
                  title="Open menu drawer"
                  className="hidden p-3 rounded-md focus:outline-none md:block hover:bg-gray-300 focus:bg-gray-300"
                  onClick={handleUserDrawerClick}
                  aria-label={
                    userMenuOpen ? "Close user menu" : "Open user menu"
                  }
                >
                  <GelIcon
                    name={userMenuOpen ? "close" : "list-view-text"}
                    fill={THEME_COLOURS.SECONDARY}
                  />
                </button>
              </div> */}
            </div>
            <div
              className={`bg-gray-900 pt-4 pl-4 pr-4 pb-2 md:hidden ${
                searchMenuOpen ? 'block' : 'hidden'
              }`}
            >
              <div className="relative flex items-center justify-between">
                <SearchInput
                  searchIsFocused={searchIsFocused}
                  setSearchIsFocused={setSearchIsFocused}
                  hidePlaceholder
                />
              </div>
              <div className="flex justify-end mt-1">
                <button
                  type="button"
                  className="pt-2 pb-1 font-semibold text-white"
                  onClick={() => setShowSearchHelpModal(true)}
                >
                  Search Help
                </button>
              </div>
            </div>
            {userMenuOpen && (
              <div className="absolute left-0 z-50 w-full h-screen px-3 py-6 text-gray-300 bg-gray-900 md:hidden">
                <MenuLinks />
              </div>
            )}
            {userDrawerOpen && (
              <>
                <button
                  type="button"
                  tabIndex="-1"
                  className="fixed top-0 left-0 z-40 w-full h-full bg-gray-900 opacity-75 focus:outline-none"
                  onClick={() => setUserDrawerOpen(false)}
                />
              </>
            )}
            {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
            <ReactFocusLock disabled={!userDrawerOpen} autoFocus={false}>
              <div
                className="fixed top-0 right-0 z-50 flex h-full transition-all duration-500 ease-in-out bg-white opacity-100"
                style={{
                  right: userDrawerOpen ? 0 : -700,
                  visibility: userDrawerOpen ? 'visible' : 'hidden',
                }}
              >
                <button
                  type="button"
                  title="Close menu drawer"
                  className="absolute p-6 -ml-20 bg-white focus:outline-none hover:bg-gray-200 focus:bg-gray-200"
                  onClick={() => setUserDrawerOpen(false)}
                  aria-label="Close user menu"
                >
                  <GelIcon
                    className="w-8 h-8"
                    name="close"
                    fill={THEME_COLOURS.SECONDARY}
                  />
                </button>
                <div className="p-8">
                  <MenuLinks />
                </div>
              </div>
            </ReactFocusLock>
          </div>
        </div>
        {enableSearchFilter && (
          <>
            {pageContext === '/favourites' && showFavouritesStorageBanner && (
              <>
                <div
                  className={`${
                    mixerModeOn ? 'bg-gray-700' : 'bg-gray-100'
                  } transition-all duration-200 ease-in-out`}
                >
                  <div className="flex flex-row items-center max-w-screen-xl px-3 mx-auto">
                    <div
                      className={`flex flex-row items-center justify-between w-full md:px-4 pl-2 mx-auto align-middle ${
                        mixerModeOn ? 'bg-gray-200' : 'bg-gray-700'
                      }`}
                    >
                      <div className="flex flex-row items-center justify-between py-2 md:py-4">
                        <GelIcon
                          name="info"
                          className="w-5 h-5"
                          fill={THEME_COLOURS.PRIMARY}
                        />
                        <div className="flex flex-col items-start pl-2 text-sm md:flex-row md:text-lg">
                          <p className="md:hidden">
                            Your favourites are stored locally.
                          </p>
                          <p className="hidden md:block">
                            {t('favourites_localstorage_alert')}&nbsp;
                          </p>
                          <button
                            type="button"
                            className="font-semibold outline-none hover:underline focus:underline"
                            onClick={() => setShowLimitInfoModal(true)}
                          >
                            {t('tell_me_more')}
                          </button>
                        </div>
                      </div>
                      {showLimitInfoModal && (
                        <LimitInfoModal
                          setShowLimitInfoModal={setShowLimitInfoModal}
                        />
                      )}
                      <button
                        type="button"
                        className="focus:outline-none hover:bg-gray-200 focus:bg-gray-200"
                        onClick={handleLimitBannerDismiss}
                        aria-label="Close banner"
                        title="Close banner"
                      >
                        <GelIcon
                          name="close"
                          fill={THEME_COLOURS.PRIMARY}
                          className="w-8 h-8 p-2"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              className={`${
                mixerModeOn ? 'bg-gray-700' : 'bg-gray-100'
              } transition-all duration-200 ease-in-out border-gray-400 border-top md:hidden`}
            >
              <h2 className="px-3 py-4 text-lg text-gray-900 md:px-0 md:mx-3">
                {pageContext === '/search' ? (
                  <span>
                    Showing&nbsp;
                    {RESULT_SIZE_CONFIG.sizeLimit < resultsLength && (
                      <span>
                        top <strong>{RESULT_SIZE_CONFIG.sizeLimit}</strong>{' '}
                        of&nbsp;
                      </span>
                    )}
                    <strong>{resultsLength}</strong>
                    {parsedQuerystring.q ? (
                      <span>
                        {' '}
                        results for <strong>{parsedQuerystring.q}</strong>
                      </span>
                    ) : (
                      <span> results</span>
                    )}
                  </span>
                ) : (
                  <span>
                    Your Favourites <strong>({resultsLength})</strong>
                  </span>
                )}
              </h2>
              <MobileFilter />
            </div>
            <div className="hidden md:w-full md:block">
              <WideFilter />
            </div>
          </>
        )}
        {searchIsFocused && (
          <>
            <button
              type="button"
              className="fixed top-0 left-0 w-full h-24 focus:outline-none md:h-40 z-2 md:block"
              onClick={() => setSearchIsFocused(false)}
              onFocus={() => setSearchIsFocused(false)}
            />
            <button
              type="button"
              className="fixed top-0 left-0 z-20 w-full h-full bg-gray-900 opacity-75 focus:outline-none md:block fade-in-overlay"
              style={{ marginTop: '15rem' }}
              onClick={() => setSearchIsFocused(false)}
              onFocus={() => setSearchIsFocused(false)}
            />
          </>
        )}
      </>
    );
}

SearchBar.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default withRouter(SearchBar);
