import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '../Container';

const FooterWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  font-family: inter;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  padding: 6.25rem;
`;

const FooterInnerWrapper = styled.div`
  width: 100%;
  border-bottom: solid 4px #37ecec;
  padding-bottom: 2.5rem;
`;

const FooterInfoWrapper = styled.div`
  width: 100%;
  border-bottom: solid 4px #37ecec;
  padding: 2.5rem 0;
`;

const FooterTop = styled.div`
  display: flex;
`;

const FooterLogo = styled.div`
  color: #37ecec;
  font-weight: 700;
  font-size: 4.3rem;
  line-height: 3.5rem;
  margin-right: 2.5rem;
  margin-bottom: 2.5rem;
  > span {
    font-size: 3.25rem;
  }
`;

const FooterLine = styled.div`
  border-top: solid 4px #37ecec;
  width: 100%;
`;

const StyledLink = styled(Link)`
  font-weight: 700;
  font-size: 2.25rem;
  color: #d9d9d9;
`;

const PageFooter = () => {
  const { t } = useTranslation();
  return (
    <FooterWrapper>
      <Container style={{ padding: '0' }}>
        <FooterInnerWrapper>
          <FooterTop>
            <FooterLogo>
              SOUNDWIX
              <br />
              <span>SOUNDEFFECT</span>
            </FooterLogo>
            <FooterLine />
          </FooterTop>
          <ul className="md:h-full h-1/2">
            <li style={{ padding: '1rem 0' }}>
              <StyledLink
                to="/about"
                className="hover:underline focus:underline focus:outline-none"
                rel="author"
              >
                {t('about_soundwix')}
              </StyledLink>
            </li>
            <li style={{ padding: '1rem 0' }}>
              <StyledLink
                to="/terms"
                className="hover:underline focus:underline focus:outline-none"
                rel="terms"
              >
                {t('terms_of_use')}
              </StyledLink>
            </li>
            <li style={{ padding: '1rem 0' }}>
              <StyledLink
                to="/privacy"
                className="hover:underline focus:underline focus:outline-none"
                rel="privacy"
              >
                {t('privacy_policy')}
              </StyledLink>
            </li>
          </ul>
        </FooterInnerWrapper>
        <FooterInfoWrapper>
          상호명 : ㈜디지윅스 | 주소 : 서울특별시 마포구 월드컵로8길 45-8, 3022
          | 대표 : 고태석 | 개인정보관리책임자 : 송형열 | 사업자등록번호 :
          381-81-03088
          <br />
          고객센터 이메일 : <strong>help@digiwix.com</strong>
          <br />
          Copyright ⓒ 2023 digiwix. All rights Reserved
        </FooterInfoWrapper>
      </Container>
    </FooterWrapper>
  );
};

export default PageFooter;
