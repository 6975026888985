import {
  ADD_PLAYING_MIXER_ASSET,
  IMPORT_MIX_ASYNC,
  IMPORT_MIX_ERROR,
  IMPORT_MIX_SUCCESS,
  REMOVE_PLAYING_MIXER_ASSET,
  ADD_READY_MIXER_ASSET,
  REMOVE_READY_MIXER_ASSET,
  SET_MIXER_ASSET_SETTINGS,
  SET_MIXER_EDIT_ASSET,
  SET_MIXER_MODE,
  SET_MIXER_OPEN,
  SET_MIXER_PLAYBACK_RESET,
  SET_MIXER_PLAYING,
  UPDATE_MIXER_ASSETS,
} from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case SET_MIXER_MODE:
      return {
        ...state,
        mixerModeOn: action.value,
      };
    case SET_MIXER_PLAYING:
      return {
        ...state,
        mixerPlaying: action.value,
      };
    case SET_MIXER_PLAYBACK_RESET:
      return {
        ...state,
        mixerPlaybackReset: action.mixerPlaybackReset,
        mixerPlaying: action.mixerPlaybackReset ? false : state.mixerPlaying,
      };
    case SET_MIXER_OPEN:
      return {
        ...state,
        mixerOpen: action.value,
        mixerEditAssetId: !action.value ? null : state.mixerEditAssetId,
      };
    case SET_MIXER_EDIT_ASSET:
      return {
        ...state,
        mixerEditAssetId: action.mixerId,
      };
    case UPDATE_MIXER_ASSETS:
      return {
        ...state,
        mixerAssets: action.newMixerAssets,
        mixerEditAssetId: null,
      };
    case SET_MIXER_ASSET_SETTINGS: {
      return {
        ...state,
        mixerAssets: {
          ...state.mixerAssets,
          [action.id]: {
            ...state.mixerAssets[action.id],
            mixerSettings: {
              ...state.mixerAssets[action.id].mixerSettings,
              ...action.settings,
            },
          },
        },
      };
    }
    case IMPORT_MIX_ASYNC:
      return {
        ...state,
        mixImporting: true,
      };
    case IMPORT_MIX_SUCCESS:
      return {
        ...state,
        mixImporting: false,
        mixerModeOn: true,
        mixerOpen: true,
      };
    case IMPORT_MIX_ERROR:
      return {
        ...state,
        mixImporting: false,
        error: action.err,
      };
    case ADD_PLAYING_MIXER_ASSET:
      return {
        ...state,
        playingMixerAssets: state.playingMixerAssets + 1,
      };
    case REMOVE_PLAYING_MIXER_ASSET:
      return {
        ...state,
        playingMixerAssets:
          state.playingMixerAssets === 0 ? 0 : state.playingMixerAssets - 1,
      };
    case ADD_READY_MIXER_ASSET:
      return {
        ...state,
        readyMixerAssets: state.readyMixerAssets + 1,
      };
    case REMOVE_READY_MIXER_ASSET:
      return {
        ...state,
        readyMixerAssets:
          state.readyMixerAssets === 0 ? 0 : state.readyMixerAssets - 1,
      };
    default:
      return state;
  }
};
