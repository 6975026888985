export default {
  user: {
    account: '',
    email: '',
    subscribe: false,
    subscribe_start: '',
    subscribe_end: '',
    done_mail_cert: false,
  },
};
