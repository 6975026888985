import checkIsIOS from '../utilities/checkIsIOS';

const { isIOS, isIPadIOS13AndAbove, version } = checkIsIOS();
const autoplaySupported =
  !isIOS ||
  (isIOS && version && version >= 13) ||
  (isIOS && isIPadIOS13AndAbove);

export default {
  moreResultsLoading: false,
  apiLoading: false,
  resultsLoading: true,
  results: [],
  categoryAggregations: [],
  resultsLength: 0,
  error: '',
  echo: null,
  selectedAsset: null,
  showRatingModal: false,
  autoplay: false,
  autoplaySupported,
  mss: '',
  mssAction: null,
};
