import { RESULT_SIZE_CONFIG } from '../constants';
import getQuerystring from './getQuerystring';

export const queryBuilderByCategory = (cat) => {
  const parsedQuerystring = getQuerystring();

  // Prevent manual URL changes of the result size above the configured 'sizeLimit'
  const resultSizeLimiter = (size) => {
    if (size > RESULT_SIZE_CONFIG.sizeLimit) {
      return RESULT_SIZE_CONFIG.sizeLimit;
    }
    return size;
  };

  return {
    size: parsedQuerystring.resultSize
      ? resultSizeLimiter(parseInt(parsedQuerystring.resultSize, 10))
      : RESULT_SIZE_CONFIG.incrementSize,
    query: parsedQuerystring.q,
    categories: [cat],
    source: parsedQuerystring.source ? parsedQuerystring.source : null,
  };
};

function queryBuilder() {
  // Get query params
  const parsedQuerystring = getQuerystring();

  // Prevent manual URL changes of the result size above the configured 'sizeLimit'
  const resultSizeLimiter = (size) => {
    if (size > RESULT_SIZE_CONFIG.sizeLimit) {
      return RESULT_SIZE_CONFIG.sizeLimit;
    }
    return size;
  };
  const subs = parsedQuerystring.continents
    ? parsedQuerystring.continents.split(',')
    : [];
  const middles = parsedQuerystring.durations
    ? parsedQuerystring.durations.split(',')
    : [];
  const cats = parsedQuerystring.cat ? parsedQuerystring.cat.split(',') : [];
  return {
    size: parsedQuerystring.resultSize
      ? resultSizeLimiter(parseInt(parsedQuerystring.resultSize, 10))
      : RESULT_SIZE_CONFIG.incrementSize,
    query: parsedQuerystring.q,
    categories:
      subs.length !== 0 ? subs : middles.length !== 0 ? middles : cats,
    source: parsedQuerystring.source ? parsedQuerystring.source : null,
  };
}

export default queryBuilder;
