import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Cookie from 'js-cookie';
import {
  Backdrop,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
// Rating modal
import RatingModal from './Components/RatingModal';

// Navbar
import Navbar from './Components/Navbar';
import PageFooter from './Components/PageFooter';

// Banners
import CookieBanner from './Components/CookieBanner';

// Pages
import Home from './Pages/Home';
import SearchResults from './Pages/SearchResults';
import FAQs from './Pages/FAQs';
import About from './Pages/About';
import Feedback from './Pages/Feedback';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Cookies from './Pages/Cookies';
import Favourites from './Pages/Favourites';
import MixImport from './Pages/MixImport';
import NotFound from './Pages/NotFound';
import SearchBar from './Components/SearchBar';
import HomeEditMixer from './Pages/HomeEditMixer';
import HomeApplication from './Pages/HomeApplication';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import Userinfo from './Pages/UserInfo';
import Terms from './Pages/Terms';
import Subscribe from './Pages/Subscribe';
import { USER_LOGON } from './constants';
import { CheckLogin } from './Route/Route';
import { UserContext } from './Context/UserContext';
import { AppContext } from './Context/AppContext';

function Router() {
  const logon = Cookie.get(USER_LOGON);
  const [isLoading, setIsLoading] = useState(true);
  const {
    closeMss,
    state: { mss, apiLoading },
  } = useContext(AppContext);
  const { setUserInfo } = useContext(UserContext);
  useEffect(() => {
    const startUp = async () => {
      if (logon) {
        setIsLoading(true);
        const res = await CheckLogin();
        if (res.status === 200) {
          console.log(res.data.user);
          setUserInfo(res.data.user);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    startUp();
  }, []);
  return (
    <BrowserRouter>
      {/* <RatingModal /> */}
      {/* <CookieBanner /> */}
      <Navbar />
      <SearchBar />
      <Switch>
        {isLoading ? (
          <>
            <Route exact path="/" component={Home} />
            <Route exact path="/search" component={SearchResults} />
            <Route exact path="/subscribe" component={Subscribe} />
            <Route exact path="/faqs" component={FAQs} />
            <Route exact path="/about" component={About} />
            <Route exact path="/feedback" component={Feedback} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            {/* <Route exact path="/cookies" component={Cookies} /> */}
            <Route exact path="/favourites" component={Favourites} />
            <Route exact path="/favourites/:shareurl" component={Favourites} />
            <Route exact path="/miximport" component={MixImport} />
            <Route exact path="/HomeEditMixer" component={HomeEditMixer} />
            <Route exact path="/HomeApplication" component={HomeApplication} />
          </>
        ) : (
          <>
            <Route exact path="/" component={Home} />
            <Route exact path="/search" component={SearchResults} />
            <Route exact path="/subscribe" component={Subscribe} />
            <Route exact path="/faqs" component={FAQs} />
            <Route exact path="/about" component={About} />
            <Route exact path="/feedback" component={Feedback} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            {/* <Route exact path="/cookies" component={Cookies} /> */}
            <Route exact path="/favourites" component={Favourites} />
            <Route exact path="/favourites/:shareurl" component={Favourites} />
            <Route exact path="/miximport" component={MixImport} />
            <Route exact path="/HomeEditMixer" component={HomeEditMixer} />
            <Route exact path="/HomeApplication" component={HomeApplication} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/sign-up" component={Signup} />
            <Route exact path="/user-info" component={Userinfo} />
            <Route exact path="/terms" component={Terms} />
          </>
        )}
        <Route path={null} component={NotFound} />
      </Switch>
      <PageFooter />
      {mss && (
        <Dialog
          onClose={closeMss}
          aria-labelledby="customized-dialog-title"
          open={mss ? true : false}
        >
          <DialogTitle id="customized-dialog-title" onClose={closeMss} />
          <DialogContent
            style={{
              width: '30rem',
              minHeight: '8rem',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'Inter',
              fontSize: '2rem',
            }}
          >
            {mss}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeMss}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {apiLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={apiLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </BrowserRouter>
  );
}

export default Router;
