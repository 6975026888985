import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';

import getQuerystring from '../../utilities/getQuerystring';
import modifyExistingQuerystring from '../../utilities/modifyExistingQuerystring';
import FilterPill from './FilterPill';
import { SOURCES } from '../../constants';
import { NEW_CATEGORY_LIST } from '../../category';
import {
  parseCategories,
  parseMiddleCategory,
  parseSubCategory,
  parseSources,
} from '../../utilities/queryParamParsers';

const AppliedFilters = ({ history }) => {
  const { echoTrack } = useContext(AppContext);

  // Store a reference to the page the user is on
  const pageContext = history.location.pathname.substring(1);

  // Get search term from query string
  const parsedQuerystring = getQuerystring();

  // Use valid search term or default to empty
  const [categories, setCategories] = useState(
    parseCategories(parsedQuerystring.cat)
  );
  const [middles, setMiddles] = useState(
    parseMiddleCategory(parsedQuerystring.durations)
  );
  const [sources, setSources] = useState(
    parseSources(parsedQuerystring.source)
  );
  const [subs, setSubs] = useState(
    parseSubCategory(parsedQuerystring.continents)
  );
  const [recordists, setRecordists] = useState(
    parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []
  );
  const [habitats, setHabitats] = useState(
    parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []
  );

  // Update the filter properties when the URL params change
  useEffect(() => {
    // Validate URL params to prevent page failures due to unexpected values
    setCategories(parseCategories(parsedQuerystring.cat));
    setMiddles(parseMiddleCategory(parsedQuerystring.durations));
    setSources(parseSources(parsedQuerystring.source));
    setSubs(parseSubCategory(parsedQuerystring.continents));
    setRecordists(
      parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []
    );
    setHabitats(
      parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []
    );
  }, [
    parsedQuerystring.cat,
    parsedQuerystring.durations,
    parsedQuerystring.source,
    parsedQuerystring.continents,
    parsedQuerystring.recordist,
    parsedQuerystring.habitat,
  ]);

  const performSearch = (urlParam, filters) => {
    echoTrack(pageContext, { action: 'desktop_filter_removed' }, 'click');

    history.push(
      `/${pageContext}?${modifyExistingQuerystring(
        urlParam,
        filters.join(',')
      )}`
    );
  };

  return (
    <>
      {categories.concat(middles, subs, recordists, sources, habitats).length >
        0 && (
        <div className="flex flex-wrap items-center max-w-screen-xl px-3 pt-3 mx-auto">
          {categories.map((activeCategory) => (
            <FilterPill
              iconName={
                NEW_CATEGORY_LIST.find(
                  (x) => x.id.toString() === activeCategory
                ).icon
              }
              viewBox={
                NEW_CATEGORY_LIST.find(
                  (x) => x.id.toString() === activeCategory
                ).viewBox
              }
              filterValue={
                NEW_CATEGORY_LIST.find(
                  (x) => x.id.toString() === activeCategory
                ).name
              }
              key={activeCategory}
              closeAction={() => {
                const cat = NEW_CATEGORY_LIST.find(
                  (x) => x.id.toString() === activeCategory
                );
                history.push(
                  `/${pageContext}?${modifyExistingQuerystring(
                    'cat',
                    categories
                      .filter((category) => category !== activeCategory)
                      .join(','),
                    modifyExistingQuerystring(
                      'durations',
                      _.filter(
                        middles,
                        (mid) =>
                          !cat.sub.map((x) => x.id.toString()).includes(mid)
                      ).join(','),
                      modifyExistingQuerystring(
                        'continents',
                        _.filter(
                          subs,
                          (sub) =>
                            !cat.sub
                              .flatMap((x) => x.sub)
                              .map((x) => x.id.toString())
                              .includes(sub)
                        ).join(',')
                      )
                    )
                  )}`
                );
              }}
            />
          ))}
          {middles.map((activeMiddle) => (
            <FilterPill
              iconName="clock"
              filterValue={
                NEW_CATEGORY_LIST.flatMap((x) => x.sub).find(
                  (x) => x.id.toString() === activeMiddle
                ).name
              }
              key={activeMiddle}
              closeAction={() => {
                const middle_cat = NEW_CATEGORY_LIST.flatMap((x) => x.sub).find(
                  (x) => x.id.toString() === activeMiddle
                );
                history.push(
                  `/${pageContext}?${modifyExistingQuerystring(
                    'durations',
                    middles
                      .filter((duration) => duration !== activeMiddle)
                      .join(','),
                    modifyExistingQuerystring(
                      'continents',
                      _.filter(
                        subs,
                        (mid) =>
                          !middle_cat.sub
                            .map((x) => x.id.toString())
                            .includes(mid)
                      ).join(',')
                    )
                  )}`
                );
              }}
            />
          ))}
          {subs.map((activeSub) => (
            <FilterPill
              iconName="map"
              filterValue={
                NEW_CATEGORY_LIST.flatMap((x) => x.sub)
                  .flatMap((x) => x.sub)
                  .find((x) => x.id.toString() === activeSub).name
              }
              key={activeSub}
              closeAction={() =>
                performSearch(
                  'continents',
                  subs.filter((continent) => continent !== activeSub)
                )
              }
            />
          ))}
          {recordists.map((activeRecordist) => (
            <FilterPill
              iconName="microphone"
              filterValue={activeRecordist}
              key={activeRecordist}
              closeAction={() =>
                performSearch(
                  'recordist',
                  recordists.filter(
                    (recordist) => recordist !== activeRecordist
                  )
                )
              }
            />
          ))}
          {sources.map((activeSource) => (
            <FilterPill
              iconName="collection"
              filterValue={SOURCES[activeSource].text}
              key={activeSource}
              closeAction={() =>
                performSearch(
                  'source',
                  sources.filter((source) => source !== String(activeSource))
                )
              }
            />
          ))}
          {habitats.map((activeHabitat) => (
            <FilterPill
              iconName="in-season"
              filterValue={activeHabitat}
              key={activeHabitat}
              closeAction={() =>
                performSearch(
                  'habitat',
                  habitats.filter((habitat) => habitat !== activeHabitat)
                )
              }
            />
          ))}
        </div>
      )}
    </>
  );
};

AppliedFilters.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default withRouter(AppliedFilters);
