import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { AppContext } from '../../Context/AppContext';
import GelIcon from '../../Components/GelIcon';
import { MixerContext } from '../../Context/MixerContext';
import getQueryString from '../../utilities/getQuerystring';
import { useTranslation } from 'react-i18next';

const CategoryCard = ({ category, value, focusRef }) => {
  return (
    <Link
      to={`/search?cat=${category.name}`}
      className="flex items-center p-3 bg-white border-2 border-transparent border-solid rounded-md shadow-sm group hover:border-teal-700 focus:border-teal-700 focus:outline-none"
      ref={focusRef}
    >
      <div
        className={`flex items-center justify-center w-8 h-8 mr-2 rounded-full group-hover:bg-teal-700 group-focus:bg-teal-700 bg-teal-100 text-teal-700 group-focus:text-white group-hover:text-white`}
      >
        <GelIcon
          name={category.icon}
          viewBox={category.viewBox}
          fill={null}
          className="w-4 h-4 fill-current"
        />
      </div>
      <p className="text-black">
        <span className="font-semibold no-underline group-focus:underline group-hover:underline">
          {category.name.replace('_', ' ')}
        </span>{' '}
        {value ? `(${value})` : ''}
      </p>
    </Link>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    viewBox: PropTypes.string,
  }),
  value: PropTypes.number.isRequired,
  focusRef: PropTypes.shape({}),
};

function HomeEditMixer({ history }) {
  const { clearSearchResults, echoTrack } = useContext(AppContext);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const firstCategoryRef = useRef();
  const { t } = useTranslation();

  // Clear search results from state on component mount
  useEffect(() => {
    const queryParams = getQueryString();
    if (queryParams && (queryParams.q || queryParams.cat)) {
      //redirect to the search page, assuming no prior search state
      history.push(`/search?
      ${queryParams.q ? `q=${queryParams.q}` : ''}
      ${queryParams.q && queryParams.cat ? '&' : ''}
      ${queryParams.cat ? `cat=${queryParams.cat}` : ''}`);
    }

    echoTrack('homepage');

    clearSearchResults();
  }, [history]);

  useEffect(() => {
    if (showAllCategories) {
      firstCategoryRef.current.focus();
    }
  }, [showAllCategories]);

  return (
    <>
      <Helmet title="SOUNDWIX" />
      <div className={`bg-black transition-all duration-200 ease-in-out`}>
        <div className="flex justify-center items-center">
          <div>
            <p style={{ paddingRight: '25rem' }}></p>
          </div>
          <div className="flex-col mr-16 md:mt-40">
            <h2
              className="text-xl font-bold md:text-3xl md:mt-40"
              style={{ color: 'White' }}
            >
              Edit
              <span style={{ color: 'Yellow' }}>Mixer</span>
              <br />
              {t('create_your_music')}
            </h2>
            <div>
              <Link
                to="/search"
                className="items-center mt-2 mb-2 relative flex flex-col justify-center p-4 overflow-hidden text-xl font-bold leading-tight text-black duration-200 transform bg-yellow-800 rounded-lg lg:text-2xl md:h-20  hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1 bg-yellow"
              >
                <h2>{t('free_start_now')}</h2>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="flex md:mt-40 justify-center items-center"
          style={{ backgroundColor: 'gray' }}
        >
          <div className="flex md:items-center py-6">
            <div>
              <h2
                className="text-xl font-bold md:text-3xl"
                style={{ color: 'White' }}
              >
                {t('my_favourites')}
              </h2>
            </div>
            <div className="ml-4 w-48">
              <Link
                to="/favourites"
                className="items-center relative flex flex-col justify-center p-4 overflow-hidden text-xl font-bold leading-tight text-yellow-500 duration-200 transform bg-blue-800 rounded-lg lg:text-2xl md:h-20  hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1 bg-gray-900"
              >
                <h2>Go</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

HomeEditMixer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

export default withRouter(HomeEditMixer);
