import queryString from 'query-string';
import { sanitize } from 'dompurify';

// Parses the URL and returns the querystring params as an object
export default () => {
  const parsedString = queryString.parse(
    window.location.search.replace(/^\?+/, '')
  );

  if (parsedString.q) {
    // Sanitize query input
    // Replace 'curly' quotation marks with 'straight' quotation marks
    parsedString.q = sanitize(parsedString.q).replace(/[\u201C\u201D]/g, '"');
  }

  // Flatten array of categories to support them supplied in querystring as:
  // cat=A&cat=B
  // cat=[A,B] or cat=['A','B']
  if (parsedString.cat) {
    parsedString.cat = parsedString.cat.toString().replace(/[[\]']+/g, '');
  }

  return parsedString;
};
