import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

import { AppContext } from '../../Context/AppContext';
import { useTranslation } from 'react-i18next';
function Terms() {
  const { echoTrack } = useContext(AppContext);
  const { t } = useTranslation();
  useEffect(() => {
    echoTrack('terms');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet title="SOUNDWIX | Terms of Use" />
      <div
        style={{ color: 'white', fontFamily: 'Inter' }}
        className="max-w-screen-xl min-h-screen px-3 mx-auto"
      >
        <h2
          className="py-5 text-3xl font-bold md:py-10"
          style={{ fontSize: '3rem' }}
        >
          {t('terms_of_use')}
        </h2>
        <div style={{ fontSize: '1rem' }} className="pb-10 space-y-5">
          <p>
            사운드윅스에 오신 것을 환영합니다! 사운드윅스는 음원 콘텐츠를 공유,
            제공하는 크리에이티브 서비스입니다
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제1조 [목적]
          </h2>
          <p style={{ fontSize: '1rem' }}>
            본 약관은 (주)디지윅스(이하 “회사”)와 “이용자” 간에 “회사”가
            제공하는 콘텐츠 서비스인 사운드윅스(www.soundwix.com) 및 제반
            서비스를 이용함에 있어 “회사”와 “이용자” 간의 권리, 의무에 관한
            사항과 기타 사항을 규정합니다.
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제2조 (정의)
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. “서비스”란 음원을 구독자에게 배포하기 위해 회사에서 제공하는
            홈페이지를 포함한 제반 서비스를 의미합니다.
            <br />
            2. “이용자”라 함은 “회사”가 제공하는 “서비스”에 접속하여 이 약관에
            따라 “회사”가 제공하는 “콘텐츠” 및 제반 서비스를 이용하는 “구독자”
            및 “비구독자”을 말합니다.
            <br />
            3. “음원”이란 회사에서 제공하는, 구독자가 사용할 수 있는 라이브러리
            음원을 의미합니다.
            <br />
            4. “콘텐츠”란 사운드윅스에서 제공하는 모든 음원 및 기타 게시물을
            의미합니다.
            <br />
            5. “유료서비스”라 함은 “회사”가 제공하는 “서비스” 중 “구독자”가
            “회사”에 일정 금액을 결제하거나, “구독자”가 “회사” 또는 제3자와의
            거래 내지 약정 조건을 수락하는 경우에 이용할 수 있는 “회사”의
            “서비스”를 의미합니다.
            <br />6 “결제“라 함은 “회사”가 제공하는 “유료서비스”를 이용하기
            위하여 각종 지불수단을 통하여 일정 금액을 “회사”에 지불하는 것을
            의미합니다.
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제3조 [약관의 게시 및 개정]
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. “회사”는 본 약관의 내용을 “구독자”이 쉽게 알 수 있도록 서비스
            페이지에 게시합니다.
            <br />
            2. “회사”는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및
            정보보호에 관한 법률(이하 “정보통신망법”)”, “콘텐츠산업 진흥법”,
            “전자상거래 등에서의 소비자 보호에 관한 법률” 등 관련 법령을
            위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
            <br />
            3. “회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
            현행약관과 함께 제1항의 방식에 따라 공지하며, 개정된 약관은 그
            적용일에 효력이 발생합니다.
            <br />
            4. “회사”가 전항에 따라 “구독자”에게 ‘공지일로부터 개정약관
            시행일까지 거부의사를 표시하지 아니하면 승인한 것으로 본다’는 뜻을
            명확하게 고지하였음에도 불구하고 구독자의 거부의 의사표시가 없는
            경우에는 변경된 약관에 동의한 것으로 합니다. 구독자가 개정약관에
            동의하지 않을 경우 구독자은 본 이용약관 규정에 따라 이용계약을
            해지할 수 있습니다.
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제4조 (서비스의 제공 및 변경)
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. 회사는 다음과 같은 서비스를 제공합니다.
            <br />
            -라이브러리 음원 제공
            <br />
            -구매한 음원에 대한 다운로드 제공
            <br />
            -구독자의 이용 편의를 위한 이메일 고객센터 운영
            <br />
            2. 회사는 음원 제작 및 배급 환경에 변화가 있을 경우 장차 체결되는
            계약에 의해 제공될 음원의 내용을 변경할 수 있습니다.
            <br />
            3. 회사는 제공하기로 구독자과 계약을 체결한 서비스의 내용에 문제가
            있어 변경할 경우 그 사유를 이용자에게 이메일로 통지합니다.
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제5조 (서비스의 범위)
          </h2>
          <p style={{ fontSize: '1rem' }}>
            사운드윅스에서 제공하는 서비스의 이용자의 사용 범위는 다음과
            같습니다.
            <br />
            1. Youtube, Vimeo, 팟캐스트, 페이스북, 인스타그램, 트위터 등의
            SNS(소셜네트워크서비스)와 1 인 방송(아프리카TV, 유튜브 방송, 트위치
            등)의 매체에 삽입하여 사용 및 광고수익 창출
            <br />
            2. 웹드라마, 웹예능, 웹툰 및 웹광고
            <br />
            3. TV프로그램, 라디오, 홈표핑, TV광고, 라디오광고, 영화, 연극,
            뮤지컬, 패션쇼, 전시회, 게임 어플리케이션, 기기 등의 하드웨어에
            탑재, 출판 등
            <br />
            4. 위의 1.2.3.의 경우 외에는 어떠한 경우에도 사용할 수 없습니다.
            <br />
            5. 사운드윅스에서 제공하는 서비스는 다음과 같은 용도로는 사용할 수
            없습니다.
            <br />
            - 콘텐츠를 타인(개인, 단체, 회사 등)에게 유상, 무상으로 양도 및
            증여하거나 복제하여 제공하는 행위
            <br />
            - 콘텐츠를 독립적으로 게시, 또는 스트리밍하는 서비스로 네트워크
            회선을 통해 제3자에게 제공하는 행위
            <br />
            - 콘텐츠를 복제, 발췌, 편집 등을 통해 수록한 음악, 음반 형태로
            판매하는 전매 행위
            <br />
            - 컨텐츠를 ‘오픈마켓’ 등에서 중고물품으로 재판매 하는 행위
            <br />
            - 콘텐츠를 P2P사이트, 포털사이트 등의 공개장소에 파일 공유형태로
            업로드 및 공개하는 행위
            <br />
            - MR(반주용) 트랙으로 사용하여 녹음하여 판매하는 행위 및 음원에
            추가로 악기를 녹음하여 판매하는 행위
            <br />
            - 다운로드한 음원을 공공장소에서 연주(실연)하거나 연주된 음악을
            발표하는 행위
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제7조 [회사의 의무]
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. “회사”는 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여
            노력합니다.
            <br />
            2. “회사”는 “구독자”가 안전하게 서비스를 이용할 수 있도록 현재
            인터넷 보안기술의 발전수준과 “회사”가 제공하는 서비스의 성격에
            적합한 보안시스템을 갖추고 운영해야 합니다.
            <br />
            3. “회사”는 서비스를 이용하는 “구독자”으로 부터 제기되는 의견이나
            불만이 정당하다고 인정할 경우 이를 처리하여야 합니다. 이때
            처리과정에 대해서 고객에게 게시판 등의 방법으로 전달합니다.
            <br />
            4. “회사”는 『정보통신망 이용촉진 및 정보보호에 관한 법률』,
            『통신비밀보호법』, 『전기통신사업법』 등 서비스의 운영, 유지와 관련
            있는 법규를 준수합니다.
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제8조 [구독자의 의무]
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. “구독자”은 1개의 이용자 계정으로 최대 3개의 디바이스 (PC, 태블릿,
            스마트 폰 각각 1대)에서 서비스 이용이 가능합니다.
            <br />
            2. “구독자”은 다음 각호에 해당하는 행위를 해서는 안됩니다.
            <br />
            - 이용 신청 또는 구독자정보 변경 시 허위내용 등록
            <br />
            - 타인의 정보 도용
            <br />
            - 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여
            <br />
            - 회사의 운영자, 임직원, 회사를 사칭하거나 관련 정보를 도용
            <br />
            - 회사가 게시한 정보의 변경
            <br />
            - 회사와 기타 제3자의 저작권, 영업비밀, 특허권 등 지적재산권에 대한
            침해
            <br />
            - 회사와 다른 구독자 및 기타 제3자를 희롱하거나, 위협하거나 명예를
            손상시키는 행위
            <br />
            - 외설, 폭력적인 메시지를 공개 또는 게시하는 행위
            <br />
            - 해킹을 통해서 다른 사용자의 정보를 취득 및 사용하는 행위
            <br />
            - 기타 현행 법령에 위반되는 불법적인 행위3. “회사”는 “구독자”이
            전항에서 금지한 행위를 하는 경우, 위반 행위의 경중에 따라 서비스의
            이용정지/계약의 해지 등 서비스 이용 제한, 수사 기관에의 고발 조치 등
            합당한 조치를 취할 수 있습니다.
            <br />
            4. “구독자”은 “회사”의 명시적 사전 동의가 없는 한 서비스의 이용권한
            및 기타 이용계약상의 지위를 제3자에게 양도, 증여, 대여할 수 없으며
            이를 담보로 제공할 수 없습니다.
            <br />
            5. “구독자”은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여
            공지한 주의사항, “회사”가 통지하는 사항 등을 준수하여야 하며, 기타
            “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다.
            <br />
            6. “구독자”은 “회사”의 사전 허락 없이 “회사”가 정한 이용 목적과
            방법에 반하여 영업/광고활동 등을 할 수 없고, “구독자”의 서비스
            이용이 “회사”의 재산권, 영업권 또는 비즈니스 모델을 침해하여서는
            안됩니다.
            <br />
            7. 청소년 유해 매체물을 이용하기 위해서는 만 19세 이상의
            구독자가어야 하며(단, 만 19세에 도달하는 해의 1월 1일을 맞이한 자를
            포함합니다), 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및
            청소년 보호법의 규정에 의한 인증수단을 통해 성인인증을 받아야 하고
            성인인증을 받지 않는 경우, 해당 서비스의 이용이 제한됩니다.
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제9조 (구독자가입)
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. 이용자는 회사가 정한 양식에 따라 구독자정보를 기입한 후 이 약관에
            동의한다는 의사표시를 함으로서 구독자가입을 신청합니다.
            <br />
            2. 구독자 가입계약의 성립 시기는 회사의 승낙이 이용자에게 도달한
            시점으로 합니다.
            <br />
            3. 구독자는 구독자가입 시 등록한 사항에 변경이 있는 경우, 구독자은
            이를 즉시 회사에 대하여 구독자정보 수정 등의 방법으로 변경사항을
            알려야 합니다. 변경의 지체로 인해 발생한 구독자의 손해에 대해서
            회사는 책임을 지지 않습니다.
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제10조 (구독자에 대한 통지)
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. 회사가 구독자에 대한 통지를 하는 경우, 구독자가 회사와 미리
            약정하여 지정한 전자우편 주소로 할 수 있습니다.
            <br />
            2. 회사는 불특정다수 구독자에 대한 통지의 경우 1주일이상 회사 게시판
            또는 팝업 창에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만,
            구독자 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는
            개별통지를 합니다.
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제11조 (면책)
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. 구독자 간에 이루어지는 음원의 매매와 관련하여 발생하는
            저작권분쟁, 저작권 등록에 따른 모든 절차는 거래의 당사자인
            구독자간에 수행되어야 하며, 회사는 거래진행에는 관여하지 않으며
            어떠한 책임도 부담하지 않습니다.
            <br />
            2. 회사는 제4조(서비스의 제공 및 변경)제2항의 사유로 인하여 서비스를
            일시적으로 중단하는 경우 회사는 이로 인하여 구독자 또는 제3자가 입은
            손해에 대하여 책임지지 않습니다.
            <br />
            3. 회사는 구독자의 귀책사유로 인한 서비스 이용의 장애에 대하여
            책임을 지지 않습니다.
            <br />
            4. 구독자가 자신의 개인정보를 타인에게 유출 또는 제공함으로써
            발생하는 피해에 대해서 회사는 일절 책임을 지지 않습니다.
            <br />
            5. 회사의 서비스 화면에서 링크, 배너 등을 통하여 연결된 회사(이하 피
            연결회사)와 구독자 간에 이루어진 거래에 회사는 개입하지 않으며, 해당
            거래에 대하여 책임을 지지 않습니다.
            <br />
            6. 회사는 적법한 권리자의 요구가 있는 경우에는 당해 음원 등에 관한
            정보를 삭제하거나 수정할 수 있으며, 판매구독자은 이로 인한
            손해배상을 회사에 청구할 수 없습니다.
            <br />
            7. 회사는 제4조(서비스의 제공 및 변경)제2항에 따른 서비스나 기능의
            전부 또는 일부의 수정이나 중단으로 인한 책임을 지지 않습니다.
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제13조 (기타 조항)
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. 회사는 필요한 경우 특정 서비스나 기능의 전부 또는 일부를
            사운드윅스 사이트를 통해 일정 기간 미리 공지한 후 일시적 또는
            영구적으로 수정하거나 중단할 수 있습니다.
            <br />
            2. 각 당사자는 상대방의 서면동의 없이 이 약관상의 권리와 의무를
            제3자에게 양도하거나 처분할 수 없습니다.
            <br />
            3. 이 약관과 관련하여 당사자 간의 합의에 의하여 추가로 작성된
            계약서, 협정서, 통보서 등과 회사의 정책변경, 법령의 제개정 또는
            공공기관의 고시나 지침 등에 의하여 회사가 사운드윅스 사이트를 통해
            공지하는 내용도 이용계약의 일부를 구성합니다.
            <br />
            4. 회사는 구독자가 서비스 이용 시 발생 할 수 있는 정당한 의견이나
            불만 사항의 적극적 수렴, 그의 해결, 구독자 상호간에 분쟁 조정을
            위하여 고객센터를 설치하고 운영합니다. 회사는 구독자가 제기하는 각종
            불만사항 및 의견에 대하여 적극적으로 검토 후 정당하다고 판단되는
            경우 이를 신속하게 처리하며, 즉시 처리가 곤란한 사항에 대하여서는 그
            사유와 처리기간에 대하여 전자우편 혹은 전화를 통하여 구독자에게
            통보를 합니다.
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제14조 (분쟁해결)
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. 회사는 구독자가 제기하는 정당한 의견이나 불만을 반영하고 그
            피해를 보상처리하기 위하여 고객센터를 설치․운영하며 그 연락은
            전자이메일로 합니다
            <br />
            2. 회사와 구독자 간에 발생한 전자상거래 분쟁과 관련하여 구독자의
            피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가
            의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            제15조 (재판권 및 준거법)
          </h2>
          <p style={{ fontSize: '1rem' }}>
            1. 회사와 구독자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소
            당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는
            지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는
            거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의
            관할법원에 제기합니다.
            <br />
            2. 회사와 구독자 간에 제기된 전자상거래 소송에는 한국법을
            적용합니다.
            <br />
          </p>
          <h2 style={{ fontSize: '1.5rem' }} className="text-xl font-bold">
            부 칙 (시행일 등)
          </h2>
          <p style={{ fontSize: '1rem' }}>
            본 약관은 2023년 08월 01일부터 적용됩니다.
            <br />
          </p>
        </div>
      </div>
    </>
  );
}

export default Terms;
