const en = {
  language: 'LANGUAGE',
  free_start_now: 'Free Start Now',
  my_favourites: 'My favourites',
  your_favourites: 'Your Favourites',
  about_soundwix: 'About Digiwix',
  faqs: 'FAQs',
  terms_of_use: 'Terms of Use',
  privacy_policy: 'Privacy Policy',
  create_your_music: 'Create your music',
  reset_filters: 'Reset Filters',
  favourites_localstorage_alert:
    'Your favourites are stored using localstorage.',
  tell_me_more: 'Tell me more',
  favourite_desc_1: 'You can store up to 100 favourites in your browser.',
  favourite_desc_2:
    'Your favourites will only be available in this browser, until you\nclear your cache.',
  favourite_desc_3:
    'You can choose to download your favourites using the ‘Download all\nfavourites’ button if you don’t want to lose what you have\nfavourited.',
  ok_got_it: 'Okay, got it',
  cancel: 'Cancel',
  apply: 'Apply',
  no_favourites_found: 'No favourites found',
  about_desc_1:
    'The DIGWIX Sound Effects Archive is available for personal, educational or research purposes.There are over 33, 000 clips from across the world from the past 100 years.These include clips made by the DIGWIX Radiophonic workshop, recordings from the Blitz in London, special effects made for DIGWIX TV and Radio productions, as well as 15, 000 recordings from the Natural History Unit archive.You can explore sounds from every continent - from the college bells ringing in Oxford to a Patagonian waterfall - or listen to a submarine klaxon or the sound of a 1969 Ford Cortina door slammingshut.',
  about_desc_2:
    'As well as browsing and searching through this treasure trove of sounds, you can also make and share your own mixes and soundscapes using the ‘mixer mode’ function. Use the mixer to layer, edit and re-order clips from the archive to create your own sounds.',
  about_desc_3: 'The Natural History Unit Collection',
  about_desc_4:
    'The Natural History sound collection originated from the need to provide authentic natural sound for the Natural History Unit’s television and radio productions, and its burgeoning output during 1960s. Most people probably don’t realise that the majority of natural history television is recorded mute; the recording demands of cameras and microphones being very different; and generally you need to get a lot closer to your subject to record the sound of an animal than you do to film it; so for the majority of the time the sound is added during a production’s edit. It meant that the Unit needed to build up a library of good quality natural sounds that would become an ongoing resource and fill the gaps for productions when they were unable to record them convincingly at the time of a shoot.',
  about_desc_5:
    'The Unit began to assimilate and incorporate the sounds collected by production recordists as they returned from the field, whilst also employing its own dedicated sound recordist to collect natural sounds and commissioning recordings from others. Gradually the sound collection was built and has since become one of the country’s largest collection of natural sounds.',
  about_desc_6:
    'The original collection was housed on vinyl, before being replaced by quarter-inch tape, and then CD, eventually moving onto a digital base. Sound doesn’t date in the same way that film does, so recordings made on tape in the 1970s can still be used in high quality productions today.',
  frequently_asked_questions: 'Frequently Asked Questions',
  about_digwix_sound_effects: 'About SOUNDWIX Sound Effects',
  more_detail: 'More Detail',
  file_info: 'File info',
  file_size: 'File size',
  channels: 'Channels',
  source: 'Source',
  sounds_added_to_mixer: 'Sousounds added to mixer',
  view_mix: 'View mix',
  play_all: 'Play all',
  pause_all: 'Pause all',
  download_all: 'Download all',
  share_mix: 'Share Mix',
  add_to_mixer: 'Add to mixer',
  added: 'Added',
  in_mix: 'in mix',
  load_more: 'Load more',
  loading: 'Loading...',
  reset: 'Reset',
  signin: {
    fill_id: 'Please fill id field',
    fill_pw: 'Please fill password field',
    fill_email: 'Please fill email field',
    fill_mobile: 'Please fill mobile field',
    fail: 'Account or Password is incorrected',
    fail_member: 'Failed to join the membership',
    fail_change: 'Failed to change user informations',
  },
  info: {
    fail_request_verify: 'Failed to request verification',
    fail_verify_email: 'Failed to verify email',
    success_verify_email: 'Verified successfully',
    success_change: 'Changed successfully',
  },
  subscribe: {
    already_subscribed:
      'You have already subscribed. Please check your subscription.',
    fail_subscribe: 'Failed to subscribe. Please try again.',
    please_login: 'Please login to subscribe.',
    desc1:
      'If there is no usage history within +7 days from the date of payment of the subscription fee Cancellation/Refund is possible',
    desc2:
      'However, when paying by mobile phone, you can only cancel the payment for the current month, so payment cannot be canceled after the current month.',
    desc3:
      'Music sources used during the period of use are permanently protected even after the period of use, however, even music downloaded during the period of use cannot be used after the end of the period of use.',
  },
  registUse: {
    fail_regist: 'Failed to register. Please try again.',
    success_regist: 'Registered successfully',
    please_login: 'Please login to regist.',
    already_registed: 'You have already registered.',
  },
};

export default en;
