export const NEW_CATEGORY_LIST = [
  {
    id: 1,
    name: '분위기',
    icon: 'commercial-plane',
    sub: [
      {
        id: 10000,
        name: '도시',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50000,
            name: '교외&시골',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50001,
            name: '도시인',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50002,
            name: '대도시',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50003,
            name: '공원',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50004,
            name: '스카이라인',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10001,
        name: '군중',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50005,
            name: '대규모',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50006,
            name: '소규모',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50007,
            name: '조용한',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50008,
            name: '대중반응',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50009,
            name: '왈라',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10002,
        name: '실내',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50010,
            name: '넓은',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50011,
            name: '좁은',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50012,
            name: '공공장소',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50013,
            name: '실내',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50014,
            name: '바쁜공간',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10003,
        name: '자연',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50015,
            name: '호수&강',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50016,
            name: '숲',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50017,
            name: '해변&바다',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50018,
            name: '필드',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50019,
            name: '사막',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10004,
        name: '교통',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50020,
            name: '자동차',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50021,
            name: '비행기',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50022,
            name: '선박',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50023,
            name: '대중교통',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50024,
            name: '인력',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10005,
        name: '날씨',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50025,
            name: '비',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50026,
            name: '바람',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50027,
            name: '폭풍',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50028,
            name: '맑은날',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50029,
            name: '밤',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Realistic',
    icon: 'commercial-plane',
    sub: [
      {
        id: 10006,
        name: '동물',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50030,
            name: '야생',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50031,
            name: '애완',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50032,
            name: '곤충',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50033,
            name: '새',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50034,
            name: '농장',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10007,
        name: '전자',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50035,
            name: '전화',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50036,
            name: 'TV',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50037,
            name: '라디오',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50038,
            name: '카메라',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50039,
            name: '컴퓨터',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10008,
        name: '자연',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50040,
            name: '익스트림',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50041,
            name: '불',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50042,
            name: '공기',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50043,
            name: '물',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50044,
            name: '지구',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10009,
        name: '재료',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50045,
            name: '직물',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50046,
            name: '목재',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50047,
            name: '메탈',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50048,
            name: '합성',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50049,
            name: '종이',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10010,
        name: '홈',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50050,
            name: '기구',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50051,
            name: '주방',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50052,
            name: '문&창문',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50053,
            name: '옥내',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50054,
            name: '옥외',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10011,
        name: '상업산업',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50055,
            name: '기계',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50056,
            name: '오피스',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50057,
            name: '도구',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50058,
            name: '구성',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50059,
            name: '공장',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10012,
        name: '스포츠',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50060,
            name: '수상',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50061,
            name: '볼',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50062,
            name: '익스트림',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50063,
            name: '모터',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50064,
            name: '근력&민첩성',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10013,
        name: '교통',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50065,
            name: '자동차',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50066,
            name: '기차&버스',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50067,
            name: '비행기',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50068,
            name: '배&잠수함',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10014,
        name: '자전거&스케이트보드',
        icon: 'commercial-plane',
        sub: [],
      },
      {
        id: 10015,
        name: '무기',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50069,
            name: '군사',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50070,
            name: '총',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50071,
            name: '칼',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50072,
            name: '히트',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50073,
            name: '전쟁 & 폭팔음',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Ploy',
    icon: 'commercial-plane',
    sub: [
      {
        id: 10016,
        name: 'Body Hits & 무술',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50074,
            name: '바디폴',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50075,
            name: '펀치',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50076,
            name: '킥',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50077,
            name: '손상',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50078,
            name: '목소리',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10017,
        name: '발자취',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50079,
            name: '나무바닥',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50080,
            name: '하이힐',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50081,
            name: '자연스러운',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50082,
            name: '산책',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50083,
            name: '달리기',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10018,
        name: '목소리&Body Sounds',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50084,
            name: '아이들',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50085,
            name: '여성',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50086,
            name: '남성',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50087,
            name: '반응',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50088,
            name: '신체기능',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10019,
        name: '웨어러블 오브젝트',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50089,
            name: '의상',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50090,
            name: '백',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50091,
            name: '보석',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50092,
            name: '액세서리',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50093,
            name: '스트랩',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: 'Musical',
    icon: 'commercial-plane',
    sub: [
      {
        id: 10020,
        name: '로고',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50094,
            name: '오디오로고',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50095,
            name: '에픽트랜지션',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50096,
            name: '뮤지컬로고',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50097,
            name: '인트로',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50098,
            name: '아우트로',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10021,
        name: 'Loops & Phrases',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50099,
            name: '멜로디',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50100,
            name: '타악기',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50101,
            name: '드럼',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50102,
            name: '현악기',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50103,
            name: '전자기기',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10022,
        name: '원샷',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50104,
            name: '멜로딕',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50105,
            name: '타악기',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50106,
            name: '드럼',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50107,
            name: '현악',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50108,
            name: '일렉트로',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10023,
        name: '보컬',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50109,
            name: '여성',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50110,
            name: '남성',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50111,
            name: '보컬',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50112,
            name: '파워프레이즈',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50113,
            name: '처리됨',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: 'Special',
    icon: 'commercial-plane',
    sub: [
      {
        id: 10024,
        name: '카툰',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50114,
            name: '보잉&딩',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50115,
            name: '멜로디',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50116,
            name: '타악기',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50117,
            name: '목소리',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50118,
            name: '완구',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10025,
        name: '파괴',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50119,
            name: '폭발',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50120,
            name: '부스러기',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50121,
            name: '충돌',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50122,
            name: '파괴',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50123,
            name: '새터링',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10026,
        name: '공포',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50124,
            name: '호러',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50125,
            name: 'Scary',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50126,
            name: '유령',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50127,
            name: '몬스터',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50128,
            name: '고어',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10027,
        name: '임팩트',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50129,
            name: '극적',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50130,
            name: '하드',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50131,
            name: '에어',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50132,
            name: '긴',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50133,
            name: '짧은',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10028,
        name: 'Sci-Fi and Fantasy',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50134,
            name: '환타지&매직',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50135,
            name: '우주선',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50136,
            name: '우주분위기',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50137,
            name: '공상과학기계',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50138,
            name: '무기',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10029,
        name: '기술',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50139,
            name: '인터페이스',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50140,
            name: '글리치',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50141,
            name: '알림',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50142,
            name: '메뉴',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50143,
            name: '게이밍',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10030,
        name: 'Whooshes & Transition',
        icon: 'commercial-plane',
        sub: [
          {
            id: 50144,
            name: '짧은',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50145,
            name: '긴',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50146,
            name: 'Risers',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50147,
            name: 'Downers',
            icon: 'commercial-plane',
            sub: [],
          },
          {
            id: 50148,
            name: 'Reverse',
            icon: 'commercial-plane',
            sub: [],
          },
        ],
      },
      {
        id: 10031,
        name: '글리치',
        icon: 'commercial-plane',
        sub: [],
      },
    ],
  },
];

export const getParents = (id) => {
  let result = [];
  for (const cat of NEW_CATEGORY_LIST) {
    result.push(cat);
    const exist = existCategoryById(id, cat, result);
    if (exist) {
      return result;
    } else {
      result = [];
    }
  }
  return result;
};

const existCategoryById = (id, category, result) => {
  if (category.id === id) {
    return true;
  }
  for (const cat of category.sub) {
    result.push(cat);
    const exist = existCategoryById(id, cat, result);
    if (exist) {
      return true;
    }
    result.pop(cat);
  }
  return false;
};
