import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import { FavouritesContext } from '../../Context/FavouritesContext';

import GelIcon from '../GelIcon';
import { THEME_COLOURS } from '../../constants';
import MixerAudioPlayer from './MixerAudioPlayer';
import TermsOfUseModal from '../SearchResultItem/TermsOfUseModal';
import VolumeWarningModal from './VolumeWarningModal';
import ShareModal from '../ShareModal';
import UserButtons from '../SearchResultItem/UserButtons';
import MoreDetails from '../SearchResultItem/MoreDetails';
import checkIsIOS from '../../utilities/checkIsIOS';
import { Stack, IconButton, Tooltip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MAX_VOLUME = 6;

function MixerAudioItem({ asset, assetIndex }) {
  const { isLooping, volume } = asset.mixerSettings;

  const { echoTrack } = useContext(AppContext);
  const {
    state: { mixerEditAssetId },
    upMixerAsset,
    downMixerAsset,
    removeMixerAsset,
    removePlayingMixerAsset,
    removeReadyMixerAsset,
    setMixerEditAsset,
    setMixerPlaying,
    setMixerAssetSettings,
  } = useContext(MixerContext);
  const {
    state: { favouriteIds },
    updateFavourites,
  } = useContext(FavouritesContext);

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showVolumeWarningModal, setShowVolumeWarningModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [showMoreDetail, setShowMoreDetail] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [showDownloadPopover, setShowDownloadPopover] = useState(false);
  const [favourited, setFavourited] = useState(favouriteIds.includes(asset.id));

  const isEditingAsset = mixerEditAssetId === assetIndex;
  const { isIOS } = checkIsIOS();

  useEffect(() => {
    if (favouriteIds.includes(asset.id)) {
      setFavourited(true);
    } else {
      setFavourited(false);
    }
  }, [favouriteIds, asset.id]);

  const handleFavouriteClick = () => {
    const newFavouriteIds = [...favouriteIds];
    if (favourited) {
      echoTrack(
        'mixer',
        { action: 'asset_favourited', data: { assetId: asset.id } },
        'click'
      );
      newFavouriteIds.splice(newFavouriteIds.indexOf(asset.id), 1);
    } else {
      newFavouriteIds.push(asset.id);
    }
    updateFavourites(newFavouriteIds);
  };

  const handleEditClick = () => {
    echoTrack(
      'mixer',
      { action: 'asset_edit', data: { assetId: asset.id } },
      'click'
    );

    setMixerPlaying(false);
    setMixerEditAsset(assetIndex);
  };

  const handleVolumeClick = (volume) => {
    echoTrack(
      'mixer',
      { action: 'asset_volume', data: { assetId: asset.id } },
      'click'
    );

    setIsMuted(false);
    setMixerAssetSettings({ volume }, assetIndex);
  };

  const handleLoopClick = (isLooping) => {
    echoTrack(
      'mixer',
      { action: 'asset_loop', data: { assetId: asset.id } },
      'click'
    );

    setMixerAssetSettings({ isLooping }, assetIndex);
  };

  const handleRemoveClick = () => {
    removePlayingMixerAsset();
    removeReadyMixerAsset();
    removeMixerAsset(assetIndex);
  };

  const handleItemUp = () => {
    upMixerAsset(assetIndex);
  };

  const handleItemDown = () => {
    downMixerAsset(assetIndex);
  };

  return (
    <div
      className="flex flex-row items-stretch w-full bg-gray-900"
      style={
        isEditingAsset
          ? { outline: `4px solid ${THEME_COLOURS.SECONDARY}` }
          : {}
      }
    >
      <button
        type="button"
        title="Remove from mixer"
        aria-label="Remove from mixer"
        className="hidden border-r border-gray-400 focus:outline-none md:block hover:bg-gray-800 focus:bg-gray-800"
        onClick={handleRemoveClick}
      >
        <div className="px-8 pt-4">
          <GelIcon
            name="add"
            fill="white"
            className="w-6 h-6 transform rotate-45 ms-rotate-45"
          />
        </div>
        <div className="pt-2 text-xs text-center">Remove</div>
      </button>
      <div className="w-full md:px-6">
        <div className="flex flex-row items-stretch justify-between w-full border-b border-gray-400 md:hidden">
          {/* Small screen remove button */}
          <button
            type="button"
            title="Remove from mixer"
            aria-label="Remove from mixer"
            className="w-full focus:outline-none hover:bg-gray-800 focus:bg-gray-800"
            onClick={() => removeMixerAsset(assetIndex)}
          >
            <div className="flex flex-row items-center py-5 pl-4">
              <GelIcon
                name="add"
                fill="white"
                className="w-5 h-5 transform rotate-45 ms-rotate-45"
              />
              <span className="ml-2 text-sm">Remove from mixer</span>
            </div>
          </button>

          {/* Small screen edit button */}
          <button
            className="flex flex-row items-center justify-between w-1/4 px-4 py-2 text-gray-900 bg-white focus:outline-none hover:opacity-75 focus:opacity-75"
            aria-label="Edit sound"
            onClick={handleEditClick}
          >
            <GelIcon
              name="edit"
              className="w-5 h-5"
              fill={THEME_COLOURS.PRIMARY}
            />
            Edit
          </button>
        </div>
        <div className="px-6 pt-4 pb-2 md:px-0 md:mx-0 md:flex md:flex-row">
          <div className="w-full md:flex md:flex-row">
            <div className="flex flex-row-reverse items-center justify-between md:flex-row md:w-1/2">
              <MixerAudioPlayer
                asset={asset}
                isLooping={isLooping}
                setIsLooping={handleLoopClick}
                isMuted={isMuted}
                currentVolume={volume || 1}
              />
              {/* Loop button - only to show on small screens */}
              <div className="flex flex-row items-center md:space-x-4 md:hidden">
                <div className="relative">
                  <button
                    type="button"
                    className="p-2 mr-2 -ml-2 rounded-md focus:outline-none focus:bg-gray-800 hover:bg-gray-800"
                    onClick={() => handleLoopClick(!isLooping)}
                    aria-label={
                      isLooping ? 'Disable looping' : 'Enable looping'
                    }
                  >
                    <GelIcon
                      name={isLooping ? 'movement-on' : 'movement-off'}
                      fill={isLooping ? 'white' : THEME_COLOURS.DARK_GREY}
                      className="block w-6 h-6 md:hidden"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-row items-center justify-end lg:justify-between md:flex md:w-1/2 md:mt-0">
              {/* Volume control */}
              <div className="flex-row items-center hidden w-1/5 lg:ml-10 lg:flex">
                {!isIOS ? (
                  <>
                    <button
                      title={isMuted ? 'Unmute audio' : 'Mute audio'}
                      aria-label={isMuted ? 'Unmute audio' : 'Mute audio'}
                      onClick={() => setIsMuted(!isMuted)}
                      className="p-2 mr-2 -ml-2 rounded-md hover:bg-gray-800 focus:bg-gray-800 focus:outline-none"
                    >
                      <GelIcon
                        name={isMuted ? 'sound-on-off' : 'audio'}
                        fill="white"
                        className="w-5 h-5"
                      />
                    </button>
                    {[...Array(MAX_VOLUME)].map((a, vol) => (
                      <button
                        key={vol}
                        type="button"
                        aria-label={`Set volume to ${vol + 1}/${MAX_VOLUME}`}
                        title={`${vol + 1}/${MAX_VOLUME}`}
                        onClick={() =>
                          handleVolumeClick((vol + 1) / MAX_VOLUME)
                        }
                        className="h-6 pr-3 transition-all duration-150 ease-in-out focus:outline-none hover:h-8 focus:h-8"
                      >
                        <div
                          className={`w-1 h-full ${
                            !volume ||
                            (volume >= (vol + 1) / MAX_VOLUME && !isMuted)
                              ? 'bg-white'
                              : 'bg-gray-800'
                          }`}
                        >
                          &nbsp;
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <button
                    title="Volume control unavailable"
                    aria-label="Volume control unavailable"
                    onClick={() => setShowVolumeWarningModal(true)}
                    className="p-2 mr-2 -ml-2 rounded-md hover:bg-gray-800 focus:bg-gray-800 focus:outline-none"
                  >
                    <GelIcon
                      name="sound-on-off"
                      fill="white"
                      className="w-5 h-5"
                    />
                  </button>
                )}
              </div>

              <p className="my-4 text-sm leading-snug text-white md:my-0 md:hidden">
                {asset.description}
              </p>

              <UserButtons
                asset={asset}
                setShowTermsModal={setShowTermsModal}
                setShowShareModal={setShowShareModal}
                setSelectedFile={setSelectedFile}
                isLooping={isLooping}
                setIsLooping={handleLoopClick}
                favourited={favourited}
                handleFavouriteClick={handleFavouriteClick}
                showDownloadPopover={showDownloadPopover}
                setShowDownloadPopover={setShowDownloadPopover}
                mixerVersion={true}
                showMoreDetail={showMoreDetail}
                setShowMoreDetail={setShowMoreDetail}
              />

              {/* Edit button (md upwards) */}
              <div className="hidden w-24 md:block">
                <button
                  className={`focus:outline-none flex flex-row items-center justify-around w-full px-4 py-2 hover:opacity-75 focus:opacity-75 bg-white text-gray-900${
                    mixerEditAssetId ? ' opacity-75 cursor-not-allowed' : ''
                  }`}
                  aria-label="Edit sound"
                  onClick={handleEditClick}
                  disabled={mixerEditAssetId}
                >
                  <GelIcon
                    name="edit"
                    className="w-5 h-5"
                    fill={THEME_COLOURS.PRIMARY}
                  />
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row items-center justify-between hidden pb-3 md:flex">
          <span className="pr-3">{asset.description}</span>
          <button
            type="button"
            className="flex flex-row items-center focus:outline-none hover:underline focus:underline"
            onClick={() => setShowMoreDetail(!showMoreDetail)}
            aria-label={
              showMoreDetail ? 'Show less detail' : 'Show more detail'
            }
          >
            <div className="hidden font-bold md:block md:mr-3 md:w-24">{`${
              !showMoreDetail ? 'More' : 'Less'
            } detail`}</div>
            <GelIcon
              name={showMoreDetail ? 'chevron-up' : 'chevron-down'}
              className="w-6 h-6 mx-1 md:w-4 md:mx-0"
              fill="white"
            />
          </button>
        </div>
        {showMoreDetail && <MoreDetails asset={asset} mixerVersion={true} />}
      </div>
      <Stack justifyContent={'center'}>
        <Tooltip title="Item up">
          <IconButton onClick={handleItemUp}>
            <KeyboardArrowUpIcon sx={{ color: '#ffffffff' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Item down">
          <IconButton onClick={handleItemDown}>
            <KeyboardArrowDownIcon sx={{ color: '#ffffffff' }} />
          </IconButton>
        </Tooltip>
      </Stack>
      {showTermsModal && (
        <TermsOfUseModal
          selectedFile={selectedFile}
          setShowTermsModal={setShowTermsModal}
          asset={asset}
        />
      )}
      {showShareModal && (
        <ShareModal asset={asset} setShowShareModal={setShowShareModal} />
      )}
      {showVolumeWarningModal && (
        <VolumeWarningModal
          setShowVolumeWarningModal={setShowVolumeWarningModal}
        />
      )}
    </div>
  );
}

MixerAudioItem.propTypes = {
  assetIndex: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    mixerSettings: PropTypes.shape({
      volume: PropTypes.number,
      isLooping: PropTypes.bool,
    }),
  }).isRequired,
};

export default MixerAudioItem;
