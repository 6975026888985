import React, { useContext, useEffect } from 'react';

import MixerOverlayHeader from './MixerOverlayHeader';
import MixerAudioItem from './MixerAudioItem';
import MixerAudioEditPanel from './MixerAudioEditPanel';
import { MixerContext } from '../../Context/MixerContext';
import GelIcon from '../GelIcon';

function MixerOverlay() {
  const {
    state: { mixerAssets, mixerOpen, mixerEditAssetId },
    setMixerOpen,
  } = useContext(MixerContext);

  useEffect(() => {
    if (mixerOpen) {
      document.documentElement.style.position = 'fixed';
      document.documentElement.style.width = '100%';
    } else {
      document.documentElement.style.position = '';
      document.documentElement.style.width = '';
    }
  }, [mixerOpen]);

  return (
    <div
      className={`fixed ${
        mixerOpen ? 'top-0 bottom-0 left-0 right-0 z-30' : ''
      }`}
    >
      <div
        className={`${
          mixerOpen
            ? 'fixed bottom-0 z-40 w-full top-0 h-full pt-16 md:pt-0'
            : ''
        }`}
      >
        <div
          className={`fixed md:hidden ${
            mixerOpen ? 'top-0 right-0 mt-4 mr-4' : ''
          }`}
        >
          <button
            title="Close mixer"
            aria-label="Close mixer"
            onClick={() => setMixerOpen(false)}
            className="focus:outline-none"
          >
            <GelIcon name="close" fill="white" className="w-6 h-6" />
          </button>
        </div>
        {mixerOpen && <MixerOverlayHeader />}
        <div
          className={`h-full pb-32 overflow-y-auto${
            !mixerEditAssetId ? '  pb-32' : ''
          }`}
          style={{ paddingBottom: mixerEditAssetId ? 480 : null }}
        >
          {Object.keys(mixerAssets).map((mixerAssetId) => (
            <div
              className="flex flex-row w-full max-w-screen-xl pt-4 mx-auto text-white bg-transparent"
              key={mixerAssetId}
            >
              <MixerAudioItem
                asset={mixerAssets[mixerAssetId]}
                assetIndex={mixerAssetId}
              />
              {mixerEditAssetId === mixerAssetId && <MixerAudioEditPanel />}
            </div>
          ))}
        </div>
      </div>
      <div className="fixed w-full h-full mb-32 bg-black opacity-75" />
    </div>
  );
}

export default MixerOverlay;
