import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';

import { COOKIES_EXPIRY, COOKIE_BANNER_ACCEPTED } from '../../constants';

function CookieBanner() {
  const [accepted, setAccepted] = useState(Cookie.get(COOKIE_BANNER_ACCEPTED));
  if (accepted) return null;

  const accept = () => {
    setAccepted(true);
    Cookie.set(COOKIE_BANNER_ACCEPTED, true, { expires: COOKIES_EXPIRY });
  };

  return (
    <div className="fixed bottom-0 z-50 w-full bg-gray-900">
      <div className="max-w-screen-xl px-3 py-4 mx-auto">
        <div className="flex flex-col">
          <div className="mb-1 text-lg font-bold text-white">
            Cookies on this DIGWIX Website
          </div>
          <p className="max-w-2xl text-sm text-white">
            We use cookies and local storage to deliver the services available
            on this site. Choose ‘more info’ (below) for the detail on cookies
            and local storage on the Sound Effects website.
          </p>
        </div>
        <div className="flex flex-row items-center justify-between mt-4 sm:justify-start">
          <button
            type="button"
            onClick={accept}
            className="px-6 py-2 mr-5 font-bold text-white bg-teal-700 sm:px-3 sm:py-1 sm:text-sm hover:opacity-75 focus:opacity-75"
          >
            Close
          </button>
          <div className="flex flex-col sm:flex-row">
            <Link
              to="/cookies"
              className="text-sm font-bold text-white hover:underline focus:underline sm:mr-5"
            >
              More Info
            </Link>
            <a
              href="https://www.bbc.co.uk/usingthebbc/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm font-bold text-white hover:underline focus:underline sm:mr-5"
            >
              DIGWIX Privacy Policy
            </a>
            <a
              href="https://www.bbc.co.uk/terms"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm font-bold text-white hover:underline focus:underline"
            >
              DIGWIX Terms of Use
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookieBanner;
