import {
  GET_SFX_DATA_ASYNC,
  GET_SFX_DATA_SUCCESS,
  GET_SFX_DATA_ERROR,
  GET_CATEGORY_AGGREGATIONS_SUCCESS,
  GET_CATEGORY_AGGREGATIONS_ERROR,
  CLEAR_SEARCH_RESULTS,
  ECHO_ANALYTICS_INITIALISE,
  SET_SELECTED_ASSET,
  TOGGLE_RATING_MODAL,
  TOGGLE_AUTOPLAY,
  REQUEST_ASYNC,
  REQUEST_ENDED,
  OPEN_MSS,
  CLOSE_MSS,
} from './actionTypes';

export default (state, action) => {
  switch (action.type) {
    case OPEN_MSS:
      return {
        ...state,
        mssOpen: true,
        mssAction: action.action,
        mss: action.mss,
      };
    case CLOSE_MSS:
      return {
        ...state,
        mssOpen: false,
        mss: '',
      };
    case REQUEST_ASYNC:
      return {
        ...state,
        apiLoading: true,
      };
    case REQUEST_ENDED:
      return {
        ...state,
        apiLoading: false,
      };
    case GET_SFX_DATA_ASYNC:
      return {
        ...state,
        resultsLoading: true,
      };
    case GET_SFX_DATA_SUCCESS:
      return {
        ...state,
        resultsLoading: false,
        results: action.results,
        resultsLength: action.resultsLength,
      };
    case GET_SFX_DATA_ERROR:
      return {
        ...state,
        resultsLoading: false,
        error: action.err,
      };
    case GET_CATEGORY_AGGREGATIONS_SUCCESS: {
      return {
        ...state,
        categoryAggregations: action.categoryAggregations,
      };
    }
    case GET_CATEGORY_AGGREGATIONS_ERROR: {
      return {
        ...state,
        categoryAggregations: [],
      };
    }
    case ECHO_ANALYTICS_INITIALISE:
      return {
        ...state,
        echo: action.echo,
      };
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        resultsLoading: true,
        results: [],
        resultsLength: 0,
        error: '',
      };
    case SET_SELECTED_ASSET:
      return {
        ...state,
        selectedAsset: action.asset,
      };
    case TOGGLE_RATING_MODAL:
      return {
        ...state,
        showRatingModal: action.val,
      };
    case TOGGLE_AUTOPLAY:
      return {
        ...state,
        autoplay: !state.autoplay,
      };
    default:
      return state;
  }
};
