import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ShowMoreResults from './ShowMoreResults';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';

import SearchResultItem from '../../Components/SearchResultItem';
import MixerOverlay from '../../Components/Mixer/MixerOverlay';
import MixerStickyBar from '../../Components/Mixer/MixerStickyBar';
import AutoplayToggle from '../../Components/AutoplayToggle';

function SearchResults({ history }) {
  const {
    state: { results, resultsLoading, autoplaySupported },
    searchByQuery,
    clearSearchResults,
    setSelectedAsset,
    echoTrack,
  } = useContext(AppContext);
  const {
    state: { mixerModeOn },
    setMixerPlaying,
    toggleMixer,
  } = useContext(MixerContext);

  // Load results on mount
  useEffect(() => {
    echoTrack('search');

    // Scroll to top on mount
    window.scrollTo(0, 0);

    searchByQuery();

    // Cleanup upon leaving search results
    return () => {
      setSelectedAsset(null);
      setMixerPlaying(false);

      if (history.location.pathname !== '/favourites') {
        toggleMixer(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Perform search when query changes
  useEffect(
    () =>
      history.listen((location) => {
        if (location.pathname === '/search') {
          searchByQuery();
        } else {
          // Else clear the search results from state as the user is navigating away
          clearSearchResults();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    [history]
  );

  return (
    <>
      <Helmet title="Search Results | soundwix Sound Effects" />
      <div
        className={`${
          mixerModeOn ? 'bg-gray-700' : 'bg-gray-100'
        } transition-all duration-200 ease-in-out`}
      >
        <div className="max-w-screen-xl min-h-screen px-0 mx-auto lg:px-3">
          {autoplaySupported && (
            <div className="flex-row-reverse hidden w-full px-2 pb-2 lg:px-0 md:flex">
              <AutoplayToggle />
            </div>
          )}
          {results.length === 0 && resultsLoading ? (
            <div className="py-10 text-2xl font-bold text-center">
              Loading...
            </div>
          ) : (
            <>
              {results.length > 0 ? (
                <>
                  {results.map((asset) => (
                    <SearchResultItem key={asset.id} asset={asset} />
                  ))}
                  <ShowMoreResults />
                </>
              ) : (
                <div className="py-10 text-2xl font-bold text-center">
                  No results found
                </div>
              )}
            </>
          )}
        </div>
        <MixerStickyBar />
        {mixerModeOn && <MixerOverlay />}
      </div>
    </>
  );
}

SearchResults.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default withRouter(SearchResults);
