import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';

import BaseModal from '../BaseModal';
import GelIcon from '../GelIcon';
import SocialIcons from '../SocialIcons';

import { APP_URL, THEME_COLOURS } from '../../constants';

function ShareModal({ asset, setShowShareModal }) {
  const { echoTrack } = useContext(AppContext);
  const inputRef = useRef(null);
  const [copyText, setCopyText] = useState('Copy');

  const handleCopy = () => {
    echoTrack('audioplayer', { action: 'asset_share_copied' }, 'click');

    inputRef.current.removeAttribute('disabled');

    inputRef.current.select();
    inputRef.current.setSelectionRange(0, 99999);

    document.execCommand('copy');

    inputRef.current.setAttribute('disabled', true);

    setCopyText('Copied');
  };

  return (
    <BaseModal labelId="share_modal_title" descriptionId="share_modal_desc">
      <p className="sr-only" id="share_modal_desc">
        Share the selected sound via a URL or social media.
      </p>
      <div className="px-6 py-8 text-left sm:px-8">
        <div className="flex items-center justify-between pb-3">
          <div className="flex flex-row items-center space-x-3">
            <GelIcon
              name="share"
              fill={THEME_COLOURS.PRIMARY}
              className="w-4 h-4"
            />
            <span
              className="text-2xl font-bold text-gray-900"
              id="share_modal_title"
            >
              Share
            </span>
          </div>
          <button
            type="button"
            className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300"
            onClick={() => setShowShareModal(false)}
            aria-label="Close"
          >
            <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
          </button>
        </div>
        <div>
          <p className="mb-3 text-gray-900">Page link</p>
          <div className="relative flex flex-row items-center">
            <div className="absolute top-0 flex flex-col justify-center h-full ml-3">
              <GelIcon
                name="link"
                fill={THEME_COLOURS.PRIMARY}
                className="w-3 h-3 opacity-75"
              />
            </div>
            <label className="sr-only" htmlFor="share-sound">
              Share sound
            </label>
            <input
              id="share-sound"
              ref={inputRef}
              className="w-full py-3 pl-8 pr-2 text-gray-900 bg-gray-100"
              disabled
              type="text"
              value={`${APP_URL}/search?q=${asset.id}`}
            />
            <button
              type="button"
              className="px-4 py-3 font-bold text-white bg-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75"
              onClick={handleCopy}
            >
              {copyText}
            </button>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end mt-8 space-x-3 sm:justify-start">
          <SocialIcons
            shareUrl={`${APP_URL}/search?q=${asset.id}`}
            shareMetadata={{ asset: asset.id }}
            className="w-6 h-6 text-white"
          />
        </div>
      </div>
    </BaseModal>
  );
}

ShareModal.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
  }),
  setShowShareModal: PropTypes.func,
};

export default ShareModal;
