import React, { useEffect, useState, useContext, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
import moment from 'moment';
import TimePicker from 'react-time-picker';
import { useTranslation } from 'react-i18next';

import GelIcon from '../GelIcon';
import PlaybackDelayInput from './PlaybackDelayInput';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import {
  THEME_COLOURS,
  WAVEFORM_URL,
  MEDIA_LOW_QUALITY_URL,
} from '../../constants';
import formatAssetDuration from '../../utilities/formatAssetDuration';
import checkIsIOS from '../../utilities/checkIsIOS';
function MixerAudioEditPanel() {
  const { echoTrack } = useContext(AppContext);
  const {
    state: { mixerEditAssetId, mixerAssets },
    setMixerEditAsset,
    setMixerAssetSettings,
  } = useContext(MixerContext);

  // Store Mixer asset to edit in local state
  const [mixerEditAsset] = useState(mixerAssets[mixerEditAssetId]);

  const [regionSeekTime, setRegionSeekTime] = useState(null);
  const [selectedSoundRegion, setSelectedSoundRegion] = useState(null);
  const [playbackTiming, setPlaybackTiming] = useState(null);
  const [regionPlaying, setRegionPlaying] = useState(false);
  const [wavesurferPlayer, setWavesurferPlayer] = useState(null);
  const [delayTimeout, setDelayTimeout] = useState(null);
  const [mixerSettingsCopy, setMixerSettingsCopy] = useState(
    mixerEditAsset.mixerSettings
  );
  const [timeError, setTimeError] = useState(null);

  const waveContainerRef = useRef();
  const { t } = useTranslation();
  // needed to disable wavesurfer.js on IE11 or earlier
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  const { isIOS } = checkIsIOS();

  // Updates the 'region' from Wavesurfer in state
  const updateRegion = (region) => {
    if (region && isNaN(region.end)) return;

    echoTrack('mixer', { action: 'mixer_region_updated' }, 'click');

    // Update region in state
    setSelectedSoundRegion({
      id: region.id,
      start: region.start,
      end: region.end,
    });
    // Update the region 'startTime' so that the play position can be retained for play/pause
    setRegionSeekTime(region.start);
  };

  const getRegionSeekTime = () => {
    const seekTimePercentage = regionSeekTime / wavesurferPlayer.getDuration();

    // Return a smaller float precision for iOS
    if (isIOS) return parseFloat(seekTimePercentage.toFixed(2));

    return seekTimePercentage;
  };

  useEffect(() => {
    if (!wavesurferPlayer && !isIE11) {
      const wavesurferInstance = WaveSurfer.create({
        container: waveContainerRef.current,
        progressColor: '#fff',
        cursorColor: '#fff',
        cursorWidth: 0,
        interact: false,
        barWidth: 2,
        barMinHeight: 1,
        responsive: true,
        hideScrollbar: true,
        height: 120,
        normalize: true,
        backend: 'MediaElement',
        plugins: [RegionsPlugin.create({ dragSelection: {} })],
      });

      wavesurferInstance.on('ready', () => {
        if (mixerSettingsCopy && mixerSettingsCopy.start) {
          wavesurferInstance.seekTo(
            mixerSettingsCopy.start / wavesurferInstance.getDuration()
          );
        }
      });

      wavesurferInstance.on('audioprocess', (secondsElapsed) => {
        setRegionSeekTime(secondsElapsed);
      });

      wavesurferInstance.on('region-created', (region) => {
        wavesurferInstance.pause();
        wavesurferInstance.regions.clear();
        updateRegion(region);
      });

      wavesurferInstance.on('region-update-end', (region) => {
        wavesurferInstance.pause();
        updateRegion(region);
        setTimeError(null);
      });

      wavesurferInstance.on('region-out', (region) => {
        wavesurferInstance.pause();
        wavesurferInstance.seekTo(
          region.start / wavesurferInstance.getDuration()
        );
      });

      wavesurferInstance.on('play', () => {
        setRegionPlaying(true);
      });

      wavesurferInstance.on('pause', () => {
        setRegionPlaying(false);
      });

      setWavesurferPlayer(wavesurferInstance);
    }

    return () => {
      clearTimeout(delayTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wavesurferPlayer, regionPlaying]);

  // Fetch the waveform JSON when the asset changes
  // Set any pre-existing range 'settings' if found
  useEffect(() => {
    if (wavesurferPlayer) {
      // Fetch waveform
      fetch(`${WAVEFORM_URL}/${mixerEditAsset.file.small.name}.json`)
        .then((res) => {
          return res.json();
        })
        .then((peaks) => {
          wavesurferPlayer.load(
            `${MEDIA_LOW_QUALITY_URL}/${mixerEditAsset.file.small.name}.mp3`,
            peaks.data,
            'metadata'
          );
        })
        .then(() => {
          // Check any existing 'mixerSettings' to pre-render the selected region
          const { mixerSettings } = mixerEditAsset;

          if (mixerSettings) {
            setMixerSettingsCopy(mixerSettings);
            wavesurferPlayer.addRegion(mixerSettings);
            setPlaybackTiming({
              delay: mixerSettings.delay,
            });
          } else {
            // Clear regions on asset change
            wavesurferPlayer.regions.clear();
            setSelectedSoundRegion(null);
            setPlaybackTiming(null);
          }
        })
        .catch((e) => {
          console.error('error', e);
        });
    }
  }, [mixerEditAsset, wavesurferPlayer]);

  const handleRegionPlayback = () => {
    if (!regionPlaying) {
      // wavesurferPlayer.getCurrentTime() returns as 6 decimal places
      // wavesruferRegion.start is typically 17 decimal places
      const playerTime = wavesurferPlayer.getCurrentTime() * 1000000 || 0;
      const regionStart = Math.floor(selectedSoundRegion.start * 1000000) || 0;

      // If there is a delay, only play the delay if player is at the beginning of the region
      setRegionPlaying(true);
      if (
        playbackTiming &&
        playbackTiming.delay &&
        playerTime === regionStart
      ) {
        setDelayTimeout(
          setTimeout(() => {
            wavesurferPlayer.seekTo(getRegionSeekTime());
            wavesurferPlayer.play();
          }, parseInt(playbackTiming.delay) * 1000)
        );
      } else {
        wavesurferPlayer.seekTo(getRegionSeekTime());
        wavesurferPlayer.play();
      }
    } else {
      clearTimeout(delayTimeout);
      setRegionPlaying(false);
      wavesurferPlayer.pause();
    }
  };

  const handleAudioTrim = () => {
    // Handle user error of dragging too far to the left if dragging backwards
    if (selectedSoundRegion) {
      if (selectedSoundRegion.start < 0) {
        selectedSoundRegion.start = 0;
      }
    }

    setMixerAssetSettings({
      start: selectedSoundRegion ? selectedSoundRegion.start : null,
      end: selectedSoundRegion ? selectedSoundRegion.end : null,
      delay: playbackTiming ? playbackTiming.delay : null,
    });

    // Seek to start of region
    if (
      selectedSoundRegion &&
      wavesurferPlayer &&
      wavesurferPlayer.getDuration()
    ) {
      wavesurferPlayer.seekTo(
        selectedSoundRegion.start / wavesurferPlayer.getDuration()
      );
    }
  };

  const handleCancel = () => {
    // If cancelled, revert to the original Mixer settings if available
    if (mixerSettingsCopy && mixerSettingsCopy.start) {
      const { start, end, delay } = mixerSettingsCopy;

      setMixerAssetSettings({
        start,
        end,
        delay,
      });
    } else {
      // Else clear the settings
      setMixerAssetSettings(null);
    }

    // Close the edit panel
    setMixerEditAsset(null);
  };

  const handleRemoveTrim = () => {
    // Remove region
    wavesurferPlayer.regions.clear();

    // Seek to 0 to remove any progress styling
    wavesurferPlayer.pause();
    wavesurferPlayer.seekTo(0);

    // Remove region from state
    setSelectedSoundRegion(null);

    // Clear the settings
    setMixerAssetSettings(null);
  };

  const handleSave = () => {
    if (!selectedSoundRegion) {
      // Clear range in state
      setMixerAssetSettings({
        start: null,
        end: null,
        delay: playbackTiming ? playbackTiming.delay : null,
      });
    } else if (!playbackTiming || !playbackTiming.delay) {
      // Else clear delay in state, while selectedSoundRegion object exists
      setMixerAssetSettings({
        start: selectedSoundRegion.start,
        end: selectedSoundRegion.end,
        delay: null,
      });
    } else {
      // Else set both range and delay in state
      handleAudioTrim();
    }

    // Close dialog
    setMixerEditAsset(null);
  };

  // Format time for the <input type='time' /> field
  const formatTime = (duration) => {
    return moment.utc(duration).format('HH:mm:ss');
  };

  // Handle changes to the time inputs
  const handleManualTimeChange = (field, time) => {
    echoTrack('mixer', { action: 'mixer_time_input' }, 'click');

    // Convert to seconds
    const seconds = moment.duration(time).asSeconds();

    // If time is greater than duration of the asset, do nothing
    if (seconds * 1000 > mixerEditAsset.duration) {
      setTimeError((prevVal) => ({ ...prevVal, [field]: true }));
      return;
    } else {
      setTimeError(null);
    }

    if (field === 'start') {
      // If no region, create it first
      if (!selectedSoundRegion) {
        wavesurferPlayer.addRegion({
          start: seconds,
          end: moment.duration(time).add(5, 'seconds').asSeconds(),
        });
      } else {
        // Clear and then create the new one
        wavesurferPlayer.regions.clear();

        if (seconds >= selectedSoundRegion.end) {
          wavesurferPlayer.addRegion({
            start: seconds,
            end: seconds + 1,
          });
        } else {
          // If the input value is after the 'end' time, stop
          if (seconds > selectedSoundRegion.end) {
            setTimeError((prevVal) => ({ ...prevVal, [field]: true }));
            return;
          }

          wavesurferPlayer.addRegion({
            start: seconds === 0 ? 0.1 : seconds, // Regions can't seem to be set at exactly 0
            end:
              seconds === selectedSoundRegion.end
                ? moment.duration(time).add(5, 'seconds').asSeconds()
                : selectedSoundRegion.end,
          });
        }
      }
    }

    if (field === 'end') {
      // If the input value equals 0, do nothing
      if (seconds === 0) return;

      if (!selectedSoundRegion) {
        const startSeconds =
          seconds <= 6
            ? 0
            : moment.duration(time).subtract(5, 'seconds').asSeconds();

        wavesurferPlayer.addRegion({
          start: startSeconds <= 0 ? 0.1 : startSeconds, // Regions can't seem to be set at exactly 0
          end: seconds,
        });
      } else {
        // If the input value is before the 'start' time, stop
        if (seconds < selectedSoundRegion.start) {
          setTimeError((prevVal) => ({ ...prevVal, [field]: true }));
          return;
        }

        wavesurferPlayer.regions.clear();

        wavesurferPlayer.addRegion({
          start:
            seconds === selectedSoundRegion.start
              ? moment.duration(time).subtract(5, 'seconds').asSeconds()
              : selectedSoundRegion.start,
          end: seconds,
        });
      }
    }
  };

  const disablePreview =
    !selectedSoundRegion || selectedSoundRegion.start > selectedSoundRegion.end;
  const disableRemoveRange = !selectedSoundRegion;
  const invalidDelay =
    !!(
      playbackTiming &&
      playbackTiming.delay &&
      playbackTiming.delay.includes('.')
    ) ||
    (playbackTiming && playbackTiming.delay && isNaN(playbackTiming.delay));

  const disableSave =
    (selectedSoundRegion &&
      selectedSoundRegion.start > selectedSoundRegion.end) ||
    timeError ||
    invalidDelay;

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-gray-900 md:h-auto md:top-auto disable-dbl-tap-zoom">
      <div className="flex flex-col items-start w-full h-full max-w-screen-xl px-3 py-6 mx-auto">
        <div className="flex flex-row items-center justify-between w-full mb-4">
          <div className="flex flex-row items-center justify-center space-x-3">
            <GelIcon className="w-4 h-4 mx-1" fill="white" name="edit" />
            <span className="text-xl font-bold text-white">Edit Sound</span>
          </div>
          <button
            type="button"
            className="focus:outline-none md:hidden"
            onClick={handleCancel}
            aria-label="Close edit panel"
            title="Close edit panel"
          >
            <GelIcon className="w-6 h-6" fill="white" name="close" />
          </button>
        </div>
        <div className="w-full mb-4">
          <p className="text-white truncate">{mixerEditAsset.description}</p>
        </div>
        <div className="w-full mb-4 space-y-4 text-white">
          <div className="flex flex-col items-start w-full mt-4 md:items-center md:flex-row">
            {!isIE11 && (
              <div className="flex flex-col items-start md:items-center md:flex-row">
                <div className="mr-4 font-semibold lg:mr-8">Sound Range</div>
                <div className="flex flex-row mt-4 md:mt-0">
                  <div className="flex flex-row items-center mr-4 md:mr-0">
                    <div
                      className={`relative flex flex-row px-3 py-2 border-2 border-${
                        timeError && timeError.start ? 'red-500' : 'white'
                      } md:px-4`}
                    >
                      {timeError && timeError.start && (
                        <span
                          className="absolute left-0 w-full text-xs font-bold text-red-500"
                          style={{ top: -22 }}
                        >
                          Invalid &apos;From&apos; value
                        </span>
                      )}
                      <span className="mr-4 font-semibold md:text-sm">
                        From
                      </span>
                      <TimePicker
                        disableClock
                        clearIcon={null}
                        name="audio-start"
                        id="audio-start"
                        className="w-20 text-white bg-transparent md:w-16 md:text-sm"
                        value={
                          selectedSoundRegion && selectedSoundRegion.start
                            ? formatTime(selectedSoundRegion.start * 1000)
                            : '00:00:00'
                        }
                        onChange={(val) => handleManualTimeChange('start', val)}
                        format="HH:mm:ss"
                        minTime="00:00:00"
                        maxDetail="second"
                        nativeInputAriaLabel="Time"
                        hourAriaLabel="Hour"
                        minuteAriaLabel="Minute"
                        secondAriaLabel="Second"
                      />
                    </div>
                    <GelIcon
                      className="hidden w-4 h-4 mx-4 md:block md:w-5 md:h-5"
                      name="scissors"
                      fill="white"
                    />
                  </div>
                  <div
                    className={`relative flex flex-row px-3 py-2 border-2 border-${
                      timeError && timeError.end ? 'red-500' : 'white'
                    } md:px-4`}
                  >
                    {timeError && timeError.end && (
                      <span
                        className="absolute left-0 w-full text-xs font-bold text-red-500"
                        style={{ top: -22 }}
                      >
                        Invalid &apos;To&apos; value
                      </span>
                    )}
                    <span className="mr-4 font-semibold md:text-sm">To</span>
                    <TimePicker
                      disableClock
                      clearIcon={null}
                      name="audio-end"
                      id="audio-end"
                      className="w-20 text-white bg-transparent md:w-16 md:text-sm"
                      value={
                        selectedSoundRegion && selectedSoundRegion.end
                          ? formatTime(selectedSoundRegion.end * 1000)
                          : '00:00:00'
                      }
                      onChange={(val) => handleManualTimeChange('end', val)}
                      format="HH:mm:ss"
                      minTime="00:00:00"
                      maxDetail="second"
                      nativeInputAriaLabel="Time"
                      hourAriaLabel="Hour"
                      minuteAriaLabel="Minute"
                      secondAriaLabel="Second"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex-row items-center hidden ml-4 md:flex">
              <PlaybackDelayInput
                playbackTiming={playbackTiming}
                setPlaybackTiming={setPlaybackTiming}
                invalidDelay={invalidDelay}
                displayType="desktop"
              />
            </div>
          </div>
          {!isIE11 && (
            <div className="flex flex-row items-center lg:w-2/3">
              <div className="relative z-0 w-full h-full">
                <div ref={waveContainerRef} />
              </div>
              <div className="px-4 text-sm">
                {formatAssetDuration(mixerEditAsset.duration)}
              </div>
            </div>
          )}
          <div className="flex flex-row items-center mb-4 md:hidden">
            <PlaybackDelayInput
              playbackTiming={playbackTiming}
              setPlaybackTiming={setPlaybackTiming}
              invalidDelay={invalidDelay}
              displayType="mobile"
            />
          </div>
        </div>
        <div className="flex flex-row justify-between w-full text-white">
          {!isIE11 && (
            <div className="flex flex-row space-x-4">
              <button
                type="button"
                className={`mr-2 focus:outline-none px-3 w-24 py-2 bg-white text-gray-900 hover:opacity-75 focus:opacity-75${
                  disablePreview ? ' opacity-75 cursor-not-allowed' : ''
                }`}
                onMouseUp={handleRegionPlayback}
                disabled={disablePreview}
              >
                {!regionPlaying ? 'Preview' : 'Pause'}
              </button>
              <button
                type="button"
                className={`focus:outline-none px-3 py-2 bg-white text-gray-900 hover:opacity-75 focus:opacity-75${
                  disableRemoveRange ? ' opacity-75 cursor-not-allowed' : ''
                }`}
                onMouseUp={handleRemoveTrim}
                disabled={disableRemoveRange}
              >
                Remove range
              </button>
            </div>
          )}
          <div className="fixed bottom-0 left-0 flex flex-row w-full md:left-auto md:space-x-4 md:w-72 md:relative md:bottom-auto">
            <button
              type="button"
              className="flex-row items-center hidden px-3 py-2 space-x-4 bg-gray-800 focus:outline-none md:mr-2 md:flex hover:opacity-75 focus:opacity-75"
              onMouseUp={handleCancel}
            >
              <span>{t('cancel')}</span>
            </button>
            <button
              type="button"
              className="flex flex-row items-center justify-center w-full px-3 py-3 space-x-4 bg-black focus:outline-none md:py-2 md:hidden hover:opacity-75 focus:opacity-75"
              onMouseUp={handleCancel}
            >
              <GelIcon className="w-4 h-4" name="chevron-left" fill="white" />
              <span>Back to Mixer</span>
            </button>
            <button
              type="button"
              className={`focus:outline-none flex flex-row items-center justify-center w-full px-3 py-3 space-x-4 bg-white text-gray-900 md:w-48 md:py-2 hover:opacity-75 focus:opacity-75${
                disableSave ? ' opacity-75 cursor-not-allowed' : ''
              }`}
              onMouseUp={handleSave}
              disabled={disableSave}
            >
              <GelIcon
                className="w-4 h-4"
                name="save"
                fill={THEME_COLOURS.PRIMARY}
              />
              <span>Save Changes</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MixerAudioEditPanel;
