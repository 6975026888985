import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Signin } from '../../Route/Route';
import styled from 'styled-components';
import Button from '../../Components/Button';
import { UserContext } from '../../Context/UserContext';

const Title = styled.div`
  font-size: 4rem;
  font-weight: 600;
  font-family: Inter;
  margin-bottom: 5rem;
`;

const Label = styled.label`
  color: white;
  font-size: 2rem;
  margin-right: 15px;
  font-family: Inter;
`;

const LoginWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  max-width: 1500px;
  padding: 10rem 0;
  align-items: center;
  input {
    color: black;
  }
`;

const Login = () => {
  const { t } = useTranslation();
  const [inputId, setInputId] = useState('');
  const [inputPw, setInputPw] = useState('');
  const [ErrMss, setErrMss] = useState('');
  const { setUserInfo } = useContext(UserContext);
  const navigate = useHistory();

  const ErrorMessage = (data) => {
    setErrMss(data);
  };

  // input data 의 변화가 있을 때마다 value 값을 변경해서 useState 해준다
  const handleInputId = (e) => {
    setInputId(e.target.value);
  };

  const handleInputPw = (e) => {
    setInputPw(e.target.value);
  };

  const onLogin = async () => {
    if (inputId.length === 0 || inputPw.length === 0) {
      ErrorMessage(
        inputId.length === 0 ? t('signin.fill_id') : t('signin.fill_pw')
      );
      return;
    }

    const res = await Signin({ account: inputId, password: inputPw });
    if (res.status !== 200) {
      ErrorMessage(t('signin.fail'));
      //에러
    } else {
      console.log('success');
      setUserInfo(res.data?.user);
      setInputId('');
      setInputPw('');
      navigate.push('/search');
    }
  };

  const onSignup = () => {
    navigate.push('/sign-up');
  };

  // 페이지 렌더링 후 가장 처음 호출되는 함수
  useEffect(
    () => {
      // axios.get('/user_inform/login')
      // .then(res => console.log(res))
      // .catch()
    },
    // 페이지 호출 후 처음 한번만 호출될 수 있도록 [] 추가
    []
  );

  return (
    <>
      <Helmet title="SOUNDWIX | LOGIN" />
      <LoginWrapper>
        <LoginContainer>
          <Title>LOGIN</Title>
          <div className="text-base mt-2">
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  width: '4rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Label>ID</Label>
              </div>
              <div>
                <input
                  type="text"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  name="input_id"
                  autoComplete="off"
                  value={inputId}
                  onChange={handleInputId}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  width: '4rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Label>PW</Label>
              </div>
              <div>
                <input
                  type="password"
                  name="input_pw"
                  autoComplete="off"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  value={inputPw}
                  onChange={handleInputPw}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: '3rem',
              color: 'red',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontSize: '1.5rem',
              marginLeft: '2rem',
            }}
          >
            {ErrMss}
          </div>
          <div
            className="flex justify-end items-end text-base mt-4"
            style={{
              color: 'White',
              marginLeft: '2rem',
              marginBottom: '3rem',
              marginTop: '2rem',
            }}
          >
            <Button onClick={onLogin}>LOGIN</Button>
          </div>
          <div
            className="flex justify-end items-end text-base mt-4"
            style={{
              color: 'White',
              fontFamily: 'Inter',
              marginLeft: '2rem',
              fontSize: '1.25rem',
            }}
          >
            Don't have an account?
            <br />
            <button
              type="button"
              style={{ margin: '0 0 0 5px', fontSize: '1.5rem' }}
              onClick={onSignup}
            >
              SIGN UP
            </button>
          </div>
        </LoginContainer>
      </LoginWrapper>
    </>
  );
};

export default Login;
