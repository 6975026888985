import React, {
  useEffect,
  useReducer,
  createContext,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Cookie from 'js-cookie';
import i18next from 'i18next';

import AppReducer from './AppReducer';
import {
  GET_SFX_DATA_ASYNC,
  GET_SFX_DATA_SUCCESS,
  GET_SFX_DATA_ERROR,
  GET_CATEGORY_AGGREGATIONS_ASYNC,
  GET_CATEGORY_AGGREGATIONS_SUCCESS,
  GET_CATEGORY_AGGREGATIONS_ERROR,
  CLEAR_SEARCH_RESULTS,
  SET_SELECTED_ASSET,
  TOGGLE_RATING_MODAL,
  TOGGLE_AUTOPLAY,
  REQUEST_ASYNC,
  REQUEST_ENDED,
  OPEN_MSS,
  CLOSE_MSS,
} from './actionTypes';

import initialState from './initialState';
import { API_URL, COOKIE_RATING_SUBMITTED } from '../constants';
import { FavouritesContext } from './FavouritesContext';
import queryBuilder, {
  queryBuilderByCategory,
} from '../utilities/queryBuilder';
import useCustomSnackbars from '../hooks/useCustomSnackbars';

export const AppContext = createContext(initialState);

// Provider component
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const {
    state: { favouriteIds },
  } = useContext(FavouritesContext); // Necessary for aggregations
  const { openErrorSnackbar } = useCustomSnackbars();

  // Clears search results from state
  function clearSearchResults() {
    dispatch({ type: CLEAR_SEARCH_RESULTS });
  }

  function echoTrack(pageName, eventData = null, actionName) {
    console.log(pageName, eventData, actionName);
    return false;
  }

  function searchByCategory(category) {
    const criteria = queryBuilderByCategory(category);
    criteria.loc = i18next.language;

    echoTrack(
      'searchquery',
      { action: 'search_query', data: { query: criteria } },
      'click'
    );

    let searchUrl = `${API_URL}/api/sfx/search`;

    dispatch({
      type: GET_SFX_DATA_ASYNC,
    });

    fetch(searchUrl, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ criteria }),
    })
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: GET_SFX_DATA_SUCCESS,
          results: data.results,
          resultsLength: data.total,
        })
      )
      .catch((err) => {
        console.log(err);
        openErrorSnackbar(
          'An error occurred when trying to fetch your search results. Please try again.',
          4000
        );

        dispatch({
          type: GET_SFX_DATA_ERROR,
          err,
        });
      });
  }

  function searchByQuery(queryOverride) {
    let criteria = queryBuilder();
    console.log(criteria);
    // Override the search query if provided
    // Favourites page uses this to pass through favouriteIds from localStorage
    if (queryOverride) {
      criteria = {
        ...criteria,
        ...queryOverride,
      };

      if (criteria.favourite.length === 0) {
        dispatch({
          type: GET_SFX_DATA_SUCCESS,
          results: [],
          resultsLength: 0,
        });
        return;
      }
    }
    criteria.loc = i18next.language;

    echoTrack(
      'searchquery',
      { action: 'search_query', data: { query: criteria } },
      'click'
    );

    let searchUrl = `${API_URL}/api/sfx/search`;

    dispatch({
      type: GET_SFX_DATA_ASYNC,
    });

    fetch(searchUrl, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ criteria }),
    })
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: GET_SFX_DATA_SUCCESS,
          results: data.results,
          resultsLength: data.total,
        })
      )
      .catch((err) => {
        console.log(err);
        openErrorSnackbar(
          'An error occurred when trying to fetch your search results. Please try again.',
          4000
        );

        dispatch({
          type: GET_SFX_DATA_ERROR,
          err,
        });
      });
  }

  function getAggregations(pathname) {
    const criteria = queryBuilder();

    // If in favourites, override the query term and use the favourites from localStorage
    if (pathname === '/favourites') {
      criteria.favourite = favouriteIds;
    }

    criteria.loc = i18next.language;

    const AGGREGATION_TYPES = {
      reducerField: 'categoryAggregations',
      endpoint: `/categoryAggregations`,
      actionTypes: [
        GET_CATEGORY_AGGREGATIONS_ASYNC,
        GET_CATEGORY_AGGREGATIONS_SUCCESS,
        GET_CATEGORY_AGGREGATIONS_ERROR,
      ],
    };

    dispatch({ type: AGGREGATION_TYPES.actionTypes[0] });
    fetch(`${API_URL}/api/sfx${AGGREGATION_TYPES.endpoint}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ criteria }),
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: AGGREGATION_TYPES.actionTypes[1],
          [AGGREGATION_TYPES.reducerField]: data.aggregations,
        });
      })
      .catch((err) =>
        dispatch({
          type: AGGREGATION_TYPES.actionTypes[2],
          err,
        })
      );
  }

  function getCachedCategoryAggregations() {
    dispatch({ type: GET_CATEGORY_AGGREGATIONS_ASYNC });
    //fetch(`${API_URL}/api/sfx/cached/categoryaggregations`)
    fetch(`${API_URL}/api/sfx/categoryAggregations`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ criteria: { loc: i18next.language }, type: 0 }),
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: GET_CATEGORY_AGGREGATIONS_SUCCESS,
          categoryAggregations: data.aggregations,
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_CATEGORY_AGGREGATIONS_ERROR,
          err,
        })
      );
  }

  function setSelectedAsset(asset) {
    dispatch({
      type: SET_SELECTED_ASSET,
      asset,
    });
  }

  function submitRating(rating) {
    echoTrack(
      'rating',
      { action: 'submitted_rating', data: { rating } },
      'click'
    );

    Cookie.set(COOKIE_RATING_SUBMITTED, true, { expires: 30 });
  }

  function toggleRatingModal(val) {
    dispatch({ type: TOGGLE_RATING_MODAL, val });
  }

  function toggleAutoplay() {
    dispatch({ type: TOGGLE_AUTOPLAY });
  }

  // Initialise Echo if not initialised already
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.echo]);

  function startRequest() {
    dispatch({ type: REQUEST_ASYNC });
  }

  function endRequest() {
    dispatch({ type: REQUEST_ENDED });
  }

  function openMss(mss, action = null) {
    dispatch({ type: OPEN_MSS, mss, action });
  }

  function closeMss() {
    if (state.mssAction) {
      state.mssAction();
    }
    dispatch({ type: CLOSE_MSS });
  }

  const ctx = useMemo(
    () => ({
      state,
      dispatch,
      searchByCategory,
      searchByQuery,
      getAggregations,
      getCachedCategoryAggregations,
      clearSearchResults,
      echoTrack,
      setSelectedAsset,
      submitRating,
      toggleRatingModal,
      toggleAutoplay,
      startRequest,
      endRequest,
      openMss,
      closeMss,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [state]
  );

  return <AppContext.Provider value={ctx}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.shape({}),
};

export function withContext(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <AppContext.Consumer>
      {(context) => <Component {...props} context={context} />}
    </AppContext.Consumer>
  );
}
