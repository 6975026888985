import React, { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import initialState from './initialState';
import { UPDATE_FAVOURITES } from '../actionTypes';
import { FAVOURITES_CONFIG } from '../../constants';
import FavouritesReducer from './FavouritesReducer';
import useCustomSnackbars from '../../hooks/useCustomSnackbars';

export const FavouritesContext = createContext(initialState);

export const FavouritesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FavouritesReducer, initialState);
  const { openDefaultSnackbar, openErrorSnackbar } = useCustomSnackbars();

  // Updates favourites on both add and remove and messages accordingly
  function updateFavourites(favouriteIds) {
    if (favouriteIds.length <= FAVOURITES_CONFIG.sizeLimit) {
      openDefaultSnackbar(
        `Sound ${
          favouriteIds.length > state.favouriteIds.length
            ? 'added to'
            : 'removed from'
        } favourites`
      );
      dispatch({
        type: UPDATE_FAVOURITES,
        favouriteIds,
      });
    } else {
      openErrorSnackbar('Error - favourites limit reached');
    }
  }

  // Batch add favourites by asset ID
  function addAssetsToFavourites(newAssetIds) {
    const newFavouriteIds = [...state.favouriteIds, ...newAssetIds];

    const uniqueFavouriteIds = _.uniq(newFavouriteIds);

    if (uniqueFavouriteIds.length <= FAVOURITES_CONFIG.sizeLimit) {
      dispatch({
        type: UPDATE_FAVOURITES,
        favouriteIds: uniqueFavouriteIds,
      });
      openDefaultSnackbar('Sounds added to favourites successfully');
    } else {
      openErrorSnackbar(
        'Error - adding sounds will exceed favourites limit of 100. Import aborted.'
      );
    }
  }

  // Batch remove favourites by asset ID
  function removeAssetsFromFavourites(assetIdsToRemove) {
    const allFavourites = state.favouriteIds;
    // const mixerFavourites = Object.keys(state.mixerAssets).map(mixerId => state.mixerAssets[mixerId].id);

    const newFavouriteIds = allFavourites.filter((el) => {
      return !assetIdsToRemove.includes(el);
    });

    dispatch({
      type: UPDATE_FAVOURITES,
      favouriteIds: newFavouriteIds,
    });

    openDefaultSnackbar('Sounds removed from favourites successfully');
  }

  const ctx = useMemo(
    () => ({
      state,
      dispatch,
      updateFavourites,
      addAssetsToFavourites,
      removeAssetsFromFavourites,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [state]
  );

  return (
    <FavouritesContext.Provider value={ctx}>
      {children}
    </FavouritesContext.Provider>
  );
};

FavouritesProvider.propTypes = {
  children: PropTypes.shape({}),
};
