export const GET_SFX_DATA_ASYNC = 'GET_SFX_DATA_ASYNC';
export const GET_SFX_DATA_SUCCESS = 'GET_SFX_DATA_SUCCESS';
export const GET_SFX_DATA_ERROR = 'GET_SFX_DATA_ERROR';

export const GET_CATEGORY_AGGREGATIONS_ASYNC =
  'GET_CATEGORY_AGGREGATIONS_ASYNC';
export const GET_CATEGORY_AGGREGATIONS_SUCCESS =
  'GET_CATEGORY_AGGREGATIONS_SUCCESS';
export const GET_CATEGORY_AGGREGATIONS_ERROR =
  'GET_CATEGORY_AGGREGATIONS_ERROR';

export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';

export const UPDATE_FAVOURITES = 'UPDATE_FAVOURITES';

export const ECHO_ANALYTICS_INITIALISE = 'ECHO_ANALYTICS_INITIALISE';

export const SET_SELECTED_ASSET = 'SET_SELECTED_ASSET';

export const SET_MIXER_MODE = 'SET_MIXER_MODE';
export const SET_MIXER_PLAYING = 'SET_MIXER_PLAYING';
export const SET_MIXER_PLAYBACK_RESET = 'SET_MIXER_PLAYBACK_RESET';
export const SET_MIXER_OPEN = 'SET_MIXER_OPEN';
export const SET_MIXER_EDIT_ASSET = 'SET_MIXER_EDIT_ASSET';
export const UPDATE_MIXER_ASSETS = 'UPDATE_MIXER_ASSETS';
export const SET_MIXER_ASSET_SETTINGS = 'SET_MIXER_ASSET_SETTINGS';
export const ADD_PLAYING_MIXER_ASSET = 'ADD_PLAYING_MIXER_ASSET';
export const REMOVE_PLAYING_MIXER_ASSET = 'REMOVE_PLAYING_MIXER_ASSET';
export const ADD_READY_MIXER_ASSET = 'ADD_READY_MIXER_ASSET';
export const REMOVE_READY_MIXER_ASSET = 'REMOVE_READY_MIXER_ASSET';

export const IMPORT_MIX_ASYNC = 'IMPORT_MIX_ASYNC';
export const IMPORT_MIX_SUCCESS = 'IMPORT_MIX_SUCCESS';
export const IMPORT_MIX_ERROR = 'IMPORT_MIX_ERROR';

export const TOGGLE_RATING_MODAL = 'TOGGLE_RATING_MODAL';

export const TOGGLE_AUTOPLAY = 'TOGGLE_AUTOPLAY';

export const REQUEST_ASYNC = 'REQUEST_ASYNC';
export const REQUEST_ENDED = 'REQUEST_ENDED';
export const OPEN_MSS = 'OPEN_MSS';
export const CLOSE_MSS = 'CLOSE_MSS';

export const SET_USER_INFO = 'SET_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
