import React from 'react';
import PropTypes from 'prop-types';
import ReactFocusLock from 'react-focus-lock';

const widthClasses = {
  'sm': 'sm:max-w-sm',
  'md': 'sm:max-w-md',
  'lg': 'sm:max-w-lg',
  'xl': 'sm:max-w-xl',
  '2xl': 'sm:max-w-2xl',
  '3xl': 'sm:max-w-3xl',
  '5xl': 'sm:max-w-5xl',
};

function BaseModal({
  children,
  width = 'lg',
  autofocus = false,
  labelId,
  descriptionId,
}) {
  return (
    <div className="absolute z-50">
      <div className="fixed top-0 left-0 w-full h-screen bg-black opacity-75" />
      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <ReactFocusLock autoFocus={autofocus}>
        <div
          className="fixed bottom-0 left-0 flex flex-row items-end justify-center w-full h-screen sm:items-center md:top-0"
          role="dialog"
          aria-labelledby={labelId}
          aria-describedby={descriptionId}
        >
          <div
            className={`w-full max-h-full overflow-auto text-center bg-white ${widthClasses[width]}`}
          >
            {children}
          </div>
        </div>
      </ReactFocusLock>
    </div>
  );
}

BaseModal.propTypes = {
  children: PropTypes.shape({}),
  width: PropTypes.string,
  autofocus: PropTypes.bool,
  labelId: PropTypes.string.isRequired,
  descriptionId: PropTypes.string,
};

export default BaseModal;
