import React, { useContext } from 'react';
import { THEME_COLOURS } from '../../constants';
import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import GelIcon from '../GelIcon';

const AutoplayToggle = () => {
  const {
    state: { autoplay },
    toggleAutoplay,
  } = useContext(AppContext);
  const {
    state: { mixerModeOn },
  } = useContext(MixerContext);

  return (
    <button
      type="button"
      onClick={() => toggleAutoplay()}
      className="flex flex-row items-center justify-between cursor-pointer hover:underline focus:outline-none focus:underline"
      title={autoplay ? 'Disable autoplay' : 'Enable autoplay'}
      aria-label={autoplay ? 'Disable autoplay' : 'Enable autoplay'}
    >
      <GelIcon
        name="continuous-play"
        fill={THEME_COLOURS.PRIMARY}
        className="w-5 h-5"
      />
      <span className="ml-1 text-xs font-bold text-gray-900">AUTOPLAY</span>
      <div
        className={`relative w-10 h-5 ${
          autoplay
            ? 'bg-blue-800'
            : `${mixerModeOn ? 'bg-gray-800' : 'bg-gray-700'}`
        } transition-all duration-300 rounded-full ml-2`}
      >
        <span
          className="absolute w-4 h-4 transition-all duration-300 bg-white rounded-full"
          style={{
            top: '2px',
            left: autoplay ? '1.4rem' : '2px',
            right: autoplay ? '2px' : '22px',
          }}
        ></span>
      </div>
    </button>
  );
};

export default AutoplayToggle;
