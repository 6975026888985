import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { AppContext } from '../../Context/AppContext';

function Cookies() {
  const { echoTrack } = useContext(AppContext);

  useEffect(() => {
    echoTrack('cookies');

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet title="Cookie Information | DIGWIX Sound Effects" />
      <div className="max-w-screen-xl min-h-screen px-3 mx-auto">
        <h2 className="py-5 text-3xl font-bold md:py-10">Cookie Information</h2>
        <p className="pb-2">
          We use cookies and local storage for different things, such as:
        </p>
        <ul className="pb-2 ml-8 list-disc">
          <li>Analysing how the Sound Effects website is used</li>
          <li>Storing favourites and mixes</li>
        </ul>
        <h2 className="py-4 text-xl font-semibold">
          Strictly necessary cookies and storage
        </h2>
        <p className="pb-2">
          Cookies and local storage let you use all the different parts of the
          Sound Effects website. Without them, services that you&apos;ve asked
          for can&apos;t be provided.
        </p>
        <div className="py-4">
          <table className="table table-fixed">
            <thead>
              <tr>
                <th className="w-1/4 px-4 py-2 text-white bg-teal-700">Name</th>
                <th className="w-1/4 px-4 py-2 text-white bg-teal-700">Type</th>
                <th className="w-1/2 px-4 py-2 text-white bg-teal-700">
                  What it&apos;s for
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2 border border-teal-700">
                  bbcsfx-favourites
                </td>
                <td className="px-4 py-2 border border-teal-700">
                  Local storage item
                </td>
                <td className="px-4 py-2 border border-teal-700">
                  Retains a list of the sounds that you save to your favourites.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 border border-teal-700">
                  bbcsfx-accept-favourites-limit
                </td>
                <td className="px-4 py-2 border border-teal-700">
                  Local storage item
                </td>
                <td className="px-4 py-2 border border-teal-700">
                  Retains your confirmation of your understanding that there is
                  a 100-sound limit to your favourites when visiting the
                  Favourites page.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 border border-teal-700">
                  bbcsfx-new-recorder-clicked
                </td>
                <td className="px-4 py-2 border border-teal-700">
                  Local storage item
                </td>
                <td className="px-4 py-2 border border-teal-700">
                  Retains your confirmation of having viewed the new mix
                  recorder tool.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 border border-teal-700">
                  bbcsfx-download-terms-agreed
                </td>
                <td className="px-4 py-2 border border-teal-700">Cookie</td>
                <td className="px-4 py-2 border border-teal-700">
                  Retains your confirmation of accepting the terms of the{' '}
                  <a
                    href="/licensing"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-blue-800 underline hover:opacity-50"
                  >
                    RemArc Licence
                  </a>{' '}
                  on your first download of a sound.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 border border-teal-700">
                  bbcsfx-favourites-banner-dismissed
                </td>
                <td className="px-4 py-2 border border-teal-700">Cookie</td>
                <td className="px-4 py-2 border border-teal-700">
                  Retains your dismissal of the local storage information banner
                  that appears in your Favourites page.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 border border-teal-700">
                  bbcsfx-rating-submitted
                </td>
                <td className="px-4 py-2 border border-teal-700">Cookie</td>
                <td className="px-4 py-2 border border-teal-700">
                  Retains a record of you having submitted a rating for the site
                  after having done so when the rating pop-up appears.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 border border-teal-700">
                  bbcsfx-accepted-cookies
                </td>
                <td className="px-4 py-2 border border-teal-700">Cookie</td>
                <td className="px-4 py-2 border border-teal-700">
                  Retains your confirmation of accepting the use of the cookies
                  and local storage items on the site by clicking
                  &apos;Close&apos; on the banner that appears on the bottom of
                  the site pages.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="pb-2">
          The Sound Effects website uses other DIGWIX services to provide
          content and functionality.
        </p>
        <p className="pb-16">
          A list of cookies for all DIGWIX services can be found at the&nbsp;
          <a
            href="https://www.bbc.co.uk/usingthebbc/cookies/how-does-the-bbc-use-cookies/"
            target="_blank"
            rel="noreferrer noopener"
            className="font-semibold hover:underline"
          >
            DIGWIX Cookie &amp; Browser Settings page.
          </a>
        </p>
      </div>
    </>
  );
}

export default Cookies;
