import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';
import { FavouritesContext } from '../../Context/FavouritesContext';

import BaseModal from '../../Components/BaseModal';
import GelIcon from '../../Components/GelIcon';

import { THEME_COLOURS } from '../../constants';
import queryString from 'query-string';

function ImportFavouritesModal({ setShowImportModal, history }) {
  const { echoTrack } = useContext(AppContext);
  const { addAssetsToFavourites } = useContext(FavouritesContext);

  const [sharedFavouriteIds, setSharedFavouriteIds] = useState([]);

  // Decode favourites on mount
  useEffect(() => {
    const values = queryString.parse(history.location.search);

    if (values.favouritesstring) {
      const decodedString = JSON.parse(window.atob(values.favouritesstring));
      setSharedFavouriteIds(decodedString.favouriteIds);
    }
  }, [history]);

  const handleImport = () => {
    echoTrack('favourites', { action: 'favourites_imported' }, 'click');

    // Add favourites to Context + localStorage
    addAssetsToFavourites(sharedFavouriteIds);

    // Close modal
    setShowImportModal(false);

    // Redirect to base /favourites page
    history.push('/favourites');
  };

  const handleClose = () => {
    // Close modal
    setShowImportModal(false);

    // Redirect to base /favourites page
    history.push('/favourites');
  };

  return (
    <BaseModal
      labelId="import_favourites_modal_title"
      descriptionId="import_favourites_modal_desc"
    >
      <div className="px-6 py-8 text-left sm:px-8">
        <div className="flex items-center justify-between pb-3">
          <div className="flex flex-row items-center space-x-3">
            <GelIcon
              name="love"
              fill={THEME_COLOURS.PRIMARY}
              className="w-5 h-5"
            />
            <span
              className="text-2xl font-bold text-gray-900"
              id="import_favourites_modal_title"
            >
              Import Favourites
            </span>
          </div>
          <button
            type="button"
            className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300"
            onClick={handleClose}
            aria-label="Close"
          >
            <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
          </button>
        </div>
        <div>
          <p className="mb-3 text-gray-900" id="import_favourites_modal_desc">
            Import this shared list of favourites into your own favourites list.
          </p>
          <p className="mb-8 font-bold text-gray-900">
            Number of favourites to import:{' '}
            <span className="px-3 py-1 ml-2 bg-gray-400 rounded">
              {sharedFavouriteIds.length}
            </span>
          </p>
          <div className="flex flex-row justify-end w-full">
            <button
              type="button"
              className="w-full px-6 py-3 font-bold text-white bg-teal-700 focus:outline-none sm:w-auto hover:opacity-75 focus:opacity-75"
              onClick={handleImport}
            >
              Import
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

ImportFavouritesModal.propTypes = {
  setShowImportModal: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

export default withRouter(ImportFavouritesModal);
