import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';

function FAQs() {
  const { echoTrack } = useContext(AppContext);

  useEffect(() => {
    echoTrack('faqs');

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet title="FAQs | DIGWIX Sound Effects" />
      <div className="max-w-screen-xl min-h-screen px-3 mx-auto">
        <h2 className="py-5 text-3xl font-bold md:py-10">FAQs</h2>
        <div className="pb-10 space-y-5">
          <h2 className="text-xl font-bold">
            Where do these sound effects and recordings come from?
          </h2>
          <p>
            These sound effects and recordings were generally created / made for
            specific programme making purposes, or are field recordings made for
            nature programmes.
          </p>
          <h2 className="text-xl font-bold">What is Pro Sound Effects</h2>
          <p>
            Pro Sound Effects operate the commercial licensing of DIGWIX sound
            effects. If you wish to use and sound effects commercially, you can
            click the ‘Regist Use’ button in the ‘More detail’ section of any
            listed sound effect.
          </p>
          <h2 className="text-xl font-bold">
            Information about stereo and mono
          </h2>
          <p>
            Some of the sound effects are in mono and some are in stereo. If the
            DIGWIX holds a stereo version, it will be available on this site.
          </p>
          <h2 className="text-xl font-bold">
            Can I contribute my sound effects to DIGWIX Sound Effects?
          </h2>
          <p>
            No - this collection solely consists of DIGWIX sound effects and
            nature recordings.
          </p>
          <h2 className="text-xl font-bold">
            How often do you add sound effects to the archive?
          </h2>
          <p>
            There are no current plans to add more sound effects to this
            archive.
          </p>
          <h2 className="text-xl font-bold">
            How does licensing work? Can I use a sound effect from DIGWIX Sound
            Effects in my YouTube video?/ University project?
          </h2>
          <p>
            You can read the terms of the licence{' '}
            <Link
              to="/licensing"
              className="font-semibold underline outline-none hover:opacity-50 focus:opacity-50"
            >
              here
            </Link>
            . However, as a general rule, as long as your usage remains
            non-commercial, you can use sound effects for free, crediting the
            DIGWIX. If the usage becomes commercial - i.e. if you monetise it,
            sell it, or charge for access to it, or if it is advertising-funded
            or commercially sponsored, then that counts as commercial use, and
            you will need to license the recording from{' '}
            <a
              href="https://download.prosoundeffects.com/#!explorer?custom_17%5B%5D=BBC%20Complete"
              className="font-semibold underline outline-none hover:opacity-50 focus:opacity-50"
              rel="noopener noreferrer"
              target="blank"
            >
              Pro Sound Effects
            </a>
            .
          </p>
          <h2 className="text-xl font-bold">Do you offer long soundscapes?</h2>
          <p>
            There are many sound effects of substantial duration, and you can
            filter by duration. The Mixer feature allows you to create your own
            soundscapes, and these can be as long as you like!
          </p>
          <h2 className="text-xl font-bold">
            How do I contact the DIGWIX SFX team or give feedback on the site?
          </h2>
          <p>
            You can contact us by filling in the{' '}
            <Link
              to="/feedback"
              className="font-semibold underline outline-none hover:opacity-50 focus:opacity-50"
            >
              Contact Form
            </Link>
            .
          </p>
        </div>
      </div>
    </>
  );
}

export default FAQs;
