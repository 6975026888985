import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import GelIcon from '../GelIcon';
import getQuerystring from '../../utilities/getQuerystring';
import modifyExistingQuerystring from '../../utilities/modifyExistingQuerystring';
import { THEME_COLOURS } from '../../constants';
import { NEW_CATEGORY_LIST } from '../../category';
import MixerToggle from '../MixerToggle';
import {
  parseCategories,
  parseMiddleCategory,
  parseSubCategory,
  parseSources,
} from '../../utilities/queryParamParsers';

function MobileFilter({ history }) {
  const {
    state: { categoryAggregations },
    echoTrack,
  } = useContext(AppContext);
  const {
    state: { mixerModeOn },
  } = useContext(MixerContext);

  // Store a reference to the page the user is on
  const pageContext = history.location.pathname.substring(1);

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  // Get search term from query string
  const parsedQuerystring = getQuerystring();

  // Use search term or default to empty
  const [categories, setCategories] = useState(
    parseCategories(parsedQuerystring.cat)
  );
  const [middles, setMiddles] = useState(
    parseMiddleCategory(parsedQuerystring.durations)
  );
  const [sources, setSources] = useState(
    parseSources(parsedQuerystring.source)
  );
  const [subs, setSubs] = useState(
    parseSubCategory(parsedQuerystring.continents)
  );
  const [recordists, setRecordists] = useState(
    parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []
  );
  const [habitats, setHabitats] = useState(
    parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []
  );

  // Update the categories and durations when the URL params change
  useEffect(() => {
    setCategories(
      parsedQuerystring.cat ? parsedQuerystring.cat.split(',') : []
    );
    setMiddles(
      parsedQuerystring.durations ? parsedQuerystring.durations.split(',') : []
    );
    setSubs(
      parsedQuerystring.continents
        ? parsedQuerystring.continents.split(',')
        : []
    );
    setRecordists(
      parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []
    );
    setSources(
      parsedQuerystring.source ? parsedQuerystring.source.split(',') : []
    );
    setHabitats(
      parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []
    );
  }, [
    parsedQuerystring.cat,
    parsedQuerystring.durations,
    parsedQuerystring.continents,
    parsedQuerystring.recordist,
    parsedQuerystring.source,
    parsedQuerystring.habitat,
  ]);

  const [showCategories, toggleShowCategories] = useState(false);
  const [showDurations, toggleShowDurations] = useState(false);
  const [showContinents, toggleShowContinents] = useState(false);
  const [showActiveFilters, setShowActiveFilters] = useState(false);

  const performSearch = () => {
    echoTrack(pageContext, { action: 'mobile_filter_search' }, 'click');

    history.push(
      `/${pageContext}?${modifyExistingQuerystring(
        'cat',
        categories.join(','),
        modifyExistingQuerystring(
          'durations',
          middles.join(','),
          modifyExistingQuerystring('continents', subs.join(','))
        )
      )}`
    );
  };

  const toggleCategory = (category) => {
    echoTrack(
      pageContext,
      { action: 'mobile_filter_category_toggled', data: { category } },
      'click'
    );

    if (categories.includes(category)) {
      setCategories(_.filter(categories, (cat) => cat !== category));

      const cat = NEW_CATEGORY_LIST.find((x) => x.id.toString() === category);
      setMiddles(
        _.filter(
          middles,
          (mid) => !cat.sub.map((x) => x.id.toString()).includes(mid)
        )
      );

      setSubs(
        _.filter(
          subs,
          (sub) =>
            !cat.sub
              .flatMap((x) => x.sub)
              .map((x) => x.id.toString())
              .includes(sub)
        )
      );
    } else {
      setCategories([category, ...categories]);
    }
  };

  const toggleMiddles = (middle) => {
    echoTrack(
      pageContext,
      { action: 'mobile_filter_duration_toggled', data: { duration: middle } },
      'click'
    );

    if (middles.includes(middle)) {
      setMiddles(_.filter(middles, (dur) => dur !== middle));

      const middle_cat = NEW_CATEGORY_LIST.flatMap((x) => x.sub).find(
        (x) => x.id.toString() === middle
      );

      setSubs(
        _.filter(
          subs,
          (mid) => !middle_cat.sub.map((x) => x.id.toString()).includes(mid)
        )
      );
    } else {
      setMiddles([middle, ...middles]);
    }
  };

  const toggleSubs = (continent) => {
    echoTrack(
      pageContext,
      { action: 'mobile_filter_continent_toggled', data: { continent } },
      'click'
    );

    if (subs.includes(continent)) {
      setSubs(_.filter(subs, (cont) => cont !== continent));
    } else {
      setSubs([continent, ...subs]);
    }
  };

  const resetFilters = () => {
    setCategories([]);
    setMiddles([]);
    setSubs([]);
    setFilterMenuOpen(false);

    echoTrack(pageContext, { action: 'mobile_filter_reset' }, 'click');

    history.push(
      `/${pageContext}?${modifyExistingQuerystring(
        'cat',
        [],
        modifyExistingQuerystring(
          'durations',
          [],
          modifyExistingQuerystring('continents', [])
        )
      )}`
    );
  };

  const closeFilterMenu = () => {
    // Reset the filter options back to their original state if user applies some and then closes the menu
    setCategories(
      parsedQuerystring.cat ? parsedQuerystring.cat.split(',') : []
    );
    setMiddles(
      parsedQuerystring.durations ? parsedQuerystring.durations.split(',') : []
    );
    setSubs(
      parsedQuerystring.continents
        ? parsedQuerystring.continents.split(',')
        : []
    );

    setFilterMenuOpen(false);
    toggleShowCategories(false);
    toggleShowDurations(false);
    toggleShowContinents(false);

    // Reset scroll lock
    document.documentElement.style.position = '';
    document.documentElement.style.width = '';
  };

  const handleFilterMenuClick = () => {
    setFilterMenuOpen(!filterMenuOpen);

    // Reset scroll lock
    document.documentElement.style.position = 'fixed';
    document.documentElement.style.width = '100%';
  };

  const handleApplyClick = () => {
    performSearch();
    closeFilterMenu();
  };

  useEffect(() => {
    if (showActiveFilters) {
      toggleShowCategories(false);
      toggleShowDurations(false);
      toggleShowContinents(false);
    }
  }, [showActiveFilters]);

  useEffect(() => {
    if (showCategories) {
      setShowActiveFilters(false);
      toggleShowDurations(false);
      toggleShowContinents(false);
    }
  }, [showCategories]);

  useEffect(() => {
    if (showDurations) {
      setShowActiveFilters(false);
      toggleShowCategories(false);
      toggleShowContinents(false);
    }
  }, [showDurations]);

  useEffect(() => {
    if (showContinents) {
      setShowActiveFilters(false);
      toggleShowCategories(false);
      toggleShowDurations(false);
    }
  }, [showContinents]);

  return (
    <>
      <div
        className={`${
          mixerModeOn ? 'bg-gray-700' : 'bg-gray-100'
        } transition-all duration-200 ease-in-out`}
      >
        <div className="max-w-screen-xl mx-auto">
          <div className="flex flex-row items-center justify-between px-3 pb-3">
            <MixerToggle />
            <button
              type="button"
              className="flex flex-row items-center justify-end w-1/2 px-3 focus:outline-none"
              onClick={handleFilterMenuClick}
              aria-label="Open filter menu"
            >
              <GelIcon
                name="filter"
                fill={THEME_COLOURS.PRIMARY}
                className="w-5 h-5"
              />
              <span className="pl-2 text-lg font-bold text-gray-900 hover:text-gray-600">
                Filter (
                {categories.length +
                  middles.length +
                  subs.length +
                  recordists.length +
                  sources.length +
                  habitats.length}
                )
              </span>
            </button>
          </div>
          {filterMenuOpen && (
            <div className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-white">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <div className="flex flex-row justify-between w-full pl-2">
                    <div className="flex flex-row items-center py-4">
                      <GelIcon name="filter" fill={THEME_COLOURS.PRIMARY} />
                      <span className="pl-3 pr-4 text-xl font-bold text-gray-900 hover:text-gray-600">
                        Filter Results
                      </span>
                    </div>
                    <button
                      type="button"
                      className="p-5 bg-white border-l-2 border-gray-400 focus:outline-none focus:bg-gray-200"
                      onClick={closeFilterMenu}
                      aria-label="Close filter menu"
                    >
                      <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
                    </button>
                  </div>
                  <div className="h-screen pb-64 overflow-y-auto scrolling-touch">
                    <div className="p-4 text-left border-t-2 border-gray-400">
                      <button
                        type="button"
                        onClick={() => setShowActiveFilters(!showActiveFilters)}
                        className="flex flex-row items-center justify-between w-full px-3 text-xl font-bold text-left text-teal-700 focus:outline-none"
                        aria-label={
                          showActiveFilters
                            ? 'Close active filters menu'
                            : 'Open active filters menu'
                        }
                      >
                        Active Filters
                        <GelIcon
                          className="w-6 h-6"
                          name={
                            showActiveFilters ? 'chevron-up' : 'chevron-down'
                          }
                          fill={THEME_COLOURS.SECONDARY}
                        />
                      </button>
                    </div>
                    <div className="p-4 text-left border-t-2 border-gray-400">
                      <button
                        type="button"
                        onClick={() => toggleShowCategories(!showCategories)}
                        className="flex flex-row items-center justify-between w-full px-3 text-xl font-bold text-left text-gray-900 focus:outline-none"
                        aria-label={
                          showCategories
                            ? 'Close categories menu'
                            : 'Open categories menu'
                        }
                      >
                        Categories ({categories.length})
                        <GelIcon
                          className="w-6 h-6"
                          name={showCategories ? 'chevron-up' : 'chevron-down'}
                          fill={THEME_COLOURS.PRIMARY}
                        />
                      </button>
                    </div>
                    {showCategories && (
                      <div>
                        {NEW_CATEGORY_LIST.length &&
                          NEW_CATEGORY_LIST.map((category) => (
                            <button
                              key={category.id}
                              type="button"
                              role="checkbox"
                              aria-checked={categories.includes(
                                category.id.toString()
                              )}
                              onClick={() =>
                                toggleCategory(category.id.toString())
                              }
                              className="w-full px-8 py-3 focus:outline-none"
                              aria-label={`Toggle ${category.name} category`}
                            >
                              <div className="flex flex-row items-center justify-between w-full mt-1 text-gray-900 text-md">
                                <span className="truncate">
                                  {category.name}
                                </span>
                                <div className="flex flex-row items-center">
                                  <small className="mr-2 text-gray-900">
                                    {categoryAggregations[category.id] &&
                                      `(${
                                        categoryAggregations[category.id]
                                          .doc_count
                                      })`}
                                  </small>
                                  {categories.includes(
                                    category.id.toString()
                                  ) ? (
                                    <div className="w-6 h-6 border-2 border-gray-900">
                                      <GelIcon
                                        className="z-20"
                                        name="check"
                                        fill={THEME_COLOURS.PRIMARY}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-6 h-6 border-2 border-gray-900" />
                                  )}
                                </div>
                              </div>
                            </button>
                          ))}
                      </div>
                    )}
                    <div className="flex p-4 text-left border-t-2 border-gray-400">
                      <button
                        type="button"
                        onClick={() => toggleShowDurations(!showDurations)}
                        className="flex flex-row items-center justify-between w-full px-3 text-xl font-bold text-left text-gray-900 focus:outline-none"
                        aria-label={
                          showDurations
                            ? 'Close duration menu'
                            : 'Open duration menu'
                        }
                      >
                        Middle ({middles.length})
                        <GelIcon
                          className="w-6 h-6"
                          name={showDurations ? 'chevron-up' : 'chevron-down'}
                          fill={THEME_COLOURS.PRIMARY}
                        />
                      </button>
                    </div>
                    {showDurations && (
                      <div>
                        {NEW_CATEGORY_LIST.filter((cat) =>
                          categories.includes(cat.id.toString())
                        )
                          .flatMap((x) => x.sub)
                          .map((cat_middle) => (
                            <button
                              key={cat_middle.id}
                              type="button"
                              role="checkbox"
                              aria-checked={middles.includes(
                                cat_middle.id.toString()
                              )}
                              onClick={() =>
                                toggleMiddles(cat_middle.id.toString())
                              }
                              className="w-full px-8 py-3 focus:outline-none"
                              aria-label={`Toggle ${cat_middle.name} duration`}
                            >
                              <div className="flex flex-row items-center justify-between w-full mt-1 text-gray-900 text-md">
                                <span>{cat_middle.name}</span>
                                <div className="flex flex-row items-center">
                                  <small className="mr-2 text-gray-900">
                                    {categoryAggregations[cat_middle.id] &&
                                      `(${
                                        categoryAggregations[cat_middle.id]
                                          .doc_count
                                      })`}
                                  </small>
                                  {middles.includes(
                                    cat_middle.id.toString()
                                  ) ? (
                                    <div className="w-6 h-6 border-2 border-gray-900">
                                      <GelIcon
                                        className="z-20"
                                        name="check"
                                        fill={THEME_COLOURS.PRIMARY}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-6 h-6 border-2 border-gray-900" />
                                  )}
                                </div>
                              </div>
                            </button>
                          ))}
                      </div>
                    )}
                    <div className="flex p-4 text-left border-t-2 border-gray-400">
                      <button
                        type="button"
                        onClick={() => toggleShowContinents(!showContinents)}
                        className="flex flex-row items-center justify-between w-full px-3 text-xl font-bold text-left text-gray-900 focus:outline-none"
                        aria-label={
                          showContinents
                            ? 'Close continent menu'
                            : 'Open continent menu'
                        }
                      >
                        Sub ({subs.length})
                        <GelIcon
                          className="w-6 h-6"
                          name={showContinents ? 'chevron-up' : 'chevron-down'}
                          fill={THEME_COLOURS.PRIMARY}
                        />
                      </button>
                    </div>
                    {showContinents && (
                      <div>
                        {NEW_CATEGORY_LIST.flatMap((x) => x.sub)
                          .filter((x) => middles.includes(x.id.toString()))
                          .flatMap((x) => x.sub)
                          .map((cat_sub) => (
                            <button
                              key={cat_sub.id}
                              type="button"
                              role="checkbox"
                              aria-checked={subs.includes(
                                cat_sub.id.toString()
                              )}
                              onClick={() => toggleSubs(cat_sub.id.toString())}
                              className="w-full px-8 py-3 focus:outline-none"
                              aria-label={`Toggle ${cat_sub.name}`}
                            >
                              <div className="flex flex-row items-center justify-between w-full mt-1 text-gray-900 text-md">
                                <span>{cat_sub.name}</span>
                                <div className="flex flex-row items-center">
                                  <small className="mr-2 text-gray-900">
                                    {categoryAggregations[cat_sub.id] &&
                                      `(${
                                        categoryAggregations[cat_sub.id]
                                          .doc_count
                                      })`}
                                  </small>
                                  {subs.includes(cat_sub.id.toString()) ? (
                                    <div className="w-6 h-6 border-2 border-gray-900">
                                      <GelIcon
                                        className="z-20"
                                        name="check"
                                        fill={THEME_COLOURS.PRIMARY}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-6 h-6 border-2 border-gray-900" />
                                  )}
                                </div>
                              </div>
                            </button>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="fixed bottom-0 grid w-full grid-cols-2">
                  <button
                    type="button"
                    className="flex flex-row items-center justify-center w-full font-bold text-white bg-gray-900 focus:outline-none"
                    onClick={resetFilters}
                    aria-label="Reset filters"
                  >
                    <GelIcon
                      className="w-5 h-5 mr-2"
                      name="refresh"
                      fill="white"
                    />
                    <span>Reset</span>
                  </button>
                  <button
                    type="button"
                    className="items-center w-full h-full py-4 font-bold text-white bg-teal-700 focus:outline-none"
                    onClick={handleApplyClick}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

MobileFilter.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default withRouter(MobileFilter);
