import React, { useReducer, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import UserReducer from './UserReducer';
import { CLEAR_USER_INFO, SET_USER_INFO } from '../actionTypes';

import initialState from './initialState';

export const UserContext = createContext(initialState);

// Provider component
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  function setUserInfo(user) {
    dispatch({
      type: SET_USER_INFO,
      user,
    });
  }

  function clearUserInfo() {
    dispatch({
      type: CLEAR_USER_INFO,
    });
  }

  const ctx = useMemo(
    () => ({
      state,
      setUserInfo,
      clearUserInfo,
    }),
    [state]
  );

  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.shape({}),
};

export function withContext(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <UserContext.Consumer>
      {(context) => <Component {...props} context={context} />}
    </UserContext.Consumer>
  );
}
