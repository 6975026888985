import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Button from '../../Components/Button';
import { Signup } from '../../Route/Route';

const Title = styled.div`
  font-size: 4rem;
  font-weight: 600;
  font-family: Inter;
  color: white;
  margin-bottom: 5rem;
`;

const Label = styled.label`
  color: white;
  font-size: 2rem;
  margin-right: 15px;
  font-family: Inter;
`;

const SignUpWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
`;

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  max-width: 1500px;
  padding: 10rem 0;
  align-items: center;
  input {
    color: black;
  }
`;

const SignUp = () => {
  const { t } = useTranslation();
  const [inputId, setInputId] = useState('');
  const [inputPw, setInputPw] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputMobile, setInputMobile] = useState('');
  const [ErrMss, setErrMss] = useState('');
  const navigate = useHistory();

  const ErrorMessage = (data) => {
    setErrMss(data);
  };

  const handleInputId = (e) => {
    setInputId(e.target.value);
  };

  const handleInputPw = (e) => {
    setInputPw(e.target.value);
  };

  const handleInputEmail = (e) => {
    setInputEmail(e.target.value);
  };

  const handleInputMobile = (e) => {
    setInputMobile(e.target.value);
  };

  const onSignup = async () => {
    if (inputId.length === 0) {
      ErrorMessage(t('signin.fill_id'));
      return;
    }
    if (inputPw.length === 0) {
      ErrorMessage(t('signin.fill_pw'));
      return;
    }
    if (inputEmail.length === 0) {
      ErrorMessage(t('signin.fill_email'));
      return;
    }
    if (inputMobile.length === 0) {
      ErrorMessage(t('signin.fill_mobile'));
      return;
    }

    const res = await Signup({
      account: inputId,
      password: inputPw,
      email: inputEmail,
      mobile: inputMobile,
    });
    if (res.status !== 200) {
      ErrorMessage(t('signin.fail_member'));
      //에러
    } else {
      console.log('success');
      setInputId('');
      setInputPw('');
      setInputEmail('');
      setInputMobile('');
      navigate.push('/Login');
    }
  };

  useEffect(
    () => {
      // axios.get('/user_inform/login')
      // .then(res => console.log(res))
      // .catch()
    },
    // 페이지 호출 후 처음 한번만 호출될 수 있도록 [] 추가
    []
  );

  return (
    <>
      <Helmet title="SOUNDWIX | SIGN UP" />
      <SignUpWrapper>
        <SignUpContainer>
          <Title>SIGN UP</Title>
          <div className="text-base mt-2">
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  width: '8.75rem',
                  alignItems: 'center',
                }}
              >
                <Label>ID</Label>
              </div>
              <div>
                <input
                  type="text"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  name="input_id"
                  autoComplete="off"
                  value={inputId}
                  onChange={handleInputId}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  width: '8.75rem',
                  alignItems: 'center',
                }}
              >
                <Label>PW</Label>
              </div>
              <div>
                <input
                  type="password"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  name="input_pw"
                  autoComplete="off"
                  value={inputPw}
                  onChange={handleInputPw}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  width: '8.75rem',
                  alignItems: 'center',
                }}
              >
                <Label>EMAIL</Label>
              </div>
              <div>
                <input
                  type="text"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  name="input_email"
                  autoComplete="off"
                  value={inputEmail}
                  onChange={handleInputEmail}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  width: '8.75rem',
                  alignItems: 'center',
                }}
              >
                <Label>MOBILE</Label>
              </div>
              <div>
                <input
                  type="text"
                  style={{ fontSize: '2rem', padding: '5px' }}
                  name="input_mobile"
                  autoComplete="off"
                  value={inputMobile}
                  onChange={handleInputMobile}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: '3rem',
              color: 'red',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontSize: '1.5rem',
            }}
          >
            {ErrMss}
          </div>
          <div
            className="flex justify-end items-end text-base mt-4"
            style={{
              color: 'White',
              marginBottom: '3rem',
              marginTop: '2rem',
            }}
          >
            <Button onClick={onSignup}>SIGN UP</Button>
          </div>
        </SignUpContainer>
      </SignUpWrapper>
    </>
  );
};

export default SignUp;
