const ko = {
  language: '언어',
  free_start_now: '무료로 시작',
  my_favourites: '즐겨찾기',
  your_favourites: '즐겨찾기',
  about_soundwix: 'About Digiwix',
  faqs: 'FAQs',
  terms_of_use: '이용약관',
  privacy_policy: '개인정보처리방침',

  create_your_music: '음악을 만들어 보세요',
  reset_filters: '필터 초기화',
  favourites_localstorage_alert: '즐겨찾기는 로컬 스토리지에 저장됩니다.',
  tell_me_more: '자세히 보기',
  favourite_desc_1: '브라우저에 최대 100개의 즐겨찾기를 저장할 수 있습니다.',
  favourite_desc_2:
    '즐겨찾기는 캐시를 지울 때까지 현재 브라우저에서만 사용할 수 있습니다.',
  favourite_desc_3:
    '즐겨찾기한 항목을 잃지 않으려면 즐겨찾기를 다운로드하여 보관하세요.',
  ok_got_it: '네 알겠습니다.',
  cancel: '취소',
  apply: '적용',
  no_favourites_found: '즐겨찾기가 없습니다.',

  about_desc_1:
    'DIGWIX Sound Effects Archive는 개인, 교육 또는 연구 목적으로 사용할 수 있습니다.지난 100년 동안 전 세계에서 33,000개 이상의 클립이 있습니다. 여기에는 DIGWIX Radiophonic 워크숍에서 만든 클립, Blitz in London의 녹음, 특별 DIGWIX TV 및 라디오 프로덕션을 위해 제작된 효과와 Natural History Unit 아카이브의 15,000개 녹음.',
  about_desc_2:
    '이 보물창고를 탐색하고 검색할 뿐만 아니라 믹서 모드 기능을 사용하여 자신만의 믹스와 사운드스케이프를 만들고 공유할 수도 있습니다. 믹서를 사용하여 아카이브에서 클립을 레이어, 편집 및 재정렬하여 나만의 사운드를 만드십시오.',
  about_desc_3: '자연의 소리',
  about_desc_4:
    'Natural History 사운드 컬렉션은 Natural History Unit의 텔레비전 및 라디오 제작과 1960년대에 급증하는 출력을 위한 진정한 자연 사운드를 제공해야 할 필요성에서 시작되었습니다. 대부분의 사람들은 아마도 대부분의 자연사 TV가 음소거로 녹화된다는 사실을 깨닫지 못할 것입니다. 카메라와 마이크의 녹음 요구 사항이 매우 다릅니다. 그리고 일반적으로 동물을 촬영하는 것보다 동물의 소리를 녹음하려면 피사체에 훨씬 더 가까이 다가가야 합니다. 따라서 대부분의 경우 프로덕션 편집 중에 사운드가 추가됩니다. 그것은 부대가 지속적인 리소스가 될 양질의 자연음 라이브러리를 구축하고 촬영 시 설득력 있게 녹음할 수 없을 때 프로덕션의 공백을 메워야 한다는 것을 의미했습니다.',
  about_desc_5:
    '부대는 현장에서 돌아온 프로덕션 녹음 기사가 수집한 소리를 동화하고 통합하기 시작했으며, 동시에 자체 녹음 전담 녹음 기사를 고용하여 자연 소리를 수집하고 다른 사람의 녹음을 의뢰했습니다. 점차적으로 사운드 컬렉션이 구축되었으며 이후 미국에서 가장 큰 자연 사운드 컬렉션 중 하나가 되었습니다.',
  about_desc_6:
    '원본 컬렉션은 1/4인치 테이프로 교체되기 전에 비닐에 보관되었으며, 그 다음에는 CD로, 결국 디지털 기반으로 이동했습니다. 사운드는 영화와 같은 방식으로 제작되지 않으므로 1970년대에 테이프에 녹음된 내용은 오늘날에도 고품질 제작에 사용될 수 있습니다.',
  frequently_asked_questions: '자주하는 질문',
  about_digwix_sound_effects: 'SOUNDWIX Sound Effects 대해서',
  more_detail: 'More Detail',
  file_info: '파일 정보',
  file_size: '파일 용량',
  channels: '채널',
  source: '소스',
  sounds_added_to_mixer: 'Sounds 믹서에 추가',
  view_mix: '믹서 보기',
  play_all: '전체 재생',
  pause_all: '전체 멈춤',
  download_all: '전체 다운로드',
  share_mix: 'Share Mix',
  add_to_mixer: '믹서에 추가',
  added: '추가됨',
  in_mix: 'in mix',
  load_more: '더보기',
  loading: '로딩...',
  reset: '초기화',
  signin: {
    fill_id: 'ID를 입력해 주세요',
    fill_pw: 'PW를 입력해 주세요',
    fill_email: 'EMAIL을 입력해주세요',
    fill_mobile: '핸드폰 번호를 입력해주세요',
    fail: 'ID 또는 PW는 정확하지 않습니다',
    fail_member: '가입처리 중 오류가 발생했습니다',
    fail_change: '정보변경에 실패했습니다',
  },
  info: {
    fail_request_verify: '메일 전송 요청에 실패했습니다.',
    fail_verify_email: '메일 인증에 실패했습니다.',
    success_verify_email: '메일 인증에 성공했습니다.',
    success_change: '정보 변경에 성공했습니다.',
  },
  subscribe: {
    already_subscribed: '이미 구독중입니다.',
    fail_subscribe: '구독에 실패했습니다. 다시 시도해 주세요.',
    please_login: '로그인 후 이용해주세요.',
    desc1:
      '구독료 결제일로부터 +7일 이내 이용이력이 없는 경우에는 취소/환불가능합니다.',
    desc2:
      '단 휴대폰으로 결제 시에는 당월 결제건에 대하여만 취소가 가능하여 결제 당월 이후에는 결제취소가 불가 합니다.',
    desc3:
      '이용기간중 사용한 음원은 이용기간 이후에도 영구적으로 보호됩니다, 단 이용기간 중에 다운로드된 음원이라도 이용기간 종료 후에는 사용이 불가합니다.',
  },
  registUse: {
    fail_regist: '등록에 실패했습니다. 다시 시도해 주세요.',
    success_regist: '등록에 성공했습니다.',
    please_login: '로그인 후 이용해주세요.',
    already_registed: '이미 등록되어 있습니다.',
  },
};

export default ko;
