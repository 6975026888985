import styled from 'styled-components';

const ButtonOuterWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  border: 3px solid transparent;
  border-radius: 33px;
  background-origin: border-box;
  background-image: linear-gradient(180deg, #3b5ee8 0%, #e51ce4 100%),
    linear-gradient(0deg, rgba(217, 217, 217, 0), rgba(217, 217, 217, 0));
`;

const ButtonInnerWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  border-radius: 33px;
`;

const CustomButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #37ecec;
  background-color: black;
  border: 3px solid #37ecec;
  border-radius: 33px;
  width: 14.25rem;
  height: 4.375rem;
  font-size: 2rem;
`;

const Button = (props) => {
  const { style, onClick, children } = props;
  return (
    <ButtonOuterWrapper>
      <ButtonInnerWrapper>
        <CustomButton style={style} onClick={onClick}>
          {children}
        </CustomButton>
      </ButtonInnerWrapper>
    </ButtonOuterWrapper>
  );
};

export default Button;
